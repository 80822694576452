import apiService from "../../services/api.service";
import * as actionTypes from "./actions";

export const getPlans = (pageType: number): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_PLANS,
    });
    apiService
      .getPlans(pageType)
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_PLANS_SUCCESS,
          data: response.plans,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_PLANS_FAILED,
          error,
        });
      });
  };
};

export const getAAOPlans = (pageType: number,token:string): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_AAO_PLANS,
    });
    apiService
      .getAAOPlans(pageType,token)
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_AAO_PLANS_SUCCESS,
          data: response.plans,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_AAO_PLANS_FAILED,
          error,
        });
      });
  };
};


export const getPracticePlans = (pageType: number): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_PRACTICEPLANS,
    });
    apiService
      .getPracticePlan(pageType)
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_PRACTICEPLANS_SUCCESS,
          data: response.data.plans,
          total: response.data.currentTotal,
          cancelSeatsTotal: response.data.cancelSeatsTotal,
          legacySeatsTotal: response.data.legacySeatsTotal,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_PRACTICEPLANS_FAILED,
          error,
        });
      });
  };
};

export const changeLicenceCountEffect = (payload: any): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.EDIT_LICENSE,
    });
    apiService
      .editLiceseCount(payload)
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.EDIT_LICENSE_SUCCESS,
          data: response,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.EDIT_LICENSE_FAILED,
          error,
        });
      });
  };
};

export const getPlanFeature = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_PLAN_FEATURE,
    });
    apiService
      .getFeatures()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_PLAN_FEATURE_SUCCESS,
          data: response?.data?.features,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_PLAN_FEATURE_FAILED,
          error,
        });
      });
  };
};