import React,{useState} from "react";
import "./Thankyou.scss";
import { useNavigate } from "react-router-dom";
import { REGISTER_DATA_RESET,SET_RESET_TRUE } from "../../store/register/actions";
import { useDispatch } from "react-redux";
import Mailimg from "../../assets/images/mailIcon.svg";
import ApiService from "../../services/api.service";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { AppConfig } from "../../configs/app.config";
import { useLocation } from "react-router-dom";
import Progress from "../common/Progress";
import Choosedplan from "../../pages/widgets/Choosedplan";

function Thankyou() {
  const { state } = useLocation() as any;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email,setEmail] = useState('');

  const [from,setFrom]= useState('');
 
  React.useEffect(() => {
    if(state?.email === undefined && ApiService?.thankyouemail === null){
      navigate("/");
    }
    if(state?.email === undefined){
      setEmail(ApiService?.thankyouemail)
      setFrom('apiservice')
    }
    if(ApiService?.thankyouemail === null){
      setEmail(state?.email)
      setFrom('register')
    }
    dispatch({
      type: REGISTER_DATA_RESET
    });
    dispatch({
      type: SET_RESET_TRUE
    });
    ApiService.thankyouemail = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  


  return (
    <>
    <div className="welcome" style={{background:'#eee'}}>
      <Container style={{paddingTop:100,background:'#eeeeee'}}>
          <Progress step={3} />
      </Container>
      <Container className="small-container">
        <Choosedplan planid={state.lastPlanId} discountData={state.discount} />
      </Container>
      <section className="thankyou">
        <div
          className="wrapperAlert"
          style={{ margin: "0px auto", textAlign: "center" }}
        >
          
          <div className="content mt-5">
              <img src={Mailimg} alt='mailimg' height='100px' width='75px' />
              <div className='mt-2'>
                {
                  from === 'register' ?
                (
                  <div>
                     <span className='thankyou-title'>Thank you for your subscription!</span><br/><br/>
                     <span className='thankyou-subtext'>
                      We’ve sent an email to <b>{email}</b> so you can set up your Leader Dashboard and start adding learners from your team.<br/><br/>
                      We can’t wait for you to get started!</span>
                  </div>
                  )
                  :
                  (
                    <div>
                     <span className='thankyou-title'>Thank you for adding billing details!</span><br/><br/>
                     <span className='thankyou-subtext'>
                      We will send you an email to <b>{email}</b> so you can login to your Leader Dashboard and start exploring.<br/><br/>
                      We can’t wait for you to get started!</span>
                  </div>
                  )
                 }
              </div> 
              <Col md={12} className="text-center">
                <a
                  type="submit"
                  href={AppConfig?.loginurl}
                  className="team-start-btn"
                >
                  Go to the login page 💻
                </a>
              </Col>
          </div>
        </div>
        <Container className="small-container">
          <Row  className="text-center gx-5">
            <Col md={12}>
              <div className='pro-tip'>
                🖥️ <b>Pro Tip:</b> To ensure you have access to Alchemy Vision’s content and emails, please<br/> share the URL below with your IT administrator(s), so they can make any necessary<br/> adjustments to your network – <a className='no-hover' target='new' href='https://www.alchemyvision.com/itsupport'>https://www.alchemyvision.com/itsupport</a>
              </div>
            </Col>
          </Row>
        </Container><br/><br/><br/><br/>
      </section>
    </div>
    </>
  );
}

export default Thankyou;
