/* eslint-disable jsx-a11y/anchor-is-valid */
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./footer.scss";
import { Link } from "react-router-dom";

function MainFooter() {
  return (
    <>
    <div className="footer-aao">
      <div className="footer-aao-middle" style={{ color: "white" }}>
        <Row>
          <Col md={12} className="text-center">
            <div  className="footer-last-text">
             <Link className="nav-link" to='/' >Solutions</Link> <span className="no-display-mobile">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
             <Link className="nav-link" to='/' >How It Works</Link> <span className="no-display-mobile">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
             <Link className="nav-link" to='/' >Pricing</Link> <span className="no-display-mobile">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
             <Link className="nav-link" to='/' >Back to aao.org</Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    </>
  );
}

export default MainFooter;
