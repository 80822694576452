/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./dashboard.scss";
import Myprofile from "../../../src/assets/images/My-Subscription.svg";
import List from "../../../src/assets/images/list.svg";
import Dollorlist from "../../../src/assets/images/dollor-list.svg";
import Setting from "../../../src/assets/images/settings.svg";
import Team from "../../../src/assets/images/My-Practice.svg";
import Arrow from "../../../src/assets/images/arrow.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAccount } from "../../store/myaccount/effect";
// import swal from "sweetalert";
import { getSubscriptionType } from "@store/subscription/effect";

function Dashboard() {
  const dispatch = useDispatch();
  const [samlToken,setSamlToken] = useState('');

  React.useEffect(() => {
    dispatch(getAccount());
    dispatch(getSubscriptionType());
    
  }, [dispatch]);


  const formRef:any = useRef(null);
  const leaderRedirect = () =>{
    const saml = JSON.parse(localStorage?.getItem("saml") as string);
    setSamlToken(saml.saml)
    // Automatically submit the form when the component is mounted
   
  }
  useEffect(()=>{
    if(samlToken!==''){
      formRef?.current?.submit();
      setSamlToken('')
    }
  },[samlToken])
  return (
    <>
    {
      samlToken &&
      <form
      ref={formRef}
      action="https://alchemyvision.myabsorb.com/api/rest/v2/authentication/saml"
      method="POST"
      target="_new"
    >
      <input
        type="hidden"
        name="SAMLResponse"
        value={samlToken}
      />
    </form>
    }
      
    <div className="home-dashboard">
      <section className="section-dashboard">
        <Container className="small-container">
          <Row className="gx-5 row justify-content-center">
            <div className="col-sm-6">
              <div className="common-box common-box-height">
                <div className="partition">
                  <Row style={{paddingLeft: '15px'}}>
                    <Col md={2} xs={3}>
                      <img src={Team} alt="common" />
                    </Col>
                    <Col md={9} xs={9} className="vertically-center">
                      <span className="common-text">My Practice</span>
                      <br />
                    </Col>
                  </Row>
                </div>
                <div className='partition-container'>
                  <div className="common-partition">
                    <Link to="/my-team#manage-team" className="common-inside-text">
                      <Row className='common-row'>
                       <Col md={2} xs={3}>
                          <img src={Arrow} alt="common" className="common-img" />
                        </Col>
                        <Col md={9} xs={9} style={{paddingLeft: '20px'}}>
                            Manage Licenses
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  <div className="common-partition no-border-display">
                    <Link to="/my-team#practice-details" className="common-inside-text">
                      <Row className='common-row'>
                     
                        <Col md={2} xs={3}>
                          <img src={List} alt="common" className="common-img" />
                        </Col>
                        <Col md={9} xs={9} style={{paddingLeft: '20px'}}>
                            Practice Details
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mt-mobile-8">
              <div className="common-box common-box-height">
                <div className="partition">
                  <Row style={{paddingLeft: '15px'}}>
                    <Col md={2} xs={3}>
                      <img src={Myprofile} alt="common" />
                    </Col>
                    <Col md={9} xs={9} className="vertically-center">
                      <span className="common-text">My Subscription</span>
                      <br />
                    </Col>
                  </Row>
                </div>
                <div className='partition-container'>
                  <div className="common-partition">
                  <Link  to="/my-practice#manage-subscription" className="common-inside-text">
                    <Row className='common-row'>
                      <Col md={2} xs={3}>
                        <img src={Setting} alt="common" className="common-img" />
                      </Col>
                      <Col md={9} xs={9} style={{paddingLeft: '20px'}}>
                          Manage Subscription
                      </Col>
                    </Row>
                    </Link>
                  </div>
                  <div className="common-partition no-border-display">
                    <Link to="/my-practice#billing-details" className="common-inside-text">
                      <Row className='common-row'>
                        <Col md={2} xs={3}>
                          <img
                            src={Dollorlist}
                            alt="common"
                            className="common-img"
                          />
                        </Col>
                        <Col md={9} xs={9} style={{paddingLeft: '20px'}}>
                            Billing Details
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Row> 
          <Row className="row justify-content-center mt-5 learning-content gx-2">
            <Col md={7} style={{fontSize:'18px',wordSpacing:'3px'}}>
                <span>View progress reports, team analytics, and<br/> learning content in your Leader Dashboard</span>
            </Col>
            <Col md={5} style={{margin:'auto'}}>
                <button className="launch-lms" onClick={()=>{leaderRedirect()}}>My Leader Dashboard</button>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
    </>
  );
}

export default Dashboard;
