import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Subtitle from "../widgets/Subtitle";
import Title from "../widgets/Title";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionList } from "../../store/subscription/effect";
import { GetListSubscriptionState } from "../../types/Subscription.type";
import Grow from "../loader/Grow";
import Pagination from "../mypractice/Pagination";
import DashboardNav from "../../pages/widgets/DashboardNav";

function Subscriptioninvoices(params: any) {
  const dispatch = useDispatch();
  const subscriptionListState = useSelector(
    (state: GetListSubscriptionState) =>
      state.subscriptionState.subscriptionlist
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [dateSort, setDateSort] = useState(false);
  const [invoiceData,setInvoiceData] = useState([]);
  const [sortdo,setSortdo] = useState(0)
  const [currentTableData,setCurrentTableData] = useState([])

  React.useEffect(() => {
    dispatch(subscriptionList());
  }, [dispatch]);

  const formatDate = (date: any) => {
    var d = new Date(date),
      day = "" + d.getDate(),
      year = d.getFullYear();
    let month = d.toLocaleString("default", { month: "long" });
    if (day.length < 2) day = "0" + day;

    return day + ` ` + month + ` ` + year;
  };

  useEffect(()=>{
    setInvoiceData(subscriptionListState?.data?.invoices)
  },[subscriptionListState?.data?.invoices])

  let PageSize = 3;
  // const currentTableData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   return subscriptionListState?.data?.invoices?.slice(
  //     firstPageIndex,
  //     lastPageIndex
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentPage, subscriptionListState?.data?.invoices]);

    React.useEffect(() => {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      setCurrentTableData(subscriptionListState?.data?.invoices?.slice(
        firstPageIndex,
        lastPageIndex
      ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, invoiceData,sortdo]);


  const SortOrder = (type:any) =>{
      let tempData;
      if(type==='createdAt'){
        if(dateSort===false){
          setDateSort(true)
        }else{
          setDateSort(false)
        }
        tempData = subscriptionListState?.data?.invoices?.sort(dynamicSort(type,!dateSort));
      }
    setInvoiceData(tempData);
    setSortdo(sortdo+1)
    }

    function dynamicSort(property:any,reverse:any) {
          var sortOrder = 1;
          if(property[0] === "-") {
              sortOrder = -1;
              property = property.substr(1);
          }
          return function (a:any,b:any) {
              /* next line works with strings and numbers, 
              * and you may want to customize it to your needs
              */
            var result;
            if(reverse===false){
              result = (new Date(a[property]) < new Date(b[property])) ? -1 : (new Date(a[property]) > new Date(b[property])) ? 1 : 0;
              } else{
              result = (new Date(a[property]) > new Date(b[property])) ? -1 : (new Date(a[property]) < new Date(b[property])) ? 1 : 0;
              }
            return result * sortOrder;
          }
      }
  



  return (
    <>
      <Col md={12} className="mt-5 pb-5">
        <div className="common-box">
          <div className="partition practice-color">
            <Row>
              <Col md={4}>
              <DashboardNav/>
                <span className="common-text">Billing Invoices</span>
                <br />
              </Col>
            </Row>
          </div>

          <div className="partition">
            <Row className="gx-5 justify-content-center">
              <div className="row" style={{borderBottom:'2px solid #3B6494',width:'80%'}}>  
                <Col md={5} xs={4} className="justify-content-center billing-header">
                  <div onClick={()=>SortOrder('createdAt')}><Title title="Charge Date"   /><i className="fa fa-angle-down" aria-hidden="true"></i></div>
                  <br />
                </Col>
                <Col md={2} xs={4} className="billing-header">
                  <Title title="Total" />
                  <br />
                </Col>
                <Col md={2} xs={4} className="billing-header">
                  <Title title="Invoice No."  />
                  <br />
                </Col>
              </div>
            </Row>
            {!subscriptionListState.loading ? (
              currentTableData && currentTableData?.length > 0 ? (
                currentTableData?.map((list: any, index: number) => {
                  return (
                    <Row
                      key={index}
                      className="gx-5 justify-content-center mt-3"
                    >
                      <div className="row" style={{borderBottom:'1px solid #A9C2DE',width:'80%',padding:'10px'}}>
                      <Col md={5} xs={4} className="justify-content-center">
                        <Subtitle subtitle={formatDate(list.createdAt)} />
                        <br />
                      </Col>
                      <Col md={2} xs={4}>
                        <Subtitle subtitle={"$" + list.amount} />
                        <br />
                      </Col>
                      <Col md={2} xs={4}>
                        <Subtitle subtitle={"AV" + list.id} />
                        <br />
                      </Col>
                      </div>
                    </Row>
                  );
                })
              ) : (
                <>
                  <div className="no-data-found">
                    <h4>No Data Found</h4>
                  </div>
                </>
              )
            ) : (
              <div className="mt-5 mb-5">
                <Grow />
              </div>
            )}
          </div>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={subscriptionListState?.data?.invoices?.length}
            pageSize={PageSize}
            onPageChange={(page: any) => setCurrentPage(page)}
          />
          <br />
          <br />
        </div>
      </Col>
    </>
  );
}

export default Subscriptioninvoices;
