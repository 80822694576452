import React from 'react'
import review1 from "../../assets/images/review-1.svg"
import review2 from "../../assets/images/review-image.svg"

function Testimonials() {
  return (
    <div className="row mt-5 gx-4 justify-content-center">
        <div className="col-md-6">
        <div className="review">
            <p className="review-text">"Staff training is critically important to practice success.  I'm happy to support Alchemy Vision in the important mission of making employee education easier and better."</p>
            <div className="row  gx-5" style={{alignItems:'center',marginTop:'55px'}}>
                <div className="col-md-4 col-lg-3 review-image">
                    <img src={review1} alt='review' />
                </div>
                <div className="col-md-8 col-lg-9">
                    <h4 className="review-name">Richard L. Lindstrom</h4>
                    <h6 className="review-post">Board Certified Ophthalmologist Minnesota Eye Consultants</h6>
                </div>
            </div>
        </div>
        </div>
        <div className="col-md-6">
        <div className="review">
            <p className="review-text">“Alchemy Vision has empowered my staff to work more efficiently and confidently, resulting in smoother workflows and happier patients. I’ve seen noticeable improvements and it has elevated the standard of our care.”</p>
            <div className="row mt-4 gx-5" style={{alignItems:'center'}}>
                <div className="col-md-4 col-lg-3 review-image">
                    <img src={review2} alt='review' />
                </div>
                <div className="col-md-8 col-lg-9" >
                    <h4 className="review-name">Mitch Shultz, MD</h4>
                    <h6 className="review-post">Cataract & Refractive Surgeon Shultz Chang Vision</h6>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Testimonials