import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./header.css";
import "./header.scss";
import Logo from "../../../../src/assets/images/Logo-Color.svg";
import Homeicon from "../../../../src/assets/images/profile.svg";
import { Link } from "react-router-dom";
import {AppConfig} from "../../../configs/app.config";
import accountimg from "../../../../src/assets/images/account.svg";
import accountWhite from "../../../../src/assets/images/accountw.svg";
import logoutimg from "../../../../src/assets/images/logout.svg";
import logoutimgs from "../../../../src/assets/images/logoutw.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Mainheader(props: any) {
  const [accountImgs,setAccountImgs] =  useState(accountimg);
  const [logouts,setLogouts] = useState(logoutimg);
  const navigate = useNavigate();
  
  const nav = (param:string) => {
    // swal({
    //   title: "Are you sure you want to logout?",
    //   icon: "warning",
    //   buttons: [true, t55rue],
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
    if(param === '/logout'){
        localStorage.clear();
        window.open(AppConfig.logoutRedirectUrl, "_self");
      }else{
        navigate('/my-account')
      }
    // });
  };

  return (
    <>
      <Navbar variant="light" className="admin-nav headerscss">
        <Container className= 'small-container container' fluid>
          <div className="header-image">
            <Link to="/dashboard">
              <img src={Logo} alt="AV Logo" />{" "}
            </Link>
          </div>
          <h1 className="header-center-text justify-content-center">
            {props.header}
          </h1>
          <div className="header-image home-icon">

          <div className="dropdown">
            <img src={Homeicon} className="dropbtn" alt="icon" />{" "}
              <div className="dropdown-content">
                 <div className="drop-link" onClick={()=>{nav('/my-account')}} onMouseEnter={e => (setAccountImgs(accountWhite))} onMouseOut={e => (setAccountImgs(accountimg))} ><img  className="pointer-none" src={accountImgs} alt='account' />&nbsp;&nbsp;My Profile</div>
                 <div className="drop-link" onClick={()=>{nav('/logout')}} onMouseEnter={e => (setLogouts(logoutimgs))} onMouseOut={e => (setLogouts(logoutimg))} ><img src={logouts} className="pointer-none" alt='logout'  />&nbsp;&nbsp; Logout</div>
              </div>
          </div>

          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default Mainheader;
