import * as actionTypes from "./actions";
import { RegisterState } from "../../types/Register.type";

const initialState: RegisterState = {
  registerUser: {
    loading: false,
    data: null,
    error: null,
  },
  registerData: {
    loading: false,
    data: null,
    error: null,
    reset:false
  },
};

const RegisterReducer = (
  state: RegisterState = initialState,
  action: any
): RegisterState => {
  switch (action.type) {
    case actionTypes.REGISTER_USER:
      return {
        ...state,
        registerUser: {
          loading: true,
        },
      };

    case actionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        registerUser: {
          data: action?.data,
          loading: false,
        },
      };

    case actionTypes.REGISTER_USER_FAILED:
      return {
        ...state,
        registerUser: {
          error: action?.error,
          loading: false,
        },
      };
    case actionTypes.RESET_REGISTER:
      return {
        ...state,
        registerUser: {
          loading: false,
          data: null,
          error: null,
        },
      };
      case actionTypes.REGISTER_DATA:
      return {
          ...state,
          registerData: {
            loading: false,
            data: action.registerData,
            error: null,
            reset:false,
          },
        };
        case actionTypes.REGISTER_DATA_RESET:
        return {
            ...state,
            registerData: {
              loading: false,
              data: null,
              error: null,
              reset:false,
            },
          };

        case actionTypes.SET_RESET_TRUE:
          return {
              ...state,
              registerData: {
                loading: false,
                data: null,
                error: null,
                reset:true,
              },
            };
  }
  return state;
};

export default RegisterReducer;
