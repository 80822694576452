import React, { useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Subtitle from "../widgets/Subtitle";
import Title from "../widgets/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPracticetState,
  GetEditPracticeState,
} from "../../types/Practice.type";
import { getPractice, editPractice } from "../../store/medicalpractice/effect";
import Grow from "../loader/Grow";
import Spinner from "react-bootstrap/Spinner";
import SimpleReactValidator from "simple-react-validator";
import { RESET_PRACTICEEDIT } from "../../store/medicalpractice/actions";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Rightcheck from "../../assets/images/rightcheck.svg";
import DashboardNav from "./../widgets/DashboardNav";

function Practicedetails(params: any) {
  const dispatch = useDispatch();
  const practiceState = useSelector(
    (state: GetPracticetState) => state.practiceState.practice
  );
  const [edit, setEdit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [editData, setEditData]: any = useState({
    practiceName: "",
    website: "",
    address: "",
    contactNumber: "",
    suite:"",
    city: "",
    postalCode:"",
    country: "",
  });
  const editPracticeState = useSelector(
    (state: GetEditPracticeState) => state.practiceState.editPractice
  );
  const [show5, setShow5] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(0);

  const handleClose5 = () => {
    setShow5(false);
    simpleValidator.current.hideMessages();
  };
  const handleShow5 = () => {
    setShow5(true);
    setSuccess(false);
    setEditData({
      ...editData,
      ...{
        practiceName: practiceState?.data?.medicalPractice?.practiceName,
        website: practiceState?.data?.medicalPractice?.website,
        address: practiceState?.data?.medicalPractice?.location?.address,
        suite: practiceState?.data?.medicalPractice?.location?.suite,
        city: practiceState?.data?.medicalPractice?.location?.city,
        postalCode: practiceState?.data?.medicalPractice?.location?.postalCode,
        country: practiceState?.data?.medicalPractice?.location?.country,
        contactNumber: practiceState?.data?.medicalPractice?.contactNumber,
      },
    });
  };

  function useForceUpdate() {
    return () => setValue((value) => value + 1);
  }
  const forceUpdate = useForceUpdate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        regex: "Please Enter Valid Value",
        min:" Must Be 11 Characters Long",
      },
      validators: {
        urlregex: {
          message: "Please Enter Valid URL",
          rule: (val: any, params: any): any => {
            // eslint-disable-next-line no-useless-escape
            var regex = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
            return regex.test(val);
          },
        },
      },
    })
  );

  React.useEffect(() => {
    if (editPracticeState?.data?.success === true) {
      setSuccess(true);
      dispatch({ type: RESET_PRACTICEEDIT });
      dispatch(getPractice());
      setEdit(false);
      handleClose5();
    }
    if (editPracticeState?.error) {
      toast.error(editPracticeState?.error?.data?.message, {
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPracticeState]);

  React.useEffect(() => {
    dispatch(getPractice());
    simpleValidator.current.hideMessages();
  }, [dispatch]);

  React.useEffect(() => {}, [practiceState]);

  const updateValue = (event: any) => {
    const { name, value } = event.target;
    simpleValidator.current.showMessageFor(name);
    setEditData({ ...editData, [name]: value });
  };

  React.useEffect(() => {
    setEditData({
      ...editData,
      ...{
        practiceName: practiceState?.data?.medicalPractice?.practiceName,
        website: practiceState?.data?.medicalPractice?.website,
        address: practiceState?.data?.medicalPractice?.address,
        contactNumber: practiceState?.data?.medicalPractice?.contactNumber,
        suite: practiceState?.data?.medicalPractice?.location?.suite,
        city: practiceState?.data?.medicalPractice?.location?.city,
        postalCode: practiceState?.data?.medicalPractice?.location?.postalCode,
        country: practiceState?.data?.medicalPractice?.location?.country,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceState.data]);

  const updateDetails = () => {
    if (edit === false) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  };

  const updateAccount = (event: any) => {
    setValidated(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate();
    } else {
      const sendData = {
        contactNumber: editData.contactNumber,
        location: {address: editData.address, suite:editData.suite,country: editData.country,city: editData.city, state:editData.state, postalCode:editData.postalCode},
        name: editData.name,
        practiceName: editData.practiceName,
        website:editData.website
      }
      dispatch(editPractice(sendData));
    }
  };

  function formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    else{
      return phoneNumberString;
    }
  }

  return (
    <>
      <Modal show={show5} onHide={handleClose5} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Update Practice Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row className="gx-5 justify-content-center pt-5 pb-2 justify-content-center">
            <Col md={6}>
              <label htmlFor="Practice Name" className="learner-label">
                Practice Name
              </label>
              <br />
              <input
                type="text"
                id="practiceName"
                name="practiceName"
                value={editData?.practiceName}
                onChange={(e) => {
                  updateValue(e);
                }}
                className="form-control mt-1 no-radious"
              />
              {simpleValidator.current.message(
                "practiceName",
                editData.practiceName,
                "required|max:100"
              )}
              <br />
            </Col>
            <Col md={6}>
              <label htmlFor="Practice Website" className="learner-label">
                Practice Website
              </label>
              <br />
              <input
                type="text"
                id="website"
                name="website"
                value={editData?.website}
                onChange={(e) => {
                  updateValue(e);
                }}
                className="form-control mt-1 no-radious"
              />
              {simpleValidator.current.message(
                "website",
                editData.website,
                "required|max:100|urlregex"
              )}
              <br />
            </Col>
          </Row>
          <Row className="gx-5 justify-content-center  justify-content-center">
            <Col md={6}>
              <label htmlFor="Practice Address" className="learner-label">
                Practice Address
              </label>
              <br />
              <input
                type="text"
                id="address"
                name="address"
                value={editData?.address}
                onChange={(e) => {
                  updateValue(e);
                }}
                className="form-control mt-1 no-radious"
              />
              {simpleValidator.current.message(
                "address",
                editData.address,
                "required|max:100"
              )}
              <br />
            </Col>
            <Col md={6}>
                  <label htmlFor="Practice Phone" className="learner-label mt-1">
                    Practice Phone
                  </label>
                  <br />
                  <PhoneInput
                    country={"us"}
                    disableDropdown={true}
                    inputProps={{
                      id: "contactNumber",
                      name: "contactNumber",
                      className: "form-control mt-1 no-radious",
                    }}
                    value={editData?.contactNumber}
                    onChange={(e) =>
                      updateValue({
                        target: {
                          name: "contactNumber",
                          value: e,
                        },
                      })
                    }
                    countryCodeEditable={false}
                  />
                  {simpleValidator.current.message(
                    "contactNumber",
                    editData.contactNumber,
                    "required|min:11"
                  )}
                  <br />
            </Col>
          </Row>
       
          <Row className="gx-5 justify-content-center  justify-content-center">
       
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleClose5();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            <button
              className="assign-btn"
              onClick={(e) => {
                updateAccount(e);
              }}
            >
              {editPracticeState.loading && (
                <Spinner className="loader" animation="border" />
              )}
              Save Practice Details
            </button>
          </Col>
        </Row>
      </Modal>
      <Col md={12} id="practice-details" className="mt-5">
        <div className="common-box">
          <div className="partition practice-color">
            <Row>
              <Col md={6}>
                <DashboardNav />
                <span className="common-text">Practice Details</span>
                <br />
              </Col>
              <Col md={6} className="text-right update-class common-text-success">
                {success ? (
                  <>
                    <img src={Rightcheck} alt="right check" />
                    <span>&nbsp;&nbsp;Practice details were updated</span>
                  </>
                ) : (
                  <></>
                )}
                <br />
              </Col>
            </Row>
          </div>
          {!practiceState.loading ? (
            <>
              <form
                noValidate
                onSubmit={(e) => {
                  updateAccount(e);
                }}
              >
                <div className="partition">
                  <Row className="gx-5 justify-content-center justify-content-center">
                    <Col md={5}>
                          <Title title="Practice Name" />
                          <br />
                          <Subtitle
                            subtitle={
                              practiceState?.data?.medicalPractice?.practiceName
                            }
                          />
                          <br />
                    </Col>
                    <Col md={4}>
                        <Title title="Practice Website" />
                        <br />
                        <Subtitle
                          subtitle={
                            practiceState?.data?.medicalPractice?.website
                          }
                        />
                        <br />
                    </Col>
                  </Row>
                </div>
                <div className="partition">
                  <Row className="gx-5 justify-content-center justify-content-center">
                    <Col md={5}>
                          <Title title="Practice Address" />
                          <br />
                          <div style={{ width: "60%" }}>
                            <Subtitle
                              subtitle={
                                practiceState?.data?.medicalPractice?.location?.address
                              }
                            />
                            <br />
                          </div>
                    </Col>
                    <Col md={4}>
                        <Title title="Practice Phone" />
                        <br />
                        <Subtitle
                          subtitle={
                            practiceState?.data?.medicalPractice
                              ?.contactNumber
                              ? formatPhoneNumber(
                                  practiceState?.data?.medicalPractice
                                    ?.contactNumber
                                )
                              : "No number"
                          }
                        />
                        <br />
                    </Col>
                  </Row>
                </div>
                <div className="partition">
                  <Row className="gx-5 justify-content-center justify-content-center">
              
                    <Col md={4}></Col>
                  </Row>
                </div>
              </form>
            </>
          ) : (
            <div className="mt-5 mb-5">
              <Grow />
            </div>
          )}
          <div className="partition-last mt-5 text-center">
            {edit === false ? (
              <Row className="gx-5 update-btn-border">
                <Col md={12} className="text-center ">
                  <button
                    className="update-practice-detail "
                    onClick={handleShow5}
                  >
                    Update Practice Details
                  </button>
                </Col>
              </Row>
            ) : (
              <Row className="gx-5 update-btn-border">
                <Col md={6} className="text-center main-div-last">
                  <button
                    type="button"
                    className="cancel-btn"
                    onClick={() => {
                      updateDetails();
                    }}
                  >
                    Cancel
                  </button>
                </Col>
                <Col
                  md={6}
                  className="text-center main-div-last save-my-details-div"
                  style={{ background: "#97998d" }}
                >
                  <button
                    type="button"
                    className="save-my-details"
                    onClick={(e) => {
                      updateAccount(e);
                    }}
                    disabled={editPracticeState.loading}
                  >
                    {editPracticeState.loading && (
                      <Spinner className="loader" animation="border" />
                    )}
                    Save My Details
                  </button>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Col>
    </>
  );
}

export default Practicedetails;
