/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./header.scss";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Avlogo from "../../../../src/assets/images/logo.svg";
import Avxlogo from "../../../../src/assets/images/avxaao.svg";
import Button from "react-bootstrap/esm/Button";
// import apiService from "@services/api.service";
import { AppConfig } from "../../../configs/app.config";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function MainHeader() {
  const location = useLocation();
  const [token,setToken] = useState();
  let accessToken:any;
  useEffect(()=>{
     // eslint-disable-next-line react-hooks/exhaustive-deps
     accessToken = JSON.parse(localStorage?.getItem("accessToken") as string);
     setToken(accessToken?.token)
  },[])

  // const navigateToLogin = () => {
  //   apiService.login().then((resp) => {
  //     if (resp?.data?.url) {
  //       window.open(resp?.data?.url, "_blank");
  //     }
  //   }).catch((error) => {
  //     console.error('error: ', error);
  //   })
  // }

  const navigateToHome = () => {
      window.open('/academy', "_self");
  }
  

  return (
    <>
    {
       localStorage?.getItem("accessToken") && location.pathname!=='/'   ? 
     <Navbar collapseOnSelect expand="sm" className="colored-navbar">
     <Container  className="header-pending small-container">
       <Link to= {'/academy#access_token='+token} >
         <img src={Avxlogo} alt="avlogo" style={{height:'50px',width: 'auto' }} />
       </Link>
       <Navbar.Toggle aria-controls="responsive-navbar-nav" />
       <Navbar.Collapse id="responsive-navbar-nav" className="menu-main">
         <Nav className="me-auto"></Nav>
         <Nav>
           <div className="menu-btn">
             <Link to= {'/academypricing?changequantity=true'} className="menu-button2">
               ← Back to Pricing
             </Link>
           </div>
         </Nav>
       </Navbar.Collapse>
     </Container>
      </Navbar>
      :
      <Navbar collapseOnSelect expand="sm" className="colored-navbar">
      <Container fluid="xxl" className="header-pending">
        <a href="https://www.alchemyvision.com">
          <img src={Avlogo} alt="avlogo" style={{height:'50px',width: 'auto' }} />
        </a>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="menu-main">
          <Nav className="me-auto"></Nav>
          <Nav>
            <a className="menu-text nav-link"  href={AppConfig.productUrl} target="_self" rel="noreferrer">
            <div style={{ paddingBottom: '4px' }}>Product</div> 
            </a>
            <Link className="menu-text nav-link" to='/' >
              <div className={`${location.pathname === '/' ? 'active' : ''}`}>Pricing</div>
            </Link>
            <a className="menu-text nav-link" href={AppConfig.blog} target="_self" rel="noreferrer">
              Blog
            </a>
            <a className="menu-text nav-link" href={AppConfig.loginurl} target="_blank"  rel="noreferrer">
              Login
            </a>
            <div className="menu-btn">
              <Button className="menu-button" onClick={navigateToHome}>
                Join Now
              </Button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  }
   </>
  );
}

export default MainHeader;
