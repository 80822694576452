import React,{ useState } from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import 'reactjs-popup/dist/index.css';
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function Plan({ plan }: { plan: any }) {
  const [exist, setExist] = useState(true);
   React.useEffect(() => {
      const checkTypewriter = document.querySelector(".header-pending");
      if (!checkTypewriter) {
        setExist(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const navigate = useNavigate();
  
  const planRedirect = (id:any) =>{
      if(exist===true){
        navigate('/signup', { state: {planid:id,from:'aao'} })
      }else{
        navigate('/signupwithoutheader', { state: {planid:id,from:'aao'} })
      }
  }

  return (
      <Col md={4} sm={12} className='mobile-margin-bottom'>
        <Card className="plan-card">
          <div style={{background:'#F2F6FC'}} className='c-body pt-3 pb-4'>
            <div style={{padding:'0px 20px 0px 20px',minHeight:'243px'}}>
              <div style={{display:'flex',justifyContent:'space-between'}}>
                <img src={plan?.icon} alt="plan" height='50px' width='50px'  />
                {plan?.flags?.mostPopular && 
                <div className="most-popular-main" style={{ background:'#3E87CB'}}>
                  <span  className="most-popular">💫 Most Popular</span>
                </div>
                }
              </div>
                   <h2 className='charge-sub custome-margin' >{plan?.title}</h2>
                   <h4 className='charge-secondorytext mt-4' style={{fontSize:20}}>{`Exclusive price for Academy members:`}</h4>
                <br/>
                <span className='plan-price ' style={{color:'#5E3A84'}} >${plan?.displayPrice}</span><span className='price-simple-text' style={{color:'#545454'}}> monthly</span><br/><br/>
                <span className='price-simple-text' style={{color:'#545454',fontSize:18,fontWeight:400}}> Non-member price:</span><br/> <span className='price-simple-text' style={{color:'#545454',fontSize:18,fontWeight:600}}> ${plan?.metadata?.nonMemberPrice} monthly</span>
              </div>
            <div  className="mt-4  charge-padding">
              <Button  onClick={()=>{planRedirect(plan.id)}} className="get-started-button" style={{background:'#F68D2E'}}>Get started</Button>
            </div>
          </div>
        </Card>
    </Col>
  );

  

}

export default Plan;
