/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./header.scss";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Avlogo from "../../../../src/assets/images/logo.svg";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";
// import apiService from "@services/api.service";

function MainHeader() {
  
  const navigate = useNavigate();

  // const navigateToLogin = () => {
  //   apiService.login().then((resp) => {
  //     if (resp?.data?.url) {
  //       window.open(resp?.data?.url, "_blank");
  //     }
  //   }).catch((error) => {
  //     console.error('error: ', error);
  //   })
  // }

  const navigateToHome = () => {
    navigate('/pricing?changequantity=true');
  }


  return (
    <Navbar collapseOnSelect expand="sm" className="colored-navbar">
      <Container  className="header-pending2 small-container">
        <a href="https://www.alchemyvision.com">
          <img src={Avlogo} alt="avlogo" style={{height:'50px',width: 'auto' }} />
        </a>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="menu-main">
          <Nav className="me-auto"></Nav>
          <Nav>
            <div className="menu-btn">
              <Button className="menu-button2" onClick={navigateToHome}>
                ← Back to Pricing
              </Button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainHeader;
