/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Subtitle from "../widgets/Subtitle";
import Title from "../widgets/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSubscriptionState,
  GetDeleteSubscriptionState,
} from "../../types/Subscription.type";
import {
  getsubscription,
  deleteSubscription,
} from "../../store/subscription/effect";
import Grow from "../loader/Grow";
import Modal from "react-bootstrap/Modal";
import { RESET_DELETESUBSCRIPTION } from "../../store/subscription/actions";
import Spinner from "react-bootstrap/Spinner";
import {  useNavigate } from "react-router-dom";
import Rightcheck from "../../assets/images/rightcheck.svg";
import Addicon from "../../assets/images/Addiconm.svg";
import Messageicon from "../../assets/images/messageicon.svg";
import Rmicon from "../../assets/images/RmIcon.svg";
import DashboardNav from "../../pages/widgets/DashboardNav";
import Manageteam from "../../pages/myteam/Manageteam";
import Redo from "../../assets/images/redo.svg";
import ContactEmail from "../../assets/images/contact-email.svg";
import ContactPhone from "../../assets/images/contact-phone.svg";
import moment from "moment";
import {
  GetEditPracticePlanState,
} from "../../types/Plan.type";

function Practicedetails(params: any) {
  const dispatch = useDispatch();
  const subscriptionState = useSelector(
    (state: GetSubscriptionState) => state.subscriptionState.subscription
  );
  const navigate = useNavigate();
  const deleteSubscriptionState = useSelector(
    (state: GetDeleteSubscriptionState) =>
      state.subscriptionState.deletesubscription
  );

  const [show6, setShow6] = useState(false);
  const [success, setSuccess] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const updatePlanState = useSelector(
    (state: GetEditPracticePlanState) => state.planState.editPlan
  );
  const handleClose6 = () => {
    setShow6(false);
  };
  const handleShow6 = () => {
    setSuccess(false);
    setShow6(true);
  };

    React.useEffect(() => {
      if (updatePlanState?.data?.success === true) {
        setSuccess(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePlanState]);

  React.useEffect(() => {
    if (deleteSubscriptionState?.data?.success === true) {
      setSuccess(true);
      dispatch({ type: RESET_DELETESUBSCRIPTION });
      dispatch(getsubscription());
      handleClose6();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSubscriptionState]);

  const cancelSubscription = () => {
    dispatch(deleteSubscription());
  };

  React.useEffect(() => {
    dispatch(getsubscription());
  }, [dispatch]);

  React.useEffect(() => {}, [subscriptionState]);

  const ref:any = useRef();

  return (
    <>
      <Modal show={show6} onHide={handleClose6} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Cancel your Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          {subscriptionState?.data?.subscription?.cancelAt === null ? (
            <>
              <Row className="gx-5 justify-content-center justify-content-center">
                <Modal.Title style={{ color: "#012A4A" }}>
                Here’s what you should know before you cancel your subscription:
                </Modal.Title>
              </Row>
              <Row className="gx-5 justify-content-center">
                <Col md={6} xs={12} className="mt-5 mb-5">
                  <div>
                    <button type="button" className="number">1</button>
                  </div>
                  <div className="mt-5">
                    <span className="cancel-text">
                        Your plan will expire at the end of your billing cycle on {moment(subscriptionState?.data?.subscription?.billingCycleEnd).format("MMMM Do YYYY")}. After that time, your learners will no longer have access to their courses.
                    </span>
                  </div>
                </Col>
                <Col md={6} xs={12} className="mt-5 mb-5">
                  <div>
                    <button type="button" className="number">2</button>
                  </div>
                  <div className="mt-5">
                    <span className="cancel-text">
                    Your team’s data and learning progress will be saved in our system, so you can rejoin Alchemy Vision at any time in the future.
                    </span>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="gx-5 justify-content-center justify-content-center">
                <Modal.Title style={{ color: "black" }}>
                  Restore Subscription
                </Modal.Title>
              </Row>
              <Row className="gx-5 justify-content-center"></Row>
            </>
          )}
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleClose6();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>

          <Col
            md={6}
            xs={12}
            className="footer-section-2 text-center"
            style={{ background: "#A34F52" }}
          >
            {subscriptionState?.data?.subscription?.cancelAt === null ? (
              <button
                disabled={deleteSubscriptionState.loading}
                className="assign-btn"
                onClick={() => {
                  cancelSubscription();
                }}
                style={{ background: "#A34F52" }}
              >
                {deleteSubscriptionState.loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Cancel Subscription
              </button>
            ) : (
              <button
                disabled={deleteSubscriptionState.loading}
                className="assign-btn"
                onClick={() => {
                  cancelSubscription();
                }}
                style={{ background: "#A34F52" }}
              >
                {deleteSubscriptionState.loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Restore Subscription
              </button>
            )}
          </Col>
        </Row>
      </Modal> 
      <Modal show={contactModal} onHide={handleClose6} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Contact Our Support Team</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
              <Row className="gx-3 justify-content-center">
                <Col md={6} className="justify-content-center" style={{textAlign:'center'}}>
                  <div className="contact-us mt-2 mb-2">
                    <img src={ContactPhone} alt="" /><br/><br/>
                    <span className="contact-text">Contact us by phone</span><br/><br/>
                    <a href="tel:+1 213 510 8050" className="contact-info">+1 213 510 8050</a><br/><br/>
                  </div>
                </Col>
                <Col md={6} className="justify-content-center" style={{textAlign:'center'}}>
                  <div className="contact-us mt-2 mb-2">
                    <img src={ContactEmail} alt="" /><br/><br/>
                    <span className="contact-text">Contact us by email</span><br/><br/>
                    <a href="mailto:hello@alchemyvision.com" className="contact-info">hello@alchemyvision.com</a><br/><br/>
                  </div>
                </Col>
              </Row>
              <Row className="gx-5 justify-content-center"></Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={12} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                setContactModal(false)
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Modal> 
      <Manageteam ref={ref} />
      <Col md={12} className="mt-5" id="manage-subscription">
        <div className="common-box">
          <div className="partition practice-color">
            <Row>
              <Col md={12} lg={6} >
                <DashboardNav />
                <span className="common-text" style={{fontSize:22}}>Manage Subscription</span>
                <br />
              </Col>
              <Col md={6} className="text-right update-class">
                {success ? (
                  <>
                    <img src={Rightcheck} alt="right check" />
                    <span className="common-success-text">
                      &nbsp;&nbsp;Subscription has been updated
                    </span>
                  </>
                ) : (
                  <></>
                )}
                <br />
              </Col>
            </Row>
          </div>
          {!subscriptionState.loading ? (
            <>
              <div className="partition">
                <Row className="gx-5 justify-content-center justify-content-center">
                  <Col md={12} lg={5}>
                    <Title title="Subscription Status" />
                    <br />
                    {subscriptionState?.data?.subscription?.cancelAt ===
                    null ? (
                      <Subtitle
                        subtitle={subscriptionState?.data?.subscription?.status}
                      />
                    ) : (
                      <h4 style={{ color: "#A34F52" }}>
                        Active until{" "}
                        {
                        moment(subscriptionState?.data?.subscription?.cancelAt).calendar()
                        }
                      </h4>
                    )}
                    <br /><br/><br/>
                    <Title title="No. of Licenses" />
                    <br />
                    <div className="manage-width">
                      <span className="common-description">
                        {subscriptionState?.data?.subscription?.licenses.map(
                          (license: any, i: any) => {
                            // Return the element. Also pass key
                            return (
                              <div key={i}>
                                <span>
                                  {license.seats}
                                </span>
                                <br />
                              </div>
                            );
                          }
                        )}
                     
                      </span>
                    </div><br/><br/>
                    <Title title="Member Since" />
                      <br />
                      <Subtitle
                        subtitle={moment(subscriptionState?.data?.subscription?.memberSince).format("MMMM Do YYYY")}
                      />
                      <br />
                  </Col>
                  <Col md={12} lg={4}>
                    <Title title="Actions" />
                    <br />
                    <Row className="gx-5">
                      <Col md={12} xs={12}>
                      {subscriptionState?.data?.subscription?.cancelAt ===null ?
                        <div className="mt-2">
                          <button  className="actions" onClick={()=>{ ref?.current?.showManageLicense();}} style={{height:53,width:'95%',paddingRight:'0'}}><img src={Addicon} alt='rmicon' />&nbsp;&nbsp;&nbsp;&nbsp;Add / remove licenses</button>
                        </div>
                        :  
                        <div className="mt-2">
                          <button  className="actions-success" onClick={()=>cancelSubscription()} disabled={deleteSubscriptionState.loading} style={{height:53,width:'95%',paddingRight:'0'}}>  {deleteSubscriptionState.loading && (
                  <Spinner className="loader" animation="border" />
                )} <img src={Redo} alt='redo' />&nbsp;&nbsp;&nbsp;&nbsp;Undo cancellation</button>
                        </div>
                      }
                      </Col>
                      <Col md={12} xs={12}>
                        <div className="mt-2">
                          <button  className="actions" style={{height:53,width:'95%',paddingRight:'0'}} onClick={()=>{setContactModal(true)}}><img src={Messageicon} alt='rmicon' />&nbsp;&nbsp;&nbsp;&nbsp;Contact our Support Team</button>
                        </div>
                      </Col>
                      <Col md={12} xs={12}>
                      {subscriptionState?.data?.subscription?.cancelAt ===null &&
                        <div className="mt-2">
                          <button onClick={()=>{handleShow6()}}  className="actions-danger" style={{height:53,width:'95%',paddingRight:'0'}}><img src={Rmicon} alt='rmicon' />&nbsp;&nbsp;&nbsp;&nbsp;Cancel your subscription</button>
                        </div>
                      }
                      </Col>
                    </Row>
                    <br />
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="mt-5 mb-5">
              <Grow />
            </div>
          )}
          <div className="partition-last mt-5 text-center">
            <Row className="gx-5 update-btn-border">
              <Col md={12} className="text-center ">
                  <button
                    onClick={() => {
                      navigate('/my-team#manage-team');
                    }}
                    className="update-practice-detail"
                  >
                    <span style={{fontWeight:500}}>Want to view your licences?</span> <span style={{fontWeight:500,textDecoration:'underline'}}>Manage Licenses</span>
                  </button>
              </Col>  
            </Row>
          </div>
        </div>
      </Col>
    </>
  );
}

export default Practicedetails;
