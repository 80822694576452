import React from 'react'
import { Link } from "react-router-dom";
import backbutton from "../../assets/images/Back Button.svg"

function DashboardNav() {
  return (
    <>
         <Link to='/dashboard'><img src={backbutton} alt='back-btn' height={46} width={46} style={{marginTop:'-7px'}} /></Link>
    </>
  )
}

export default DashboardNav