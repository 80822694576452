import React, { useState } from "react";
import "./subscriptionsummery.scss";
import { GetRegisterDataState } from "../../types/Register.type";
import { useSelector } from "react-redux";
import { GetPlanState } from "../../types/Plan.type";
import { Link } from "react-router-dom";
import { GetApplyCouponState } from "../../types/Coupon.type";
// import { useNavigate } from "react-router-dom";

function Subscriptionsummery() {
  const registerDataState: any = useSelector(
    (state: GetRegisterDataState) => state?.registerState.registerData
  );
  const applyCouponState = useSelector(
    (state: GetApplyCouponState) => state?.couponState.applyCoupon
  );

  // const navigate = useNavigate();
  const [price, setPrice] = useState(0);

  function formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const planState = useSelector(
    (state: GetPlanState) => state?.planState.plans
  );

  React.useEffect(()=>{
    if(registerDataState?.data?.workEmail === undefined){
      // navigate('/signup')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  React.useEffect(() => {
    let tempPrice = 0;
    planState?.data?.forEach((plan: any, index: number) => {
      if (plan.quantity > 0) {
        tempPrice += plan.monthlyPrice * plan.quantity;
      }
    });
    setPrice(tempPrice);
  }, [planState?.data]);

  return (
    <>
      <section className="subscriptionsummary">
        {/* <h2 className="text-center">Let’s wrap things up</h2>
          <div className="subscription-sub-text-box mb-5">
            <span className="subscription-sub-text">
              Make sure that everything looks right before finishing up!
            </span>
          </div> */}
        <div className="row">
          <div className="col-md-12 mb-2">
            <b className="lable-elm-info">
              Subscription Info{" "}
              <Link to="/plans?edit=true" className="edit">
                EDIT
              </Link>
              <div className="box">
                <div className="row">
                  {planState?.data?.map((plan: any,index:number) => {
                    return (
                      <>
                        {plan.quantity > 0 && (
                          <div key={index} className="row">
                            <div className="col-md-8 col-8 col-sm-8">
                              <span className="heading-title">{plan.name==='Complimentary' ? 'Leaders' : plan.name}</span>
                            </div>
                            <div className="col-md-2 col-2 col-sm-2 text-right">
                              <span className="heading-desc">
                                {plan.quantity}
                              </span>
                            </div>
                            <div className="col-md-2 col-2 col-sm-2 text-right">
                              <span className="heading-desc">
                                ${plan.monthlyPrice}
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                  {applyCouponState?.lastDiscount > 0 && (
                    <>
                      <div className="row">
                        <div className="col-md-8 col-8 col-sm-8">
                          <span className="heading-title">
                            Reseller Discount
                          </span>
                        </div>
                        <div className="col-md-2 col-2 col-sm-2 text-right">
                          <span className="heading-desc"></span>
                        </div>
                        <div className="col-md-2 col-2 col-sm-2 text-right">
                          <span className="heading-desc">
                            -${applyCouponState?.lastDiscount}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <br />
                <div className="row mt-5">
                  <div className="col-md-8 col-sm-8 col-8">
                    <span className="lable-elm-info">MONTHLY TOTAL</span>
                  </div>
                  <div className="col-md-1 col-1 col-sm-1">
                    <span className="heading-desc"></span>
                  </div>
                  <div className="col-md-3 col-sm-3 col-3 text-left">
                    {
                      applyCouponState?.lastDiscount > 0 ? 
                     <span className="lable-elm-info">${price-applyCouponState?.lastDiscount}</span> 
                     :
                     <span className="lable-elm-info">${price}</span> 
                    } 
                    </div>
                </div>
              </div>
            </b>
          </div>
          <br />
          <div className="col-md-12 ">
            <b className="lable-elm-info">
              <br />
              Practice Info{" "}
              <Link to="/signup" className="edit">
                EDIT
              </Link>
              <div className="box">
                <div className="row">
                  <div className="col-md-7">
                    <span className="heading-title">Practice Name </span>
                    <span className="heading-desc">
                      <br/>{registerDataState?.data?.practiceName} <br />
                      {registerDataState?.data?.practiceUrl} <br />
                      {formatPhoneNumber(registerDataState?.data?.phoneNo)}
                    </span>
                    <br />
                    <span className="heading-desc">
                      {registerDataState?.data?.address},<br />
                      {registerDataState?.data?.city} ,
                      {registerDataState?.data?.state}
                      <br />
                      {registerDataState?.data?.country +
                        `-` +
                        registerDataState?.data?.postalCode}
                    </span>
                  </div>
                </div>
              </div>
            </b>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12 mb-2">
            <b className="lable-elm-info">
              Point of Contact Info &nbsp; &nbsp;
              <Link to="/signup#point-of-contact" className="edit">
                EDIT
              </Link>
              <div className="box">
                <div className="row">
                  <div className="col-md-8">
                    <span className="heading-title">Name Surname </span><br/>
                    <span className="heading-desc">{registerDataState?.data?.firstname} {registerDataState?.data?.lastname}</span><br/>
                    <span className="heading-desc">{registerDataState?.data?.workEmail}</span>
                  </div>
                </div>
              </div>
            </b>
          </div>
        </div>
      </section>
    </>
  );
}

export default Subscriptionsummery;
