import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import apiService from "../../services/api.service";
import { register, registerDataStore } from "../../store/register/effect";
import { useDispatch, useSelector } from "react-redux";
import {
  GetRegisterState,
  registerError,
  GetRegisterDataState,
} from "../../types/Register.type";
import { toast } from "react-toastify";
import { RESET_REGISTER } from "../../store/register/actions";
import Spinner from "react-bootstrap/Spinner";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput from "react-phone-input-2";
import { GetApplyCouponState } from "../../types/Coupon.type";
import { RESET_APPLY_COUPON } from "../../store/coupons/actions";
import { getCoupons } from "../../store/coupons/effect";

function Preview({ signup, planId }: { signup: any; planId: any }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validatedcoupon, setValidatedCoupon] = useState(false);
  const [exist, setExist] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [couponDetails, setCouponDetails] = useState<any>();
  const [freeTrialLoading, setFreeTrialLoading] = useState(false);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        not_regex: "Please Enter Valid Value",
      },
      validators: {
        urlregex: {
          message: "Please Enter Valid URL",
          rule: (val: any, params: any): any => {
            // eslint-disable-next-line no-useless-escape
            var regex = new RegExp(
              "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
            );
            return regex.test(val);
          },
        },
      },
    })
  );
  const couponValidator = useRef(
    new SimpleReactValidator({
      messages: {
        not_regex: "Please Enter Valid Value",
      },
    })
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const applyCouponState = useSelector(
    (state: GetApplyCouponState) => state?.couponState.applyCoupon
  );

  React.useEffect(() => {
    dispatch(getCoupons());
    const checkTypewriter = document.querySelector(".header-pending");
    if (!checkTypewriter) {
      setExist(false);
    }
    var tempTotal = 0;

    if (signup === null) {
      if (tempTotal === 0) {
        navigate("/");
      }
    }

    if (registerDataState?.data?.practiceName) {
      // if (applyCouponState.lastApplied) {
      //   const selectedPlanObj = planState?.data?.map((plan: SinglePlan) => {
      //     return {
      //       id: plan.id,
      //       quantity: plan.quantity,
      //     };
      //   });
      //   setApplyCouponData({
      //     ...applyCouponData,
      //     couponCode: applyCouponState.lastApplied,
      //   });
      //   setToastshow(false);
      //   dispatch(
      //     applyCouponCode(applyCouponState.lastApplied, selectedPlanObj)
      //   );
      // }
    } else {
      dispatch({
        type: RESET_APPLY_COUPON,
        data: null,
        lastApplied: null,
        lastDiscount: null,
      });
    }
    const str = window.location.href;
    const after_ = str.slice(str.indexOf("#") + 1);
    var elem = document.getElementById(after_);
    elem && elem.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    couponValidator.current.hideMessages();
  }, [applyCouponState?.data]);

  const [registerData, setRegisterData] = useState({
    practiceName: "",
    practiceUrl: "",
    contactNumber: "",
    address: "",
    suite: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    firstname: "",
    lastname: "",
    professionalTitle: "",
    workEmail: "",
    additionalInfo: "",
    referall:""
  });
  const registertState:any = useSelector(
    (state: GetRegisterState) => state?.registerState.registerUser
  );
  const registerDataState: any = useSelector(
    (state: GetRegisterDataState) => state?.registerState.registerData
  );

  const [errors, setErrors] = useState<any>(null);

  React.useEffect(() => {
    if(errors){
    const element = document.getElementById('point-of-contact'); 
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' }); 
    }
  }
  }, [errors]);

  React.useEffect(() => {
    if (registerDataState?.data) {
      setRegisterData({
        practiceName: registerDataState?.data?.practiceName,
        practiceUrl: registerDataState?.data?.practiceUrl,
        contactNumber: registerDataState?.data?.contactNumber,
        address: registerDataState?.data?.address,
        suite: registerDataState?.data?.suite,
        city: registerDataState?.data?.city,
        state: registerDataState?.data?.state,
        country: registerDataState?.data?.country,
        postalCode: registerDataState?.data?.postalCode,
        firstname: registerDataState?.data?.firstname,
        lastname: registerDataState?.data?.lastname,
        professionalTitle: registerDataState?.data?.professionalTitle,
        workEmail: registerDataState?.data?.workEmail,
        additionalInfo: registerDataState?.data?.additionalInfo,
        referall: registerDataState?.data?.referall,
      });
    }
  }, [registerDataState]);

  React.useEffect(() => {
    if (registertState?.error) {
      if (registertState?.error?.data?.message) {
        toast.error(registertState?.error?.data?.message, {
          position: "top-right",
        });
      }
      if (registertState?.error?.statusText) {
        toast.error(registertState?.error?.statusText, {
          position: "top-right",
        });
      } else {
        let obj: any = {};
        if (registertState?.error?.length > 0) {
          registertState?.error?.forEach((elem: registerError) => {
            obj[`${elem.param}`] = elem.msg;
          });
        }
        setErrors(obj);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registertState?.error]);


  React.useEffect(() => {
    if (registertState?.data != null) {
      dispatch({ type: RESET_REGISTER });
      setErrors(null);
      dispatch(registerDataStore(registerData));
      toast.success("Successfully Registered", {
        position: "top-right",
      });
      if (signup === null) {
        setValidated(false);
      
        simpleValidator.current.hideMessages();
        if (exist === true) {
          navigate("/payment", {
           state: { clientSecret: registertState?.data?.clientSecret,registerDataState:registerData,planId:planId },
          });
        } else {
          navigate("/paymentwithoutheader", {
            state: { clientSecret: registertState?.data?.clientSecret,registerDataState:registerData,planId:planId },
          });
        }
        setRegisterData({
          practiceName: "",
          practiceUrl: "",
          contactNumber: "",
          address: "",
          suite: "",
          city: "",
          country: "",
          state: "",
          postalCode: "",
          firstname: "",
          lastname: "",
          professionalTitle: "",
          workEmail: "",
          additionalInfo: "",
          referall:""
        });
      
      } else {
        const selectePlanStatic = [{ id: atob(planId), qty: 1 }];
        const payLoad = {
          plans: selectePlanStatic,
          medicalPracticeId: registertState?.data,
        };
        setValidated(false);
        setRegisterData({
          practiceName: "",
          practiceUrl: "",
          contactNumber: "",
          address: "",
          suite: "",
          city: "",
          state: "",
          country: "",
          postalCode: "",
          firstname: "",
          lastname: "",
          professionalTitle: "",
          workEmail: "",
          additionalInfo: "",
          referall:""
        });
        simpleValidator.current.hideMessages();

        apiService
          .createPayment(payLoad)
          .then((resp) => {
            if (resp) {
              navigate("/thankyou");
            }
          })
          .catch((errorData) => {
            if (registertState?.error?.data?.message) {
              toast.error(errorData?.data?.message, {
                position: "top-right",
              });
            }
            const { response } = errorData;
            const error = response;
            toast.error(error?.data?.message, {
              position: "top-right",
            });
          });
      }
    } else {
      setValidated(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registertState]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputs = (event: any) => {
    var { name, value } = event.target;
    if (value === "+") {
      return false;
    }
    let value2 = value.replace("+", "");
    simpleValidator.current.showMessageFor(name);
    if (errors && errors[name] !== null) {
      errors[name] = "";
    }
    setRegisterData({ ...registerData, [name]: value2 });
  };

  const handleSubmit = (event: any) => {
    setValidated(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
    } else {
      localStorage.setItem("email", registerData.workEmail);
      const data = {
        practiceInfo:{
          name: registerData.practiceName,
          practiceName: registerData.practiceName,
          workEmail: registerData.workEmail,
          website: registerData.practiceUrl,
          contactNumber: registerData.contactNumber,
          location: { 
            address: registerData.address,
            suite: registerData.suite,
            city: registerData.city,
            country: registerData.country,
            state: registerData.state,
            postalCode: registerData.postalCode,
        }
      },
        pointOfContact: {
          firstName: registerData.firstname,
          lastName: registerData.lastname,
          professionalTitle: registerData.professionalTitle,
          workEmail: registerData.workEmail,
        },
        additionalInfo: registerData.additionalInfo ? registerData.additionalInfo : undefined,
        discoverySource:registerData.referall ? registerData.referall : undefined,
        licenseTemplateId:planId
      };

      dispatch(register(data));
    }
  };

  const continueFreeTrial = () => {
    setValidated(true);
    setFreeTrialLoading(true);

    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
    } else {
      localStorage.setItem("email", registerData.workEmail);
      const data: any = {
        practiceName: registerData.practiceName,
        website: registerData.practiceUrl,
        location: {
          address: registerData.address,
          suite: registerData.suite,
          city: registerData.city,
          country: registerData.country,
          state: registerData.state,
          postalCode: registerData.postalCode,
        },
        pointOfContact: {
          firstName: registerData.firstname,
          lastName: registerData.lastname,
          professionalTitle: registerData.professionalTitle,
          workEmail: registerData.workEmail,
          phoneNumber: registerData.contactNumber,
        },
      };

      apiService
        .register(data)
        .then((response) => {
          const plans: any = applyCouponState.data?.freeTrailPlans?.map(
            (plan) => {
              return {
                id: plan?.id,
                quantity: plan?.seats,
              };
            }
          );

          const payLoad = {
            plans: [...plans, { id: 4, quantity: 1 }],
            medicalPracticeId: response?.data?.data?.medicalPracticeId,
            couponId: applyCouponState?.data?.id,
            freeTrial: applyCouponState?.data?.freeTrail,
          };

          apiService
            .createFreeTrial(payLoad)
            .then((resp) => {
              if (resp) {
                apiService.thankyouemail = registerData.workEmail;
                navigate("/thankyou");
              }
              setFreeTrialLoading(false);
            })
            .catch((error) => {
              setFreeTrialLoading(false);
            });
        })
        .catch((error) => {
          setFreeTrialLoading(false);
        });

      // dispatch(register(data));
    }
  };

  const handleCheckBox = (e:any) =>{
    if(e.target.checked){
      setRegisterData({ ...registerData, referall: e.target.value });
    }
  }



  return (
    <div>
      {/* <h1 className="preview-av">Let’s get some info</h1> */}
      <div className="plans">
        <b className="lable-elm-info">Practice Info</b>
        <br />
        <br />
     
        <form className="signup-form" noValidate onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="practiceName"
                  maxLength={100}
                  placeholder="Practice Name"
                  value={registerData.practiceName}
                />
                {simpleValidator.current.message(
                  "practiceName",
                  registerData.practiceName,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.practiceName}</span>
            </Col>
            <Col md={6}>
              <Form.Group
                as={Col}
                className="form-elem"
              >
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  placeholder="Practice website"
                  onChange={handleInputs}
                  maxLength={100}
                  name="practiceUrl"
                  value={registerData.practiceUrl}
                />
                {simpleValidator.current.message(
                  "practiceUrl",
                  registerData.practiceUrl,
                  "required|urlregex|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.practice_url}</span>
            </Col>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <PhoneInput
                  country={"us"}
                  disableDropdown={true}
                  inputProps={{
                    id: "contactNumber",
                    name: "contactNumber",
                    className: "form-elem-single",
                    placeholder:"Practice phone number"
                  }}
                  value={registerData?.contactNumber}
                  onChange={(e) =>
                    handleInputs({
                      target: {
                        name: "contactNumber",
                        value: e,
                      },
                    })
                  }
                  countryCodeEditable={false}
                />
              </Form.Group>
              <span className="color-red">{errors?.contactNumber}</span>
              {simpleValidator.current.message(
                "contactNumber",
                registerData.contactNumber,
                "required|max:15|min:11"
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="address"
                  placeholder="Address"
                  maxLength={100}
                  value={registerData.address}
                />
                {simpleValidator.current.message(
                  "address",
                  registerData.address,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.address}</span>
            </Col>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="suite"
                  placeholder="Suite (Optional)"
                  maxLength={50}
                  value={registerData.suite}
                />
                {simpleValidator.current.message(
                  "suite",
                  registerData.suite,
                  "max:50"
                )}
              </Form.Group>
              <span className="color-red">{errors?.suite}</span>
            </Col>
            <Col md={4}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="city"
                  placeholder="City"
                  maxLength={100}
                  value={registerData.city}
                />
                {simpleValidator.current.message(
                  "city",
                  registerData.city,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.city}</span>
            </Col>
            <Col md={4}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="state"
                  placeholder="State"
                  maxLength={100}
                  value={registerData.state}
                />
                {simpleValidator.current.message(
                  "state",
                  registerData.state,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.state}</span>
            </Col>
            <Col md={4}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="postalCode"
                  placeholder="Postal Code"
                  maxLength={50}
                  value={registerData.postalCode}
                />
                {simpleValidator.current.message(
                  "postalCode",
                  registerData.postalCode,
                  "required|max:50"
                )}
              </Form.Group>
              <span className="color-red">{errors?.postalCode}</span>
            </Col>
            <Col md={4}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="country"
                  placeholder="Country"
                  maxLength={100}
                  value={registerData.country}
                />
                {simpleValidator.current.message(
                  "country",
                  registerData.country,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.country}</span>
            </Col>
          </Row>
          <br />
          <b className="lable-elm-info" id="point-of-contact">
            Point of Contact Info
          </b>
          <br />
          <br />
          <Row>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="firstname"
                  placeholder="First name"
                  maxLength={100}
                  value={registerData.firstname}
                />
                {simpleValidator.current.message(
                  "firstname",
                  registerData.firstname,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.firstname}</span>
            </Col>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="lastname"
                  placeholder="Last name"
                  maxLength={100}
                  value={registerData.lastname}
                />
                {simpleValidator.current.message(
                  "lastname",
                  registerData.lastname,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.lastname}</span>
            </Col>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="professionalTitle"
                  placeholder="Work title"
                  maxLength={100}
                  value={registerData.professionalTitle}
                />
                {simpleValidator.current.message(
                  "professionalTitle",
                  registerData.professionalTitle,
                  "required|max:100"
                )}
              </Form.Group>
              <span className="color-red">{errors?.professionalTitle}</span>
            </Col>
            <Col md={6}>
              <Form.Group
                as={Col}
                controlId="validationCustom01"
                className="form-elem"
              >
                <Form.Control
                  required
                  type="text"
                  className="form-elem-single"
                  onChange={handleInputs}
                  name="workEmail"
                  id='workEmail'
                  placeholder="Work Email"
                  maxLength={100}
                  value={registerData.workEmail}
                />
                {simpleValidator.current.message(
                  "workEmail",
                  registerData.workEmail,
                  "required|email|max:100"
                )}
              </Form.Group>
              <div className="minus-margin">
              <span className="color-red">{errors?.workEmail}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <br />
              <b className="lable-elm-info">Additional Info</b>
              <br />
              <br />
              <Form.Group
                as={Col}
                className="form-elem"
              >
                <Form.Control
                  as="textarea"
                  name="additionalInfo"
                  id="additionalInfo"
                  placeholder="What should we know about your practice?"
                  value={registerData.additionalInfo}
                  className="form-elem-single"
                  onChange={handleInputs}
                  maxLength={200}
                  style={{
                    height: "100px",
                    background: "#F7F7F7",
                    border: "1px solid #DBD9D9",
                    borderRadius: 0,
                  }}
                />
                {simpleValidator.current.message(
                  "additionalInfo",
                  registerData.additionalInfo,
                  "max:200"
                )}
              </Form.Group>
              <span className="color-red">{errors?.additionalInfo}</span>
            </Col>
            <Col md={6}>
              <br />
              <b className="lable-elm-info">How did you hear about us?</b>
              <br />
              <Form.Group
                as={Col}
                className="form-elem"
              >
                <br />
                <div className="radio-group row gx-2" id='reffrals-grp'>
                  <div className="col-md-4">
                    <label className="lable-elm radio-referal">
                      <input
                        name="referall"
                        type={`radio`}
                        className="ref-radio"
                        value={`Referral`}
                        readOnly
                        checked={registerData.referall==='Referral'}
                        onClick={(e)=>{handleCheckBox(e)}}
                      />
                      Referral
                    </label>
                  </div>
                  <div className="col-md-4">
                    <label className="lable-elm radio-referal">
                      <input
                        name="referall"
                        type={`radio`}
                        className="ref-radio"
                        value={`Social Media`}
                        readOnly
                        checked={registerData.referall==='Social Media'}
                        onClick={(e)=>{handleCheckBox(e)}}
                      />
                      Social Media
                    </label>
                  </div>
                  <div className="col-md-4">
                    <label className="lable-elm radio-referal">
                      <input
                        name="referall"
                        type={`radio`}
                        className="ref-radio"
                        value={`Print Media`}
                        readOnly
                        checked={registerData.referall==='Print Media'}
                        onClick={(e)=>{handleCheckBox(e)}}
                     />
                      Print Media
                    </label>
                  </div>
                  <div className="col-md-4">
                    <label className="lable-elm radio-referal">
                      <input
                        name="referall"
                        type={`radio`}
                        className="ref-radio"
                        value={`Google`}
                        readOnly
                        checked={registerData.referall==='Google'}
                        onClick={(e)=>{handleCheckBox(e)}}
                     />
                      Google
                    </label>
                  </div>
                  <div className="col-md-4">
                    <label className="lable-elm radio-referal">
                      <input
                        name="referall"
                        type={`radio`}
                        className="ref-radio"
                        value={`Event`}
                        readOnly
                        checked={registerData.referall==='Event'}
                        onClick={(e)=>{handleCheckBox(e)}}
                     />
                      Event
                    </label>
                  </div>
                  <div className="col-md-4">
                    <label className="lable-elm radio-referal">
                      <input
                        name="referall"
                        type={`radio`}
                        className="ref-radio"
                        value={`Other`}
                        readOnly
                        checked={registerData.referall==='Other'}
                        onClick={(e)=>{handleCheckBox(e)}}
                     />
                      Other
                    </label>
                  </div>
                </div>
              </Form.Group>
              <span className="color-red">{errors?.discoverySource}</span>
            </Col>
          </Row>
          {/* <br />
          <b className="lable-elm-info">Apply Coupon</b>
          <br />
          <br /> */}

          {/* <Row>
            <Col md={9}>
              <Form.Group as={Col} controlId="coupan-box" className="form-elem">
                <Form.Control
                  required
                  type="text"
                  name="couponCode"
                  className="form-elem-single"
                  onChange={handleCoupanInputs}
                  value={applyCouponData.couponCode}
                  maxLength={50}
                  placeholder="Apply Coupon Code"
                  style={{ marginBottom: "15px" }}
                />
                {couponValidator.current.message(
                  "couponCode",
                  applyCouponData.couponCode,
                  "required|max:50"
                )}
                {applyCouponState?.data?.status === true && (
                  <span className="text-success">
                    Coupon Applied Successfully
                  </span>
                )}
                {applyCouponState?.error && (
                  <span className="text-danger">
                    {applyCouponState?.error?.message}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col md={3}>
              <button
                type="button"
                className="coupan-btn"
                onClick={applyCouponCodeForm}
              >
                Apply Coupon
              </button>
            </Col>
          </Row> */}

          {applyCouponState?.data?.freeTrail && (
            <p style={{ color: "#0d800d" }}>You are eligible for free trial</p>
          )}

          <Row>
            <Col md={9}></Col>
            <Col md={9}></Col>
          </Row>

          <div className="get-stated mt-5">
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <h1 className={`team-price ${applyCouponState?.data?.freeTrail ? 'line-through' : '' }`}>
                ${total}/mo {discount !== 0 && `(-$` + discount + `)`}
              </h1>

              {applyCouponState?.data?.freeTrail && <span className="free-trial-indicator">Free Trial</span>}
            </div> */}

            {!applyCouponState?.data?.freeTrail && (
              <Button
                type="submit"
                onClick={handleSubmit}
                className="team-start-btn"
                disabled={registertState.loading}
              >
                {registertState.loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Continue to Billing Info
            </Button>
            )}

            {applyCouponState?.data?.freeTrail && (
              <Button
                type="button"
                onClick={continueFreeTrial}
                className="team-start-btn"
                disabled={registertState.loading}
              >
                {freeTrialLoading && (
                  <Spinner className="loader" animation="border" />
                )}
                Continue with Free Trial
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Preview;
