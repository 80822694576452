/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./Myteam.scss";
import Manageteam from "./Manageteam";
import { useSelector } from "react-redux";
import Practicedetails from "./../mypractice/Practicedetails";

function Myteam() {
  const freshLearnerState = useSelector(
      (state: any) => state.learnerState.learnerlist
    );
  React.useEffect(() => {
    if(freshLearnerState?.data){
      setTimeout(() => {
        const str = window.location.href;
        const after_ = str.slice(str.indexOf("#") + 1);
        var elem = document.getElementById(after_);
        elem && elem.scrollIntoView();        
      }, 100);
  }
  }, [freshLearnerState?.data]);

  return (
    <>
      <div className="my-team">
        <section className="section-practice">
          <Container>
            <Row className="mt-5">
              <Manageteam />
              <div className="my-practice mt-5">
                <Practicedetails />
              </div>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}

export default Myteam;
