import * as actionTypes from "./actions";
import { PlanState, SinglePlan } from "../../types/Plan.type";
const initialState: PlanState = {
  plans: {
    loading: false,
    data: [],
    error: null,
  },
  aaoplans: {
    loading: false,
    data: [],
    error: null,
  },
  practicePlans:{
    loading: false,
    data: [],
    error: null,
    total:null,
    cancelSeatsTotal:null,
    legacySeatsTotal:null
  },
  editPlan:{
    loading: false,
    data: [],
    error: null,
  },
  planFeatures:{
    loading: false,
    data: [],
    error: null,
  },
};

function increment(state: PlanState, action: any) {
  if (state?.plans?.data) {

    let practicePlans = state?.plans?.data.map((item: any, index: number) => {
   
      if (item.id === action.id) {
        let qty = item.quantity || 0;
        qty = qty + 1;
        item.quantity = qty;
        let config;
        config = checkPlanConfig(item,item.quantity);
        let getPrice
        if(item.planConfig.length > 0){
          getPrice = checkPrice(item,item.quantity)
        }
        item.rules= config.rules;
        item.nextConfig = config.nextConfig;
        item.nextConfigPrice = config.nextConfigPrice;
        item.monthlyPrice = getPrice? getPrice:item.monthlyPrice;
        item.rules= config.rules;
      }
      return item;
    });
    return practicePlans;
  }
}

function checkPlanConfig(plan:any,qty:number){
  let nextConfig;
  let nextConfigPrice;
  const rules = [];
  let firstRule = {rule:`1 - `+(plan?.planConfig[0]?.minLearner-1),price:plan?.originalPrice}
  rules.push(firstRule)
  for (let index = 0; index < plan?.planConfig.length; index++) {
      const element = plan?.planConfig[index];
      if(qty  <= element.minLearner ){
        nextConfig = element.minLearner
        nextConfigPrice = element.price
        rules.push({rule:element?.minLearner+` - `+element?.maxLearner,price:element?.price})
        break;
      }
      rules.push({rule:element?.minLearner+` - `+element?.maxLearner,price:element?.price})
    }
  let returnValue = {
    nextConfig:nextConfig,
    rules:rules,
    nextConfigPrice:nextConfigPrice
  }
  return returnValue;
}

function checkPrice(plan:any,qty:number){
  let price:any;
  for (let index = 0; index < plan.planConfig.length; index++) {
    const element = plan.planConfig[index];
    if(qty  >= element.minLearner && qty <= element.maxLearner ){
        price = element.price;
        break
    }else{
        price = plan.originalPrice;
    }
  }
  return price;
}

const incrementPractice =  (state: PlanState, action: any) => {
  if (state?.practicePlans?.data) {
    let practicePlans = state?.practicePlans?.data.map((item: any, index: number) => {
      if (item.id === action.id) {
        let qty = item.totalSeats || 0;
        qty = qty + 1;
        let getPrice
        let config;
        config = checkPlanConfig(item,qty);
        item.nextConfig = config.nextConfig;
        item.nextConfigPrice = config.nextConfigPrice;
        if(item.planConfig.length > 0){
         getPrice=checkPrice(item,qty)
        }
        let availableSeats = item.availableSeats+1
        item.totalSeats = qty;
        item.rules= config.rules;
        item.monthlyPrice = getPrice? getPrice:item.monthlyPrice;
        item.availableSeats = availableSeats;
      }
      return item;
    });
    return practicePlans;
  }
}


function decrementPractice(state: PlanState, action: any) {
  if (state?.practicePlans?.data) {
    let practicePlans = state?.practicePlans?.data.map((item: any, index: number) => {
      if (item.id === action.id) {
        let qty = item.totalSeats || 0;
        let availableSeats = item.availableSeats-1
        qty = qty - 1;
        let config;
        config = checkPlanConfig(item,qty);
        item.nextConfig = config.nextConfig;
        item.nextConfigPrice = config.nextConfigPrice;
        if(item.availableSeats > 0){
          let getPrice;
          if(item.planConfig.length > 0){
          getPrice=checkPrice(item,qty-1)
          }
          item.rules= config.rules;
          item.monthlyPrice = getPrice || item.monthlyPrice;
          item.availableSeats = availableSeats;
          item.totalSeats = qty;
        }
      }
      return item;
    });
    return practicePlans;
  }
}

function decrement(state: PlanState, action: { type: string; id: number }) {
  if (state?.plans?.data) {
    const plans = state?.plans?.data.map((item: any, index: number) => {
      if (item.id === action.id) {
        let qty = item.quantity || 0;
        let config;
        config = checkPlanConfig(item,qty);
        item.nextConfig = config.nextConfig;
        item.rules= config.rules;
        let getPrice;
        if(item.planConfig.length > 0){
         getPrice=checkPrice(item,qty-1)
        }
        item.monthlyPrice = getPrice? getPrice:item.monthlyPrice;
        item.nextConfigPrice = config.nextConfigPrice;
        if (item.planType === 'leader') {
          if (qty > 1) {
            qty = qty - 1;
            item.quantity = qty;
          }
        } else {
          if (qty > 0) {
            qty = qty - 1;
            item.quantity = qty;
          }
        }
      }
      return item;
    });
    return plans;
  }
}

const PlanReducer = (
  state: PlanState = initialState,
  action: any
): PlanState => {
  switch (action.type) {
    case actionTypes.GET_PLANS:
      return {
        ...state,
        plans: {
          loading: true,
        },
      };

    case actionTypes.GET_PLANS_SUCCESS:
      const assignPlans = action?.data.map(
        (item: SinglePlan, index: number) => {
          let qty = 0;
          if (item.planType === 'leader') {
            qty = 1;
          }
          item.quantity = qty;
          item.originalPrice = item.monthlyPrice;
          return item;
        }
      );
      return {
        ...state,
        plans: {
          data: assignPlans,
          loading: false,
        },
      };

    case actionTypes.GET_PLANS_FAILED:
      return {
        ...state,
        plans: {
          error: action.error,
          loading: false,
        },
      };

    case actionTypes.GET_AAO_PLANS:
      return {
        ...state,
        aaoplans: {
          loading: true,
        },
      };

    case actionTypes.GET_AAO_PLANS_SUCCESS:
      const assignAAOPlans = action?.data.map(
        (item: SinglePlan, index: number) => {
          let qty = 0;
          if (item.planType === 'leader') {
            qty = 1;
          }
          item.quantity = qty;
          item.originalPrice = item.monthlyPrice;
          return item;
        }
      );
      return {
        ...state,
        aaoplans: {
          data: assignAAOPlans,
          loading: false,
        },
      };

    case actionTypes.GET_AAO_PLANS_FAILED:
      return {
        ...state,
        aaoplans: {
          error: action.error,
          loading: false,
        },
      };


      case actionTypes.GET_PRACTICEPLANS:
        return {
          ...state,
          practicePlans: {
            loading: true,
          },
        };
  
      case actionTypes.GET_PRACTICEPLANS_SUCCESS:
        const assignPracticePlans = action?.data.map(
          (item: any, index: number) => {
            let getPrice;
            if(item.planConfig.length > 0){
              getPrice=checkPrice(item,item.totalSeats)
            }
            item.originalPrice = item.monthlyPrice;
            item.oldQty = item.totalSeats;
            item.monthlyPrice = getPrice || item.monthlyPrice;
            return item;
          }
        );
        return {
          ...state,
          practicePlans: {
            data: assignPracticePlans,
            loading: false,
            total:action.total,
            cancelSeatsTotal:action.cancelSeatsTotal,
            legacySeatsTotal:action.legacySeatsTotal
          },
        };
  
      case actionTypes.GET_PRACTICEPLANS_FAILED:
        return {
          ...state,
          practicePlans: {
            error: action.error,
            loading: false,
          },
        };

        case actionTypes.EDIT_LICENSE:
          return {
            ...state,
            editPlan: {
              loading: true,
            },
          };
    
        case actionTypes.EDIT_LICENSE_SUCCESS:
          return {
            ...state,
            editPlan: {
              data: action?.data,
              loading: false,
            },
          };
    
        case actionTypes.EDIT_LICENSE_FAILED:
          return {
            ...state,
            editPlan: {
              error: action.error,
              loading: false,
            },
            
          };

          case actionTypes.RESET_EDIT_LICENSE:
            return {
              ...state,
              editPlan: {
                loading: false,
                data: [],
                error: null,
              },
            };
            case actionTypes.GET_PLAN_FEATURE:
              return {
                ...state,
                planFeatures: {
                  loading: true,
                },
              };
        
            case actionTypes.GET_PLAN_FEATURE_SUCCESS:
              return {
                ...state,
                planFeatures: {
                  data: action?.data,
                  loading: false,
                },
              };
        
            case actionTypes.GET_PLAN_FEATURE_FAILED:
              return {
                ...state,
                planFeatures: {
                  error: action.error,
                  loading: false,
                },
              };


    case actionTypes.INCREMENT:
      let plans = increment(state, action);
      return {
        ...state,
        plans: {
          data: plans,
          loading: false,
        },
      };

    case actionTypes.DECREMENT:
      let decrmentplans = decrement(state, action);

      return {
        ...state,
        plans: {
          data: decrmentplans,
          loading: false,
        },
      };

      case actionTypes.INCREMENT_PRACTICE:
        let practicePlans = incrementPractice(state, action);
        return {
          ...state,
          practicePlans: {
            data: practicePlans,
            loading: false,
            total:action.total,
            cancelSeatsTotal:action.cancelSeatsTotal,
            legacySeatsTotal:action.legacySeatsTotal
          },
        };
  
      case actionTypes.DECREMENT_PRACTICE:
        let decrmentPracticePlans = decrementPractice(state, action);
  
        return {
          ...state,
          practicePlans: {
            data: decrmentPracticePlans,
            loading: false,
            total:action.total,
            cancelSeatsTotal:action.cancelSeatsTotal,
            legacySeatsTotal:action.legacySeatsTotal
          },
        };

      
  }
  return state;
};

export default PlanReducer;
