import * as actionTypes from "./actions";
import { CouponState } from "../../types/Coupon.type";

const initialState: CouponState = {
  coupons: {
    loading: false,
    data: [],
    error: null,
  },
  applyCoupon: {
    loading: false,
    data: null,
    error: null,
    lastApplied:null,
    lastDiscount:null,
  },
};

const CouponReducer = (
  state: CouponState = initialState,
  action: any
): CouponState => {
  switch (action.type) {
    case actionTypes.GET_COUPON:
      return {
        ...state,
        coupons: {
          loading: true,
        },
      };

    case actionTypes.GET_COUPON_SUCCESS:
      return {
        ...state,
        coupons: {
          data: action.data,
          loading: false,
        },
      };

    case actionTypes.GET_COUPON_FAILED:
      return {
        ...state,
        coupons: {
          error: action.error,
          loading: false,
        },
      };
    case actionTypes.APPLY_COUPON:
      return {
        ...state,
        applyCoupon: {
          loading: true,
        },
      };

    case actionTypes.APPLY_COUPON_SUCCESS:
      return {
        ...state,
        applyCoupon: {
          data: action.data,
          loading: false,
        },
      };

    case actionTypes.APPLY_COUPON_FAILED:
      return {
        ...state,
        applyCoupon: {
          error: action.error,
          loading: false,
        },
      };
    case actionTypes.RESET_APPLY_COUPON:
      return {
        ...state,
        applyCoupon: {
          loading: false,
        //   error: null,
          lastApplied: action.coupon,
          data: action.data
        //   lastDiscount: action.discount,
        //   freeTrial: state.applyCoupon.freeTrial,
        },
      };
  }
  return state;
};

export default CouponReducer;
