import apiService from "../../services/api.service";
import * as actionTypes from "./actions";

export const getLearners = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_LEARNER,
    });
    apiService
      .gerLerners()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_LEARNER_SUCCESS,
          data: response.profiles,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_LEARNER_FAILED,
          error,
        });
      });
  };
};

export const getFreshLearners = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_FRESHLEARNER,
    });
    apiService
      .gerFreshLerners()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_FRESHLEARNER_SUCCESS,
          data: response.profiles,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_FRESHLEARNER_FAILED,
          error,
        });
      });
  };
};

export const getExamState = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_EXAMSTATES,
    });
    apiService
      .getExamState()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_EXAMSTATES_SUCCESS,
          data: response.data,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_EXAMSTATES_FAILED,
          error,
        });
      });
  };
};

export const getLoginState = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.GET_LOGINSTATES,
    });
    apiService
      .getLoginState()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.GET_LOGINSTATES_SUCCESS,
          data: response.data,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.GET_LOGINSTATES_FAILED,
          error,
        });
      });
  };
};

export const sendReport = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.SEND_LOGINSTATES,
    });
    apiService
      .sendReport()
      .then(({ data, ...rest }) => {
        const response = data;
        dispatch({
          type: actionTypes.SEND_LOGINSTATES_SUCCESS,
          data: response.data,
        });
      })
      .catch((errorData) => {
        const { response } = errorData;
        const error = response;
        dispatch({
          type: actionTypes.SEND_LOGINSTATES_FAILED,
          error,
        });
      });
  };
};
