import React, { useState } from 'react'
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AAOlogo from "../../assets/images/AAO Logo.svg";
import apiService from "../../services/api.service";
import Spinner from "react-bootstrap/Spinner";
import { toast } from 'react-toastify';
import Button from "react-bootstrap/Button";

const Academypage = () => {
  const location = useLocation();
  const [loader,setLoader] = useState(false);

  function redirect() {
    setLoader(true)
    apiService.aaoVerifyCreate().then(({ data, ...rest }:any)=>{
      const verifyResponse = data;
      window.open(verifyResponse?.redirectionURL, '_blank');
      setLoader(false)
      }).catch(()=>{
        toast.error("Something Went Wrong")
      })
    }

  return (
    <>
     <Navbar collapseOnSelect expand="sm" className="aao-colored-navbar">
      <Container fluid="xxl" className="header-pending">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="menu-main">
          <Nav>
            <Link className="nav-link aao-active" to='/' >
              <div className={`${location.pathname === '/' ? 'active' : ''} aao-nav-text`}>The Academy</div>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <div style={{textAlign:'center',margin:50}}>
        <h2 className='mb-5'>The Academy Page</h2>
      
        <Button type="submit" className="team-start-btn" onClick={()=>{redirect()}}  disabled={loader}>
          {loader && <Spinner className="loader" animation="border" /> }
          Alchemy Vision
        </Button>
    </div>
    <div className="footer-aao"  style={{textAlign:'center',bottom: 0,
    left: 0,
    right: 0,
    position: 'fixed'}}> 
      <div className="footer-aao-middle">
      <Container fluid="xxl">
        <Row>
          <Col md={12} className="header-pending aao-logo-footer">
            <img src={AAOlogo} width='255px' alt="footer-logo" />
          </Col>
        </Row>
        <br />
      </Container>
      </div>
    </div>
    </>
  )
}

export default Academypage