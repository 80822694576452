/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useImperativeHandle,useState, useRef, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Myteam.scss";
import Pagination from "../mypractice/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getLearners,getFreshLearners } from "../../store/learner/effect";
import { getLicenceDetails } from "../../store/subscription/effect";
import { GetLearnerState } from "../../types/Learners.type";
import addicon from "../../assets/images/add-icon.svg";
import Modal from "react-bootstrap/Modal";
import SimpleReactValidator from "simple-react-validator";
import defaultUser from "../../assets/images/defaultuser.png";
import {
  applyLicenceAllocation,
  cancelLicence,
  updateLicence,
  changeLicence,
} from "../../store/licenceallocation/effect";
import {
  getLeaders,
  insertLeader,
  removeLeader,
} from "../../store/leaders/effect";
import {
  GetLicenceAllocationState,
  GetDeleteLicenceState,
  GetEditLicenceState,
  GetChangeLicenceState,
} from "../../types/licenceallocation.type";
import {
  GetLeaderState,
  AddLeaderState,
  DeleteLeaderState,
} from "../../types/leaders.type";
import { toast } from "react-toastify";
import {
  RESET_LICENCEALLOCATION,
  RESET_EDIT_LICENCE,
  RESET_LICENCE_DELETE,
  RESET_CHANGE_LICENCE,
} from "../../store/licenceallocation/actions";
import {
  RESET_ADD_LEADER,
  RESET_REMOVE_LEADER,
} from "../../store/leaders/actions";
import right from "../../assets/images/right2.svg";
import cross from "../../assets/images/cross2.svg";
import Spinner from "react-bootstrap/Spinner";
import Minusicon from "../../../src/assets/images/minusicon.svg";
import Leader from "../../../src/assets/images/Leader.svg";
import "react-tooltip/dist/react-tooltip.css";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Tooltip } from "react-tooltip";
import Grow from "../loader/Grow";
import {
  GetPracticePlanState,
  GetEditPracticePlanState,
} from "../../types/Plan.type";
import {
  changeLicenceCountEffect,
} from "../../store/plans/effect";
import Rightcheck from "../../assets/images/rightcheck.svg";
import {
  RESET_EDIT_LICENSE,
} from "../../store/plans/actions";
import Chooseplan from "./Chooseplan";
import ApiService from "../../services/api.service";
import Card from "react-bootstrap/Card";
import Select, { components } from "react-select";
import DashboardNav from "./../widgets/DashboardNav";
import Resendicon from "../../assets/images/ResendIcon.svg";
import Swapicon from "../../assets/images/Swap-Icon.svg";
import Dangericon from "../../assets/images/Remove-Icon.svg";
import LeaderSelect from "../../assets/images/Leader-select.svg"
import LearnerSelect from "../../assets/images/Learner-select.svg"
import { useNavigate } from "react-router-dom";
import Excicon from "../../assets/images/exc.svg";
import Searchicon from "../../assets/images/Search-Icon.png";
import Clearicon from "../../assets/images/Clear-Icon.svg";
import ContactEmail from "../../assets/images/contact-email.svg";
import ContactPhone from "../../assets/images/contact-phone.svg";

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyNiAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjMwOTIgMC42NTk4MzRDMTIuNjk1NyAwLjI5MDYzNSAxMy4zMDQyIDAuMjkwNjM0IDEzLjY5MDcgMC42NTk4MzRMMjIuMTg4IDguNzc2OUMyMi44NDAzIDkuMzk5OTQgMjIuMzk5MyAxMC41IDIxLjQ5NzMgMTAuNUg0LjUwMjY5QzMuNjAwNzEgMTAuNSAzLjE1OTcyIDkuMzk5OTQgMy44MTE5NCA4Ljc3NjlMMTIuMzA5MiAwLjY1OTgzNFoiIGZpbGw9IiM2Mjg5QjgiLz4KPHBhdGggZD0iTTEzLjY5MDggMjguMzQwMkMxMy4zMDQzIDI4LjcwOTQgMTIuNjk1OCAyOC43MDk0IDEyLjMwOTMgMjguMzQwMkwzLjgxMTk3IDIwLjIyMzFDMy4xNTk3NSAxOS42MDAxIDMuNjAwNzQgMTguNSA0LjUwMjcyIDE4LjVMMjEuNDk3MyAxOC41QzIyLjM5OTMgMTguNSAyMi44NDAzIDE5LjYwMDEgMjIuMTg4MSAyMC4yMjMxTDEzLjY5MDggMjguMzQwMloiIGZpbGw9IiM2Mjg5QjgiLz4KPC9zdmc+Cg=="
        alt="icon"
      />
    </components.DropdownIndicator>
  );
};

  const Manageteam = forwardRef((props, ref) => {

  const getLicenceDetailsState = useSelector(
    (state: any) => state.subscriptionState.licenceDetails
  );
  const [selectedOption, setSelectedOption]: any = useState("");
  const [deleteCounter, setDeleteCounter]: any = useState(0);
  const [disableDelete, setDisableDelete]: any = useState(false);
  const [currentPrice, setCurrentPrice]: any = useState(0);
  const [currentImage, setCurrentImage]: any = useState(0);
  const [newCurrentPrice, setNewCurrentPrice]: any = useState(0);
  const [options, setOptions]: any = useState(0);
  const [current, setCurrent]: any = useState("");
  const [needContent, setNeedContent]: any = useState(false);
  const [newRate, setNewRate] = useState(0);
  const [dueRate, setDueRate] = useState(0);
  const [removeProfile, setRemoveProfile] = useState(false);
  const navigate = useNavigate()
  function handleTypeSelect(e: any) {
    setSelectedOption(e.value);
    if (e.value === "100+") {
      setNeedContent(true);
    }else{
      setNeedContent(false);
    }
    setDisableDelete(false);
    setCheckBoxAgree(false);
    setDeleteArray([]);
    setDeleteManageLicense(false);
  }

  useImperativeHandle(ref, () => ({
    showManageLicense(){
      dispatch(getLicenceDetails());
      setManageLicense(true);
    }
  }));


  function getSelectedBrackDown(selected:any) {
    let breakd = options?.filter((breakdown:any)=>{
      return breakdown?.value === selected
    })
    return breakd[0];
  }

  useEffect(() => {
    if(getLicenceDetailsState?.data && selectedOption){
    setDeleteCounter((current - selectedOption)-getLicenceDetailsState?.data?.subscription?.unusedLicenses);
  
    if(current!==selectedOption){
      let diff = Math.abs(current - Number(selectedOption))
      let selectedBreackDown = getSelectedBrackDown(selectedOption)
      setNewCurrentPrice(selectedBreackDown?.singlePrice)
      setCurrentImage(selectedBreackDown?.icon)
      if(current>Number(selectedOption)){
        setNewRate(selectedBreackDown?.listTotal)
        setDueRate(0)
      }else{
        setNewRate(selectedBreackDown?.listTotal)
        setDueRate(diff*selectedBreackDown?.proratedPricePerLicense)
      }
    }else{
      let selectedBreackDown = getSelectedBrackDown(selectedOption)
      setCurrentImage(selectedBreackDown?.icon)
      setNewRate(0)
      setDueRate(0)
      setNewCurrentPrice(0)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);


  const colourStyles = {
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isSelected ? "#CEDBEB" : "#F2F6FC",
        color: "#000",
        textAlign: "center",
      };
    },
    control: (provided: any) => ({
      ...provided,
      margin: 0,
      marginLeft: 0,
      border: 0,
      fontSize: 20,
      backgroundColor: "#CEDBEB",
      outline: "none",
      textAlign: "center",
      fontWeight: "bold",
    }),
  };

  let profile = JSON.parse(localStorage.getItem("profile") || "");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [learnerArray,setLearnerArray]:any = useState([])
  const [learnerArrayTmp,setLearnerArrayTmp]:any = useState([])
  const learnerState = useSelector(
    (state: GetLearnerState) => state.learnerState.learnerlist
  );
  const freshLearnerState = useSelector(
    (state: any) => state.learnerState.freshLearners
  );
  const deleteLeaderState = useSelector(
    (state: DeleteLeaderState) => state.leaderState.removeleader
  );
  const changeLicenceState = useSelector(
    (state: GetChangeLicenceState) => state.LicenceAllocationState.changelicence
  );
  const [learnerData,setLearnerData] = useState([]);
  useEffect(()=>{
    setLearnerArray(freshLearnerState?.data?.filter((item:any)=>{return !item?.isCurrentUser}))
    setLearnerArrayTmp(freshLearnerState?.data?.filter((item:any)=>{return !item?.isCurrentUser}))
  },[freshLearnerState])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changeTireSuccess, setChangeTireSuccess] = useState(false);
  const [deleteArray, setDeleteArray]:any = useState([]);
  const [checkBoxAgree, setCheckBoxAgree] = useState(false);

  React.useEffect(() => {
    if (changeLicenceState?.data?.success === true) {
      let id = changeLicenceState?.data?.license?.id;
      dispatch({ type: RESET_CHANGE_LICENCE });
      dispatch(getLearners());
      handleLicenceTireClose();
      setChangeTireSuccess(true);
      setDeleteSuccess(false);
      let planName;
      planName = planData?.map((plan: any, index: number) => {
        if (plan.id === changeTire.planId) {
          return plan.name;
        } else {
          return "";
        }
      });

      setLearnerDetails({
        ...learnerDetail,
        licensetier: planName,
        planId: changeTire.planId,
        licenseAllocationId: id,
      });
    }
    if (changeLicenceState?.error) {
      toast.error(changeLicenceState?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({ type: RESET_CHANGE_LICENCE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLicenceState]);

  useEffect(()=>{
    let tempLearner:any = [];
    if(disableDelete===true){
      tempLearner = learnerArray?.map((learner:any)=>{
        if(deleteArray.includes(learner.licenseAllocationId)){
          learner.disable=false;
        }else{
          learner.disable=true;
        }
        return learner;
      })
    }else{
      tempLearner = learnerArray?.map((learner:any)=>{
          learner.disable=false;
          return learner;
      })
    }
    setLearnerArray(tempLearner)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[disableDelete])

  const deactive = (e: any) => {
    let tempLearner = [];
    let tempLearner2 = [];
    if (e.target.checked) {
      tempLearner = learnerArray?.map((learner:any)=>{
        if(e.target.value==learner.licenseAllocationId){
          learner.checked=true;
        }
        return learner;
      })
      tempLearner2 = learnerArrayTmp?.map((learner:any)=>{
        if(e.target.value==learner.licenseAllocationId){
          learner.checked=true;
        }
        return learner;
      })
      searchDeletes('')
      setDeleteArray((prevArray:any) => [...prevArray, Number(e.target.value)]);
      setDeleteCounter(deleteCounter - 1);
      if (deleteCounter === 1) {
        setDisableDelete(true)
      }
    } else {
      tempLearner = learnerArray?.map((learner:any)=>{
        if(e.target.value===learner.licenseAllocationId){
          learner.checked=false;
        }
        return learner;
      })
      searchDeletes('')
      tempLearner2 = learnerArrayTmp?.map((learner:any)=>{
        if(e.target.value==learner.licenseAllocationId){
          learner.checked=false;
        }
        return learner;
      })
      setDeleteArray((prevArray:any) =>
        prevArray.filter((val:any) => val !== Number(e.target.value))
      );
      setDeleteCounter(deleteCounter + 1);
      setDisableDelete(false)
    }
    setLearnerArrayTmp(tempLearner2)
    if(search===''){
      setLearnerArray(tempLearner)
    }
  };


  const agree = (e: any) => {
    if (e.target.checked) {
      setCheckBoxAgree(true);
    } else {
      setCheckBoxAgree(false);
    }
  };


  useEffect(() => {
    if(getLicenceDetailsState?.data?.licenseBreakdownOptions){
    let currentBreackDown = getLicenceDetailsState?.data?.licenseBreakdownOptions?.filter((breakdown:any)=>{
      return breakdown?.licenseQuantity === getLicenceDetailsState?.data?.subscription?.totalLicenses
    })
    let optionArray = [];
    optionArray = getLicenceDetailsState?.data?.licenseBreakdownOptions?.map((breakDown:any)=>{
                let tempbrekDown          
                tempbrekDown = {
                  label:breakDown?.licenseQuantity.toString(),
                  value:breakDown?.licenseQuantity.toString(),
                  listTotal:breakDown?.listTotal,
                  singlePrice:breakDown?.pricePerLicense,
                  proratedPricePerLicense:breakDown?.proratedPricePerLicense,
                  icon:breakDown?.icon
                };
                return tempbrekDown;
    });
    optionArray?.push({
      label:"100+",
      value:'100+',
      listTotal:0,
      singlePrice:0,
      proratedPricePerLicense:0,
      icon:'https://alchemydev001.blob.core.windows.net/signup-portal/large_plan_icon.svg'
    })
    setOptions(optionArray)
    setCurrentPrice(currentBreackDown[0]?.pricePerLicense)
    setCurrentImage(currentBreackDown[0]?.icon)
    setSelectedOption(
      getLicenceDetailsState?.data?.subscription?.totalLicenses.toString()
    );
    setCurrent(
      getLicenceDetailsState?.data?.subscription?.totalLicenses.toString()
    );
  }
  }, [getLicenceDetailsState]);

  const [addLearner, setAddLearner] = useState(false);
  const [manageLicense, setManageLicense] = useState(false);
  const [deleteManageLicense, setDeleteManageLicense] = useState(false);
  const [learnerInfo, setLearnerInfo] = useState(false);
  const [assignNewLearner, setAssignNewLearner] = useState(false);
  const [deleteLicence, setDeleteLicence] = useState(false);
  const [manageLeader, setManageLeader] = useState(false);
  const [addLeader, setAddLeader] = useState(false);
  const [adminSuccess, setAdminSuccess] = useState(false);
  const [changeLicenceTire, setChangeLicenceTire] = useState(false);
  const [licenceUpdateSuccess, setLicenceUpdateSuccess] = useState(false);
  const [planData, setPlanData] = useState<any>();
  const [profileData, setProfileData] = useState<any>();
  const [leaderLearner, setLeaderLearner] = useState(false);
  const [currentTableData,setCurrentTableData] = useState([])
  const [sortdo,setSortdo] = useState(0)
  const [leaderLearnerOption, setLeaderLearnerOption] = useState(false);
  const [type, setType] = useState('');

  const openAdjustLicense = () => {
   dispatch(getFreshLearners());
   setDeleteManageLicense(true);
  };

  useEffect(() => {
    if (localStorage.getItem("profile")) {
      const profile = JSON.parse(localStorage.getItem("profile") as string);
      setProfileData(profile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("profile")]);

  const planState = useSelector(
    (state: GetPracticePlanState) => state.planState.practicePlans
  );

  React.useEffect(() => {
    setPlanData(planState?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planState?.data]);

  const [learnerDetail, setLearnerDetails] = useState({
    id: 0,
    name: "",
    surname: "",
    planId: "",
    email: "",
    licensetier: "",
    jobtitle: "",
    currentPeriodEnd: "",
    licenseAllocationId: 0,
    cancelAt: "",
    profileId: 0,
    legacyPlan: false,
    allocationStatus:'',
    licenseType:''
  });
  const [planName, setplanName] = useState("");
  const [licenceData, setLicenceData]: any = useState({
    email: "",
    planId: "",
    subscriptionId: profile?.subscriptionId,
    surname: "",
    name: "",
    jobTitle: "",
    experienceLevel: null,
    licenseAllocationId:0
  });
  const [leaderData, setLeaderData]: any = useState({
    name: "",
    surname: "",
    email: "",
    subscriptionId: profile?.subscriptionId,
    jobTitle: "",
  });
  const [editLicenceData, setEditLicenceData]: any = useState({
    email: "",
    planId: 0,
    name: "",
    jobtitle: "",
    surname: "",
    experienceLevel: null,
    licenseAllocationId: 0,
    subscriptionId: profile?.subscriptionId,
  });

  const [changeTire, setChangeTire]: any = useState({
    planId: "",
    profileId: "",
    subscriptionId: profile?.subscriptionId,
    medicalPracticeId: profile?.medicalPracticeId,
    licenseAllocationId: learnerDetail?.licenseAllocationId,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue]:any = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  const [nameSort,setNameSort] = useState(false);
  const [typeSort,setTypeSort] = useState(false);
  const [loginSort,setLoginSort] = useState(false);
  const licenceAllocation = useSelector(
    (state: GetLicenceAllocationState) =>
      state.LicenceAllocationState.licenceAllocation
  );
  // const experience = useSelector(
  //   (state: GetExperienceState) => state.LicenceAllocationState.experience
  // );
  const cancelLicenceState = useSelector(
    (state: GetDeleteLicenceState) => state.LicenceAllocationState.deleteLicence
  );
  const leadersState = useSelector(
    (state: GetLeaderState) => state.leaderState.leaderlist
  );
  const addLeadersState = useSelector(
    (state: AddLeaderState) => state.leaderState.addleader
  );
  const editLicenceState = useSelector(
    (state: GetEditLicenceState) => state.LicenceAllocationState.editLicence
  );

  const updatePlanState = useSelector(
    (state: GetEditPracticePlanState) => state.planState.editPlan
  );

  const deleteLeader = (id: number) => {
    dispatch(removeLeader(id));
  };

  const deleteSelectedLicenses = () => {
    setDeleteManageLicense(false);
    setCheckBoxAgree(false);
  };

  const hideDeleteSelectLicenses = () => {
    setDisableDelete(false);
    setCheckBoxAgree(false);
    setDeleteArray([]);
    setSearchTech('')
    setDeleteCounter((current - selectedOption)-getLicenceDetailsState?.data?.subscription?.unusedLicenses);
    setDeleteManageLicense(false);
  };
  const [contactModal, setContactModal] = useState(false);



  React.useEffect(() => {
    if (updatePlanState?.data?.success === true) {
      setCurrent(updatePlanState?.data?.data?.newLicenseDetails?.licenseQuantity)
      setSelectedOption(updatePlanState?.data?.data?.newLicenseDetails?.licenseQuantity)
      // toast.success(updatePlanState?.data?.message, {
      //   position: "top-right",
      // });
      setManageLicense(false);
      dispatch({ type: RESET_EDIT_LICENSE });
      dispatch(getLearners());
      setLicenceAllocationSuccess(false);
      setAdminSuccess(false);
      setLicenceUpdateSuccess(true);
    }
    if (updatePlanState?.error) {
      toast.error(updatePlanState?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({ type: RESET_EDIT_LICENSE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePlanState]);

  const [deleteSuccess, setDeleteSuccess] = useState(false);

  React.useEffect(() => {
    if (deleteLeaderState?.data?.success === true) {
      setDeleteSuccess(true);
      setLicenceAllocationSuccess(false)
      setLicenceUpdateSuccess(false)
      setAdminSuccess(false);
      setRemoveProfile(false);
      setLearnerInfo(false)
      dispatch(getLearners());
      dispatch({ type: RESET_REMOVE_LEADER });
    }
    if (deleteLeaderState?.error) {
      toast.error(deleteLeaderState?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({ type: RESET_REMOVE_LEADER });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLeaderState]);

  const [assignEdit, setAssignEdit] = useState(false);
  const [licenceAllocationSuccess, setLicenceAllocationSuccess] = useState(false);
  const [search, setSearchTech] = useState('');
  const [resendEmailLoader, setResendEmailLoader] = useState(false);
  const [resendEmailMsg, setResendEmailMsg] = useState(false);
  
  const searchDeletes = (e:any) =>{
    let tmpData = [];
    setSearchTech(e)
    tmpData = learnerArrayTmp?.filter((item:any)=>{
      return item.name.toLowerCase().includes(e.toLowerCase());
    })
    tmpData.sort(function(a:any,b:any) {
      return a['checked'] > b['checked'] ? -1 : a['name'] > b['name'] ? 1 : 0;
    });
    setLearnerArray(tmpData)
  }

  function useForceUpdate() {
    return () => setValue( value + 1);
  }

  const forceUpdate = useForceUpdate();

  const resendEmail = (id: any, roleId: any) => {
    setResendEmailLoader(true)
        let data = {
          licenseAllocationId: id,
          roleId: roleId,
        };
       ApiService.sendEmail(data)
          .then((response) => {
            setResendEmailMsg(true)
            setAssignEdit(false)
            setResendEmailLoader(false)
          })
          .catch((error) => {
            setResendEmailLoader(false)
          });
      }


  React.useEffect(() => {
    if (editLicenceState?.data?.success === true) {
      dispatch({ type: RESET_EDIT_LICENCE });
      dispatch(getLearners());
      handleAssignNewLearnerClose();
      setAssignEdit(true);
      setResendEmailMsg(false);
      setLicenceAllocationSuccess(false);
      setChangeTireSuccess(false);
      setLearnerDetails({
        ...learnerDetail,
        name: editLicenceData.name,
        email: editLicenceData.email,
        surname: editLicenceData.surname,
      });
      setChangeTire({
        ...changeTire,
        profileId: "",
        planId: "",
      });
      setEditLicenceData({
        ...editLicenceData,
        name: "",
        surname: "",
        email: "",
        jobTitle: null,
        experienceLevel: null,
      });
    }
    if (editLicenceState?.error) {
      toast.error(editLicenceState?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({ type: RESET_EDIT_LICENCE });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editLicenceState]);

  React.useEffect(() => {
    if (addLeadersState?.data?.success === true) {
      setAdminSuccess(true);
      setDeleteSuccess(false);
      setLeaderLearnerOption(false)
      setLeaderData({
        ...leaderData,
        name: "",
        surname: "",
        email: "",
        jobTitle: null,
      });
      addLeaderClose();
      dispatch({ type: RESET_ADD_LEADER });
      dispatch(getLeaders());
      setAddLeader(false);
      const section = document.getElementById("sent-invite");
      section?.scrollIntoView({ behavior: "smooth" });

    }
    if (addLeadersState?.error) {
      toast.error(addLeadersState?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({ type: RESET_ADD_LEADER });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addLeadersState]);

  React.useEffect(() => {
    if (cancelLicenceState?.data) {
      if (cancelLicenceState?.data?.success === true) {
        setAssignEdit(false);
        setChangeTireSuccess(false);
        // dispatch(getLearners());
      }
      dispatch({ type: RESET_LICENCE_DELETE });
      deleteLicenceClose();
      if (!learnerDetail.cancelAt) {
        setLearnerDetails({
          ...learnerDetail,
          cancelAt: learnerDetail?.currentPeriodEnd,
        });
      } else {
        setLearnerDetails({ ...learnerDetail, cancelAt: "" });
      }
    }
    if (cancelLicenceState?.error) {
      toast.error(cancelLicenceState?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({ type: RESET_LICENCE_DELETE });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelLicenceState]);

  React.useEffect(() => {
    if (licenceAllocation?.data?.success === true) {
      setLicenceUpdateSuccess(false);
      setAdminSuccess(false);
      setLeaderLearnerOption(false)
      setLicenceAllocationSuccess(true);
      setLearnerDetails({
        ...learnerDetail,
        name: "",
        surname: "",
        licenseAllocationId: 0,
        email: "",
        licensetier: "",
        id: 0,
        currentPeriodEnd: "",
        cancelAt: "",
        planId: "",
        legacyPlan: false,
        allocationStatus:'',
        jobtitle:'',
      });

      reset("", "");
      dispatch({ type: RESET_LICENCEALLOCATION });
      dispatch(getLearners());
      setAddLearner(false);
      handleLearnerClose();
    }
    if (licenceAllocation?.error) { 
      toast.error(licenceAllocation?.error?.data?.message, {
        position: "top-right",
      });
      dispatch({ type: RESET_LICENCEALLOCATION });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenceAllocation]);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        regex: "Please Enter Valid Value",
      },
      validators: {
        emailregex: {
          message: "Please Enter Valid Email",
          rule: (val: any, params: any): any => {
            const regex = new RegExp("^[a-z0-9+]+@[a-z]+.[a-z]{2,3}$");
            return regex.test(val);
          },
        },
      },
    })
  );

  const leaderValidator = useRef(
    new SimpleReactValidator({
      validators: {
        emailregex: {
          message: "Please Enter Valid Email",
          rule: (val: any, params: any): any => {
            const regex = new RegExp("^[a-z0-9+]+@[a-z]+.[a-z]{2,3}$");
            return regex.test(val);
          },
        },
      },
    })
  );

  const assignValidator = useRef(
    new SimpleReactValidator({
      validators: {
        emailregex: {
          message: "Please Enter Valid Email",
          rule: (val: any, params: any): any => {
            const regex = new RegExp("^[a-z0-9+]+@[a-z]+.[a-z]{2,3}$");
            return regex.test(val);
          },
        },
      },
    })
  );

  const removeLicence = (id: number, from: string) => {
    dispatch(cancelLicence(id));
  };

  //addlearner
  const handleLearnerClose = () => {
    // setType("")
    setLicenceData({
      ...licenceData,
      email: "",
      planId: "",
      surname: "",
      name: "",
      jobTitle: "",
      experienceLevel: null,
      licenseAllocationId:0
    });
    simpleValidator.current.hideMessages();
    simpleValidator.current.hideMessageFor("name");
    simpleValidator.current.hideMessageFor("surname");
    simpleValidator.current.hideMessageFor("email");
    simpleValidator.current.hideMessageFor("experienceLevel");
    simpleValidator.current.hideMessageFor("jobTitle");
    // forceUpdate();
    setAddLearner(false);
    setChangeLicenceTire(false);
  };
  const leaderLearnerclose = () => {
    setLeaderLearnerOption(false)
    // setType("")
    setLicenceData({
      ...licenceData,
      email: "",
      planId: "",
      surname: "",
      name: "",
      jobTitle: "",
      experienceLevel: null,
      licenseAllocationId:0
    });
    simpleValidator.current.hideMessages();
    simpleValidator.current.hideMessageFor("name");
    simpleValidator.current.hideMessageFor("surname");
    simpleValidator.current.hideMessageFor("email");
    simpleValidator.current.hideMessageFor("experienceLevel");
    simpleValidator.current.hideMessageFor("jobTitle");
    // forceUpdate();
    setAddLearner(false);
    setChangeLicenceTire(false);
  };

  


  const handleLicenceTireClose = () => {
    reset("", "");
    setChangeLicenceTire(false);
  };

  const handleLearnerInfoClose = () => {
    setLearnerInfo(false);
    setAssignEdit(false);
    setChangeTireSuccess(false);
  };

  const hideManageLicense = () => {
    setManageLicense(false);
    setDeleteManageLicense(false);
    setSelectedOption(
      getLicenceDetailsState?.data?.subscription?.totalLicenses.toString()
    );
    setNeedContent(false)
    setDeleteArray([]);
    setDeleteCounter(0);
    setDisableDelete(false);
  };

  const addLeaderOpen = () => {
    setAddLeader(true);
  };

  const addLeaderClose = () => {
    leaderValidator.current.hideMessages();
    leaderValidator.current.hideMessageFor("name");
    leaderValidator.current.hideMessageFor("surname");
    leaderValidator.current.hideMessageFor("email");
    leaderValidator.current.hideMessageFor("jobTitle");
    setLeaderData({
      ...leaderData,
      name: "",
      surname: "",
      email: "",
      jobTitle: null,
    });
    forceUpdate();

    setAddLeader(false);
  };

  const handleAssignNewLearnerOpen = (planId: any) => {
    setAssignNewLearner(true);
  };

  const manageLeaderClose = () => {
    setManageLeader(false);
    setDeleteSuccess(false);
    setAdminSuccess(false);
  };



  const handleAssignNewLearnerClose = () => {
    assignValidator.current.hideMessages();
    assignValidator.current.hideMessageFor("name");
    assignValidator.current.hideMessageFor("surname");
    assignValidator.current.hideMessageFor("email");
    assignValidator.current.hideMessageFor("experienceLevel");
    setEditLicenceData({
      ...editLicenceData,
      name: "",
      email: "",
      surname: "",
    });
    forceUpdate();
    setAssignNewLearner(false);
  };


  const deleteLicenceClose = () => {
    setDeleteLicence(false);
  };

  const handleLearnerLeaderOption = (item:any) =>{
    setType('')
    setplanName(item?.plan?.name);
    setLeaderLearnerOption(true)
    setLicenceData({
      ...licenceData,
      ...{ planId: item?.plan?.id, id: item.id,licenseAllocationId:item?.licenseAllocationId },
    });
  }

  const handleLearnerShow = () => {
    setAddLearner(true);
  };

  const assignSeatNewLearner = (event: any) => {
    setValidated(true);
    event.preventDefault();
    const formValid = assignValidator.current.allValid();
    if (!formValid) {
      assignValidator.current.showMessages();
      forceUpdate();
    } else {
      dispatch(updateLicence(editLicenceData,learnerDetail?.licenseType));
    }
  };

  

  const handleLearnerInfo = (
    email: string,
    name: string,
    surname: string,
    licensetier: string,
    id: number,
    cancelAt: string,
    planId: string,
    profileId: number,
    legacyPlan: any,
    allocationStatus:any,
    jobTitle:any,
    licenseType:any
  ) => {
    setLearnerInfo(true);
    setLearnerDetails({
      ...learnerDetail,
      name: name,
      surname: surname,
      email: email,
      licensetier: licensetier,
      licenseAllocationId: id,
      id: id,
      cancelAt: cancelAt,
      planId: planId,
      profileId: profileId,
      legacyPlan: legacyPlan,
      allocationStatus:allocationStatus,
      jobtitle:jobTitle,
      licenseType:licenseType
    });
  };

  React.useEffect(() => {
    if (learnerDetail) {
      setEditLicenceData({
        ...editLicenceData,
        licenseAllocationId: learnerDetail.id,
        planId: learnerDetail.planId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnerDetail]);

  React.useEffect(()=>{
    setLearnerData(learnerState?.data?.sort(
        (learner:any)=>{
          if(learner?.isCurrentUser===true) 
            return -1 
          else 
           return 1
        }))
  },[learnerState?.data])

  let PageSize = 10;
  React.useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTableData(learnerData?.slice(firstPageIndex, lastPageIndex));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, learnerData,sortdo]);

  const SortOrder = (type:any) =>{
    let tempData:any;
    if(type==='name'){
      if(nameSort===false){
        setNameSort(true)
      }else{
        setNameSort(false)
      }
      tempData = learnerData?.sort(dynamicSort(type,!nameSort));
    }
    if(type==='licenseType'){
      if(typeSort===false){
        setTypeSort(true)
      }else{
        setTypeSort(false)
      }
      tempData = learnerData?.sort(dynamicSort(type,!typeSort));
    }
    if(type==='allocationStatusText'){
      if(loginSort===false){
        setLoginSort(true)
      }else{
        setLoginSort(false)
      }
      tempData = learnerData?.sort(dynamicSort(type,!loginSort));
    }
   setLearnerData(tempData);
   setSortdo(sortdo+1)
  }

  function dynamicSort(property:any,reverse:any):any {
      var sortOrder = 1;
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
      return function (a:any,b:any) {
          /* next line works with strings and numbers, 
          * and you may want to customize it to your needs
          */
          var result:any;
        if(property==='name' || property==='licenseType'){ 
        if((a['name'])!=='z'){
          if(reverse===false){
            result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
           } else{
            result = (a[property].toLowerCase() > b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() < b[property].toLowerCase()) ? 1 : 0;
           }
           return result * sortOrder;
          }
        }else{
          if(reverse===false){
            result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
           } else{
            result = (a[property].toLowerCase() > b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() < b[property].toLowerCase()) ? 1 : 0;
           }
           return result * sortOrder;
        }
      }
  }
  

  React.useEffect(() => {
    simpleValidator.current.hideMessages();
    leaderValidator.current.hideMessages();
    assignValidator.current.hideMessages();
  }, []);

  React.useEffect(() => {
    dispatch(getLearners());
  }, [dispatch]);

  const updateValue = (event: any) => {
    const { name, value } = event.target;
    // let value2 = value.replace("+", "");
    // if (value === "+") {
    //   return false;
    // }
    simpleValidator.current.showMessageFor(name);
    setLicenceData({ ...licenceData, [name]: value });
  };

  const updateValueEdit = (event: any) => {
    const { name, value } = event.target;
    // let value2 = value.replace("+", "");
    // if (value === "+") {
    //   return false;
    // }
    assignValidator.current.showMessageFor(name);
    if (name === "experienceLevel") {
      setEditLicenceData({ ...editLicenceData, [name]: parseInt(value) });
    } else {
      setEditLicenceData({ ...editLicenceData, [name]: value });
    }
  };
  const updateLeaderData = (event: any) => {
    const { name, value } = event.target;
    let value2 = value.replace("+", "");
    if (value === "+") {
      return false;
    }
    if (value !== "") {
      leaderValidator.current.showMessageFor(name);
    }
    setLeaderData({ ...leaderData, [name]: value2 });
  };

  const addNewLeader = (event: any) => {
    setValidated(true);
    event.preventDefault();
    const formValid = leaderValidator.current.allValid();
    if (!formValid) {
      leaderValidator.current.showMessages();
      forceUpdate();
    } else {
      dispatch(insertLeader(leaderData));
    }
  };

  React.useEffect(() => {}, [changeTire]);

  const reset = (plan: any, learnerId: any) => {
    const data = planData?.map((resp: any) => {
      resp.activeSelect = resp.id === plan.id;
      return resp;
    });

    setChangeTire({
      ...changeTire,
      profileId: learnerId,
      planId: plan.id,
    });

    setPlanData(data);
  };

  const updateTire = () => {
    const request = {
      ...changeTire,
      licenseAllocationId: learnerDetail.licenseAllocationId,
    };
    dispatch(changeLicence(request));
  };

  const assignLicense = (event: any,leader:any) => {
    setValidated(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate();
    } else {
      dispatch(
        applyLicenceAllocation(
          { ...licenceData },
          leader
        )
      );
    }
  };

  const formatDate = (date: any) => {
    var d = new Date(date),
      day = "" + d.getDate(),
      year = d.getFullYear();
    let month = d.toLocaleString("default", { month: "long" });
    if (day.length < 2) day = "0" + day;

    return day + ` ` + month + `, ` + year;
  };

  const confirmNewPlan = () =>{
    const newLicenseCount = {
      totalNewLicenses:Number(selectedOption), 
      licensesToDeactivate:deleteArray
    }
    dispatch(changeLicenceCountEffect(newLicenseCount))
  }

  const select = (type:any) =>{
    setType(type)
  }

  const removeProfilefunc = () =>{
    setType(learnerDetail.licenseType)
    setRemoveProfile(true)
  }


  return (
    <>

<Modal show={contactModal} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Contact Our Support Team</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
              <Row className="gx-3 justify-content-center">
                <Col md={6} className="justify-content-center" style={{textAlign:'center'}}>
                  <div className="contact-us mt-2 mb-2">
                    <img src={ContactPhone} alt="" /><br/><br/>
                    <span className="contact-text">Contact us by phone</span><br/><br/>
                    <a href="tel:+1 213 510 8050" className="contact-info">+1 213 510 8050</a><br/><br/>
                  </div>
                </Col>
                <Col md={6} className="justify-content-center" style={{textAlign:'center'}}>
                  <div className="contact-us mt-2 mb-2">
                    <img src={ContactEmail} alt="" /><br/><br/>
                    <span className="contact-text">Contact us by email</span><br/><br/>
                    <a href="mailto:hello@alchemyvision.com" className="contact-info">hello@alchemyvision.com</a><br/><br/>
                  </div>
                </Col>
              </Row>
              <Row className="gx-5 justify-content-center"></Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={12} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                setContactModal(false)
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Modal> 
      {/* <Pdf/> */}
      <Modal show={changeLicenceTire} onHide={handleLicenceTireClose} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Change License Tier</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row className="mt-4 gx-5">
            <br />
            <span className="new-learner">
              CHOOSE A LICENSE TIER FOR {learnerDetail.name}...
            </span>
            <Row>
              {planState.loading ? (
                <Grow />
              ) : (
                planData?.map((plan: any, index: number) => {
                  return (
                    <Chooseplan
                      key={index}
                      plan={plan}
                      reset={reset}
                      active={plan.activeSelect}
                      currentLicence={plan.id === learnerDetail.planId}
                      learnerId={learnerDetail.profileId}
                    />
                  );
                })
              )}
            </Row>
          </Row>

          <Row className="mt-5">
            <b className="add-learner-extra">
              Your subscription price will adjusted and pro-rated for your next
              billing cycle.
            </b>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleLicenceTireClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            {changeTire.planId !== "" &&
            changeTire.planId !== learnerDetail.planId ? (
              <button
                disabled={changeLicenceState?.loading}
                className="assign-btn"
                onClick={() => updateTire()}
              >
                {changeLicenceState?.loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Update License
              </button>
            ) : (
              <button disabled={true} className="assign-btn">
                Update License
              </button>
            )}
          </Col>
        </Row>
      </Modal>
      <Modal show={leaderLearnerOption} onHide={handleLearnerClose} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Invite a New User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row className="mt-4 gx-5">
                <Col md={6} style={{textAlign:'center',borderRadius:10,cursor:'pointer'}} onClick={()=>select('learner')}>
                    <div style={{background:type==='learner'?'#DBE5F4':'#EAF0F8',padding:'15px'}}>
                       <img src={LeaderSelect} alt='Leader' /><br/>
                       <span className="label-want">I want to invite a new</span><br/>
                       <button type='button' className="learner-type  mt-2 mb-2" style={{paddingTop:'5px',paddingLeft:'15px',paddingRight:'15px',paddingBottom:'5px',fontSize:20}}>Learner</button><br/>
                      <div style={{paddingRight:60,paddingLeft:60}}>
                        <span className="info">This user will be emailed to setup their Learner profile. They can begin training immediately.</span>
                      </div>
                    </div>
                </Col>
                <Col md={6} style={{textAlign:'center',borderRadius:10,cursor:'pointer'}} onClick={()=>select('leader')}>
                  <div style={{background:type==='leader'?'#DBE5F4':'#EAF0F8',padding:'15px'}}>
                    <img src={LearnerSelect} alt='Learner' /><br/>
                    <span  className="label-want">I want to invite a new</span><br/>
                    <button type='button' className="leader-type mt-2 mb-2" style={{paddingTop:'5px',paddingLeft:'15px',paddingRight:'15px',paddingBottom:'5px',fontSize:20}}>Leader</button><br/>
                    <div style={{paddingRight:60,paddingLeft:60}}>
                      <span className="info">This user will be emailed to setup their Leader profile. They’ll have the same administrative access as you.</span>
                    </div>
                  </div>
                </Col>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          {
            type==='' ?
          <Col md={12} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                leaderLearnerclose()
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          :
          <>
          <Col md={6} xs={12} className="footer-section-1 text-center">
          <button
            onClick={() => {
              leaderLearnerclose();
            }}
            className="cancel-btn"
          >
            Cancel
          </button>
        </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
                <button
                  className="assign-btn"
                  onClick={() => handleLearnerShow()}
                >
                  
                  Invite a new {type==='learner' ? 'Learner' : 'Leader'}
                </button>
            </Col>
          </>
              }
        </Row>
      </Modal>
      <Modal show={leaderLearner} size="xl" onHide={()=>{setLeaderLearner(false)}}>
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Make into a Leader license</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row className="mt-4 gx-5">
            <br />
            <span className="new-learner">
                Akura Sato is currently a <button className="learner-type">Learner</button>
            </span>
            <Row>
              {planState.loading ? (
                <Grow />
              ) : (
                planData?.map((plan: any, index: number) => {
                  return (
                    <Chooseplan
                      key={index}
                      plan={plan}
                      reset={reset}
                      active={plan.activeSelect}
                      currentLicence={plan.id === learnerDetail.planId}
                      learnerId={learnerDetail.profileId}
                    />
                  );
                })
              )}
            </Row>
          </Row>

          <Row className="mt-5">
            <Col md={9}>
              <b className="add-learner-extra">
              If you need more Leaders in your subscription, you can switch one or more of your Learner accounts and assign them to other leaders in your organization.<br/><br/>

              When switched to a Leader license, the user will be sent an email to set up their Leader profile. They’ll be able to view the &nbsp;&nbsp;<span className="new-learner"> progress reports</span> and <span className="new-learner">&nbsp;&nbsp;team analytics</span> of your organization, as well as the full&nbsp;&nbsp; <span className="new-learner">Learner experience.</span><br/><br/>

              You can always switch this license back to a Learner if you choose to do so.<br/><br/><br/>
              <span className="new-learner">Would you like to make Akura Sato a ? <button className="leader-type">Leader</button></span>
              </b>
            </Col>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                setLeaderLearner(false)
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            {changeTire.planId !== "" &&
            changeTire.planId !== learnerDetail.planId ? (
              <button
                disabled={changeLicenceState?.loading}
                className="assign-btn"
                onClick={() => updateTire()}
              >
                {changeLicenceState?.loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Update License
              </button>
            ) : (
              <button className="assign-btn">
                Make this Learner a Leader
              </button>
            )}
          </Col>
        </Row>
      </Modal>
      <Modal show={addLearner} onHide={handleLearnerClose} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>{type==='learner' ? 'Invite a New Learner' : 'Invite a New Leader'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <form
            noValidate
          >
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">Name</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  value={licenceData.name}
                  name="name"
                  placeholder="Enter First Name"
                  onChange={(e) => {
                    updateValue(e);
                  }}
                />
                {simpleValidator.current.message(
                  "name",
                  licenceData.name,
                  "min:2|required|max:100"
                )}
              </Col>
              <Col md={6} xs={12}>
                <label className="learner-label">Surname</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  value={licenceData.surname}
                  name="surname"
                  placeholder="Enter Last Name"
                  onChange={(e) => {
                    updateValue(e);
                  }}
                />
                {simpleValidator.current.message(
                  "surname",
                  licenceData.surname,
                  "min:2|required|max:100"
                )}
              </Col>
            </Row>
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">Email</label>
                <input
                  type="email"
                  className="form-control mt-1 no-radious"
                  value={licenceData.email}
                  name="email"
                  placeholder="Enter Email"
                  onChange={(e) => {
                    updateValue(e);
                  }}
                />
                {simpleValidator.current.message(
                  "email",
                  licenceData.email,
                  "email|required|max:100"
                )}
              </Col>
              <Col md={6} xs={12}>
                <label className="learner-label">Job Title</label>
                  <input
                    type="text"
                    className="form-control mt-1 no-radious"
                    value={licenceData.jobTitle}
                    name="jobTitle"
                    placeholder="Enter Job Title"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                  {simpleValidator.current.message(
                    "jobTitle",
                    licenceData.jobTitle,
                    "min:2|required|max:100"
                  )}
              </Col>
            </Row>
            <Row className="mt-4">
              <p className="add-learner-extra">
                <br />
                This learner will be emailed so that they can continue setting
                up their account.
                <br />
                {planName !== "ONBOARDING" && (
                  <>
                    The experience level you choose above will determine the
                    learning path on which they’ll start.
                  </>
                )}
              </p>
            </Row>
          </form>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center"> 
            <button
              onClick={() => {
                handleLearnerClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            <button
              type="button"
              disabled={licenceAllocation?.loading}
              onClick={(e) => {
                assignLicense(e,type==='learner'? 1 : 2);
              }}
              className="assign-btn"
            >
              {licenceAllocation?.loading && (
                <Spinner className="loader" animation="border" />
              )}
              Assign Seat to this {type==='learner' ? 'Learner' : 'Leader' }
            </button>
          </Col>
        </Row>
      </Modal>
      <Modal  show={manageLicense} onHide={hideManageLicense} size="xl"  backdrop="static">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Manage Licenses</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          {!getLicenceDetailsState?.loading ? (
            <>
              <div className="row parent-plan gx-5">
                <div className="col-md-12 col-lg-6 pl-5 col-xs-12 col-12">
                  <Card className="card-plan">
                    <Card.Body>
                      <Card.Title>
                        <div className="row">
                          <div className="col-md-3">
                            <img src={currentImage} alt="grpicon" />
                          </div>
                          <div
                            className="col-md-9"
                            style={{ textAlign: "right" }}
                          >
                            {current === selectedOption &&
                            <>
                            <button className="current-plan-btn">
                              Current Plan
                            </button> 
                            </>
                            } 
                          </div>
                          <div className="col-md-6 mt-4">
                            <h1 className="training-licenses">
                              {selectedOption} Licenses
                            </h1>
                          </div>
                        </div>
                      </Card.Title>
                      <Card.Text className="using-text mt-4">
                        You are using{" "}
                        <span style={{ fontWeight: 700 }}>
                          {
                            getLicenceDetailsState?.data?.subscription
                              ?.usedLicenses
                          }
                        </span>
                        <br /> of your{" "}
                        <span style={{ fontWeight: 700 }}>
                          {
                            getLicenceDetailsState?.data?.subscription
                              ?.totalLicenses
                          }
                        </span>{" "}
                        training licenses
                      </Card.Text>
                      <div className="col-md-12 mt-4">
                        {
                          options &&
                        
                        <Select
                          className="drop-down-l"
                          classNamePrefix="drop-down-l"
                          defaultValue={selectedOption}
                          value={options?.filter(function (option:any) {
                            return option.value === selectedOption;
                          })}
                          onChange={handleTypeSelect}
                          components={{ DropdownIndicator }}
                          options={options}
                          styles={colourStyles}
                        />
                      }
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-12 col-lg-6 pr-5 col-xs-12 col-12 ">
                  <Col md={12} xs={12} className="price-plate">
                    <span className="current-rate" style={{color:"#3B6494"}}>Current Rate</span>
                    <br />
                    <span
                      style={{
                        fontFamily: "Space Grotesk",
                        fontSize: "35px",
                        fontWeight: 500,
                        lineHeight: "54.47px",
                        textAlign: "left",
                      }}
                    >
                         {
                           `$`+getLicenceDetailsState?.data?.subscription
                              ?.currentTotalAmount+`/mo`
                          }
                    </span>
                    &nbsp;&nbsp;
                    <span className="per-license-price">({`$`+currentPrice} per license)</span>
                  </Col>
                  <Col md={12} xs={12} className="price-plate">
                    <span className="current-rate" style={{color:"#3B6494"}}>New Rate</span>
                    <br />
                    <span
                      style={{
                        fontFamily: "Space Grotesk",
                        fontSize: "35px",
                        fontWeight: 500,
                        lineHeight: "54.47px",
                        textAlign: "left",
                        
                      }}
                    >
                      {newRate === 0 ? '-' : `$`+newRate+`/mo`}
                      {newCurrentPrice>0 &&
                    <span className="per-license-price">({`$`+newCurrentPrice+` per license`})</span>
                    }
                    </span>
                  </Col>
                  <Col md={12} xs={12} className="price-plate" style={{background:dueRate === 0 ? "#E0E9F3" : needContent ? "#E4CFE1" : "#CAE2CD" }}>
                    <span
                      style={{ color: dueRate === 0 ? "#A9C2DE" : needContent ? "#4E2857": "#1B4332", backgroundColor: dueRate === 0 ? "#E0E9F3" : needContent ? "#E4CFE1" : "#CAE2CD" }}
                      className="current-rate-due"
                    >
                      {!needContent
                        ? `Due Today (Prorated)`
                        : `Contact us by clicking below`}
                    </span>
                    <br />
                    <span
                      style={{
                        fontFamily: "Space Grotesk",
                        fontSize: "35px",
                        fontWeight: 500,
                        lineHeight: "54.47px",
                        textAlign: "left",
                        color: dueRate === 0 ? "#787A6D" : "#1B4332"
                      }}
                    >
                      {  dueRate===0 ? `-` : needContent ? `-` : `$`+dueRate.toFixed(2)}
                    </span>
                  </Col>
                </div>
              </div>
              <div className="row footer-text-model mt-5 mb-3">
                {Number(current) <= Number(selectedOption) ? (
                  <span style={{color:'#274C77'}}>
                    New rate changes occur on your next billing cycle. Upgrades
                    get charged today at a prorated rate.
                  </span>
                ) : deleteArray?.length >= 0 ? (
                  <span style={{color:'#274C77'}}>
                    New rate changes occur on your next billing cycle. Upgrades
                    get charged today at a prorated rate.
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    ⚠️ You have too many active licenses to make this
                    adjustment. Please adjust your licenses.
                  </span>
                )}
              </div>
            </>
          ) : (
            <Grow />
          )}
        </Modal.Body>
        {
          !getLicenceDetailsState?.loading ?
          <>
          {
        Number(current) === Number(selectedOption) ? (
          <Row className="footer-modal">
            <Col md={12} xs={12} className="footer-section-1 text-center">
              <button
                onClick={() => {
                  hideManageLicense();
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </Col>
          </Row>
        ) : Number(current) >= Number(selectedOption) &&
          current-selectedOption > getLicenceDetailsState?.data?.subscription?.unusedLicenses && deleteArray?.length <= 0 ? (
          <Row className="footer-modal">
            <Col md={6} xs={12} className="footer-section-1 text-center">
              <button
                onClick={() => {
                  hideManageLicense();
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </Col>
            <Col md={6} xs={12} className="adjust-active-license text-center">
              <button
                onClick={() => {
                  openAdjustLicense();
                }}
                className="adjust-btn"
              >
                Adjust active licenses
              </button>
            </Col>
          </Row>
        ) : (
          <Row className="footer-modal">
            <Col md={6} xs={12} className="footer-section-1 text-center">
              <button
                onClick={() => {
                  hideManageLicense();
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </Col>
            {!needContent ? (
              <Col md={6} xs={12} className="confirm-new-plan text-center">
                <button
                  onClick={() => {
                    confirmNewPlan();
                  }}
                  disabled={updatePlanState?.loading}
                  className="confirm-btn"
                >
                  {updatePlanState?.loading && <Spinner className="loader" animation="border" />}Confirm new subscription plan
                </button>
              </Col>
            ) : (
              <Col md={6} xs={12} className="contact-us-plan text-center">
                <button
                  onClick={() => {
                    setContactModal(true)
                  }}
                  className="contact-us-btn"
                >
                  Contact us for Enterprise options
                </button>
              </Col>
            )}
          </Row>
        )}
        </>
        :
        <Row className="footer-modal">
          <Col md={12} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                hideManageLicense();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
        </Row>
        }
      </Modal>
      <Modal
        show={deleteManageLicense}
        onHide={hideManageLicense}
        size="xl"
        backdrop="static"
      >
        <Modal.Header className="model-heading-custom" closeButton>
              <Modal.Title>Add / Remove Licenses</Modal.Title>
              <span className="search-user"><img onClick={()=>searchDeletes('')} src={search ? Clearicon : Searchicon }  alt='search' /><input type='text' className={`form-controls search-bg`}  value={search}  onChange={(e)=>{searchDeletes(e.target.value)}} /></span>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <div className="row">
              <div className="text-left footer-text-model mt-2 mb-3">
                <span>
                  Select users to remove in order to make your subscription change. They will be notified via email.
                </span>
              </div>
            <div
              className="delete-license "
              
              style={{  height:learnerArray?.length>=10 ? 800 : 400, overflowY: "scroll" }}
            >
                <div className="row bottom-border licence-details">
                      <Col md={4} xs={4}>
                        <span className="current-rate-heading">Name</span>
                        <br />
                      </Col>
                      <Col md={4} xs={4}>
                        <span className="current-rate-heading">License Type</span>
                        <br />
                      </Col>
                      <Col md={4} xs={4}>
                        <span className="current-rate-heading">Remove user</span>
                        <br />
                      </Col>
                    </div>
              {
                !freshLearnerState?.loading ? 
                learnerArray?.map((learner:any,index:number)=>
                  (
                      learner?.allocationStatus === "UNASSIGNED" ? 
                          <div className="row bottom-border licence-details" key={index}>
                              <Col md={4} xs={4}>
                              <label htmlFor={learner?.licenseAllocationId}><span className="manage-license-name">Unassigned License</span></label>
                                <br />
                              </Col>
                              <Col md={4} xs={4}>
                              <label htmlFor={learner?.licenseAllocationId}> <span className="manage-license-name">-</span></label>
                                <br />
                              </Col>
                              <Col md={4} xs={4}>
                              </Col>
                        </div>
                       :  
                       <div className="row bottom-border licence-details" key={index}>
                       <Col md={4} xs={4}>
                       <label htmlFor={learner?.licenseAllocationId}><span className="manage-license-name">{learner?.name+` `+learner?.surname}</span></label>
                         <br />
                       </Col>
                       <Col md={4} xs={4}>
                       <label htmlFor={learner?.licenseAllocationId}><span className="manage-license-name"> {learner?.licenseType==='Leader'?  
                                    <button type='button' className="leader-type">Leader</button>
                                    :
                                    <button type='button' className="learner-type">Learner</button>
                                  }</span></label>
                         <br />
                       </Col>
                       <Col md={4} xs={4}>
                         <input
                           type="checkbox"
                           className="check_box"
                           id={learner?.licenseAllocationId}
                           value={learner?.licenseAllocationId}
                           disabled={learner?.disable}
                           checked = {learner.checked}
                           onClick={(e) => {
                             deactive(e);
                           }}
                           onChange={e => {}}
                         />
                            <label htmlFor={learner?.licenseAllocationId}></label>
                       </Col>
                     </div>
                    )
                )
                :
                <div style={{position: 'absolute',top:"50%",left:'50%'}}>
                    <Grow  />
                </div>
              }
            </div>
          </div>
          <div className="row footer-text-model mt-2 mb-2">
            <span style={{ color: checkBoxAgree ? "#3B3C37" : "red" }}>
              <input
                className="checkbox-agree"
                type="checkbox"
                id="checkbox6"
                onClick={(e) => agree(e)}
              />{" "}
              <label htmlFor="checkbox6" className="checkbox-agree-custome"></label>
              <label htmlFor="checkbox6" > &nbsp;&nbsp;I understand that these users will be removed from their licenses, and lose access to training</label>
            </span>
          </div>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                hideDeleteSelectLicenses();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          {deleteCounter === 0 && checkBoxAgree === true ? (
            <Col
              md={6}
              xs={12}
              className="delete-selected-licenses text-center"
            >
              <button
                onClick={() => {
                  deleteSelectedLicenses();
                }}
                className="delete-btn"
              >
                Remove selected users
              </button>
            </Col>
          ) : (
            <Col
              md={6}
              xs={12}
              style={{background:'#CEDBEB',color:'#012A4A'}}
              className="counter-selected-licenses text-center"
            >
              <button className="counter-btn"   style={{background:'#CEDBEB',color:'#012A4A'}}>
                {deleteCounter === 0 && checkBoxAgree === false
                  ? `Check the box above to continue`
                  : `Delete ${deleteCounter} licenses to continue`}
              </button>
            </Col>
          )}
        </Row>
      </Modal>
      <Modal show={learnerInfo} onHide={handleLearnerInfoClose} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>{learnerDetail?.licenseType} License</Modal.Title>
          {assignEdit ? (
            <>
              <div>
                <img src={Rightcheck} alt="right check" />
                &nbsp;&nbsp;
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: "400",
                  }}
                  className="common-success-text"
                >
                Invite email sent to this {learnerDetail?.licenseType}
              </span>
              </div>
            </>
          ) : (
            <></>
          )}
           {resendEmailMsg ? (
            <>
              <div>
                <img src={Rightcheck} alt="right check" />
                &nbsp;&nbsp;
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: "400",
                  }}
                  className="common-success-text"
                >
                Resend email sent successfully
              </span>
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal.Header>
        <Modal.Body className="model-body-custom">
        <Row className="mt-4 gx-5">
          <Col md={6}>
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">Name</label>
                <br />
                <div className="mt-2">
                  <span className="team-training-text ">
                    {learnerDetail.name + ` ` + learnerDetail.surname}
                  </span>
                  &nbsp;&nbsp;
                </div>
              </Col>
            </Row>
            <Row className="gx-5 mt-5">
              <Col md={6} xs={12}>
                <label className="learner-label">Job Title</label>
                <br />
                <div className="mt-2">
                  <span className="team-training-text ">
                    {learnerDetail?.jobtitle ? learnerDetail?.jobtitle : "-"}
                  </span>
                  &nbsp;&nbsp;
                </div>
              </Col>
            </Row>
            <Row className="gx-5 mt-5">
              <Col md={6} xs={12}>
                <label className="learner-label">Email</label>
                <br />
                <div className="mt-2">
                  <span className="team-training-text ">
                    {learnerDetail.email}
                  </span>
                  &nbsp;&nbsp;
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row className="mt-4 gx-5">
            {
              removeProfile===false ?
              <Col md={12}>
              <Row className="gx-5">
                <Col md={12} xs={12}>
                  <label className="learner-label">Actions</label>
                  <br />
                  <div className="mt-2">
                    {
                      learnerDetail?.allocationStatus==='SENT_INVITE' && 
                      <button  className="actions" disabled={resendEmailLoader} onClick={()=>{resendEmail(learnerDetail?.licenseAllocationId,2)}}>{resendEmailLoader === true && <Spinner className="loader" animation="border" /> } <img src={Resendicon} alt='resend' />&nbsp;&nbsp;&nbsp;&nbsp;Resend invitation email</button>
                    }
                    </div>
                </Col>
              </Row>
              <Row className="gx-5">
                <Col md={12} xs={12}>
                  <div className="mt-2">
                    <button  className="actions" onClick={()=>{handleAssignNewLearnerOpen(learnerDetail.planId)}}><img src={Swapicon} alt='swap' />&nbsp;&nbsp;Swap for a different {learnerDetail?.licenseType}...</button>
                  </div>
                </Col>
              </Row>
              {/* <Row className="gx-5">
                <Col md={12} xs={12}>
                  <div className="mt-2">
                    <button  className="actions" onClick={()=>{makeNewLeaderOrLearner(learnerDetail.licenseAllocationId,2)}}><img src={Leadericon} alt='leader'  />&nbsp;&nbsp;&nbsp;&nbsp;Make into a Leader license...</button>
                  </div>
                </Col>
              </Row> */}
              <Row className="gx-5">
               
                <Col md={12} xs={12}>
                  <div className="mt-2">
                    {learnerDetail?.allocationStatus!=='SENT_INVITE' && 
                    <button onClick={()=>{removeProfilefunc()}}  className="actions-danger"><img src={Dangericon} alt='delete' />&nbsp;&nbsp;&nbsp;&nbsp;Remove {learnerDetail?.licenseType} from license</button>
                    }
                    </div>
                </Col>
              </Row>
              </Col>
              :
              <Col md={12} className="mb-5">
                <div className="delete-section" style={{padding:20}}>
                <h3><img src={Excicon} alt='excicon' /> Before you continue...</h3>
                  <div className="delete-text" style={{padding:20,paddingLeft:40}}>
                    Removing this Learner will free up this license, so you can invite a different Learner (or Leader) to use it. 
                    This Learner’s training data can still be viewed in your Leader Dashboard.
                  </div>
                </div>
              </Col>
            }
            </Row>
          </Col>
        </Row>
        </Modal.Body>
        <Row className="footer-modal">
          {
            removeProfile === false ?
          <Col md={12} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleLearnerInfoClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          :
          <>
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={
                () => {
                setRemoveProfile(false)
                }
              }
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-1 text-center remove-profile">
          <button
            onClick={() => {
              deleteLeader(learnerDetail?.licenseAllocationId);
            }}
            className="remove-profile-btn"
            disabled={deleteLeaderState?.loading}
          >
            {deleteLeaderState?.loading && <Spinner className="loader" animation="border" />}
            Remove this {learnerDetail?.licenseType} from the license
          </button>
        </Col>
       </>
        }
       </Row>
      </Modal>
      <Modal show={deleteLicence} onHide={deleteLicenceClose} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Delete License</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          {learnerDetail.cancelAt === "" ? (
            <>
              <Row className="gx-5 justify-content-center justify-content-center">
                <Modal.Title style={{ color: "black" }}>
                  Here’s what you should know before you cancel:
                </Modal.Title>
              </Row>
              <Row className="gx-5 justify-content-center">
                <Col md={6} xs={12} className="mt-5 mb-5">
                  <div>
                    <img src={right} alt="cancel" />
                  </div>
                  <div className="mt-5">
                    <span className="cancel-text">
                      The learner will continue to have access to their course
                      content until {formatDate(learnerDetail.currentPeriodEnd)}
                      , then your plan rate will adjust for the new billing
                      cycle.
                    </span>
                  </div>
                </Col>
                <Col md={6} xs={12} className="mt-5 mb-5">
                  <div>
                    <img src={cross} alt="right" />
                  </div>
                  <div className="mt-5">
                    {learnerDetail?.legacyPlan === false ? (
                      <>
                        <span className="cancel-text">
                          We save the learner progress and data, so you can
                          alway re-add them to another license in the future.
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          className="cancel-text"
                          style={{ color: "#A34F52" }}
                        >
                          This is a Legacy license providing access to all
                          content on the platform. This license is no longer
                          offered, so{" "}
                          <span style={{ borderBottom: "1px solid" }}>
                            deleting it is irreversible.
                          </span>
                          &nbsp; Licenses added to the account in the future
                          will follow current pricing.
                        </span>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="gx-5 mt-5 mb-5 justify-content-center justify-content-center">
                <Modal.Title style={{ color: "black" }}>
                  Undo Delete License:
                </Modal.Title>
              </Row>
              <Row className="gx-5 justify-content-center"></Row>
            </>
          )}
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                deleteLicenceClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>

          <Col
            md={6}
            xs={12}
            className="footer-section-2 text-center"
            style={{ background: "#A34F52" }}
          >
            {learnerDetail.cancelAt === "" ? (
              <button
                className="assign-btn"
                onClick={() => {
                  removeLicence(learnerDetail.id, "fromDelete");
                }}
                disabled={cancelLicenceState.loading}
                style={{ background: "#A34F52" }}
              >
                {cancelLicenceState.loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Delete License
              </button>
            ) : (
              <button
                className="assign-btn"
                onClick={() => {
                  removeLicence(learnerDetail.id, "fromUndo");
                }}
                disabled={cancelLicenceState.loading}
                style={{ background: "#A34F52" }}
              >
                {cancelLicenceState.loading && (
                  <Spinner className="loader" animation="border" />
                )}
                Undo Delete License
              </button>
            )}
          </Col>
        </Row>
      </Modal>
      <Modal
        show={assignNewLearner}
        onHide={handleAssignNewLearnerClose}
        size="xl"
      >
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Swap for a different {learnerDetail?.licenseType}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row className="mt-4 gx-5">
            <Col md={6} xs={12} className="border-right-custom">
              <br />
              <span className="new-learner">Current {learnerDetail?.licenseType}</span>
              <Row>
                <Col md={12} className="mt-4" style={{ marginLeft: "20px" }}>
                  <label className="learner-label">Name</label>
                  <br />
                  <div className="mt-2">
                    <span className="team-training-text ">
                      {learnerDetail.name + ` ` + learnerDetail.surname}
                    </span>
                    &nbsp;&nbsp;
                  </div>
                </Col>
                <Col md={12} className="mt-4" style={{ marginLeft: "20px" }}>
                  <label className="learner-label">Job Title</label>
                  <br />
                  <div className="mt-2">
                    <span className="team-training-text ">
                      {learnerDetail.jobtitle ? learnerDetail.jobtitle : '-'}
                    </span>
                    &nbsp;&nbsp;
                  </div>
                </Col>
                <Col md={12} className="mt-4" style={{ marginLeft: "20px" }}>
                  <label className="learner-label">Email</label>
                  <br />
                  <div className="mt-2">
                    <span className="team-training-text ">
                      {learnerDetail.email}
                    </span>
                    &nbsp;&nbsp;
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6} xs={12}>
              <br />
              <span className="new-learner">New {learnerDetail?.licenseType}</span>
              <Row>
                <Col
                  md={12}
                  className="mt-4"
                  xs={12}
                  style={{ marginLeft: "20px" }}
                >
                  <label className="learner-label">Name</label>
                  <input
                    type="text"
                    className="form-control mt-1 no-radious"
                    placeholder="Enter First Name"
                    name="name"
                    onChange={(e) => {
                      updateValueEdit(e);
                    }}
                    value={editLicenceData.name}
                  />
                  {assignValidator.current.message(
                    "name",
                    editLicenceData.name,
                    "required|max:100|min:2"
                  )}
                </Col>
                <Col
                  md={12}
                  className="mt-4"
                  xs={12}
                  style={{ marginLeft: "20px" }}
                >
                  <label className="learner-label">Surname</label>
                  <input
                    type="text"
                    className="form-control mt-1 no-radious"
                    placeholder="Enter Last Name"
                    name="surname"
                    onChange={(e) => {
                      updateValueEdit(e);
                    }}
                    value={editLicenceData.surname}
                  />
                  {assignValidator.current.message(
                    "surname",
                    editLicenceData.surname,
                    "required|max:100|min:2"
                  )}
                </Col>
                <Col
                  md={12}
                  className="mt-4"
                  xs={12}
                  style={{ marginLeft: "20px" }}
                >
                  <label className="learner-label">Job Title</label>
                  <input
                    type="text"
                    className="form-control mt-1 no-radious"
                    placeholder="Enter Job Title"
                    name="jobtitle"
                    onChange={(e) => {
                      updateValueEdit(e);
                    }}
                    value={editLicenceData.jobtitle}
                  />
                  {assignValidator.current.message(
                    "jobtitle",
                    editLicenceData.jobtitle,
                    "required|max:100|min:2"
                  )}
                </Col>
                <Col
                  md={12}
                  className="mt-4"
                  xs={12}
                  style={{ marginLeft: "20px" }}
                >
                  <label className="learner-label">Email</label>
                  <input
                    type="text"
                    className="form-control mt-1 no-radious"
                    placeholder="lorem@ipsum.io"
                    name="email"
                    onChange={(e) => {
                      updateValueEdit(e);
                    }}
                    value={editLicenceData.email}
                  />
                  {assignValidator.current.message(
                    "email",
                    editLicenceData.email,
                    "email|required|max:100"
                  )}
                </Col>
                {/* {experience?.data?.length > 1 && (
                  <>
                    <Row className="mt-4 gx-5">
                      <label className="learner-label" htmlFor="beginner">
                        Learner Experience Level
                      </label>
                      <br />
                      {experience?.data?.map((exp: any) => {
                        return (
                          <>
                            <Col md={12} xs={12} key={exp.id}>
                              <input
                                type="radio"
                                onChange={(e) => {
                                  updateValueEdit(e);
                                }}
                                id={exp.id}
                                name="experienceLevel"
                                value={exp.id}
                                className="mt-3"
                              />
                              &nbsp;&nbsp;
                              <label
                                htmlFor={exp.id}
                                className="checkbox-class"
                              >
                                {exp.level}
                              </label>
                            </Col>
                          </>
                        );
                      })}
                      {assignValidator.current.message(
                        "experienceLevel",
                        editLicenceData.experienceLevel,
                        "required"
                      )}
                    </Row>
                  </>
                )} */}
              </Row>
            </Col>
          </Row>

          <Row className="mt-5">
            <p className="add-learner-extra">
              The current learner will be notified of their account suspension,
              the new learner will be notified
              <br /> about setting up their account, and there will be no
              changes to your subscription licenses or price.
            </p>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleAssignNewLearnerClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            <button
              className="assign-btn"
              disabled={editLicenceState?.loading}
              onClick={(e) => {
                assignSeatNewLearner(e);
              }}
            >
              {editLicenceState?.loading && (
                <Spinner className="loader" animation="border" />
              )}
              Assign Seat to this New {learnerDetail?.licenseType}
            </button>
          </Col>
        </Row>
      </Modal>
      <Modal
        scrollable={true}
        show={manageLeader}
        onHide={manageLeaderClose}
        size="xl"
      >
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Manage Leaders</Modal.Title>
          {deleteSuccess ? (
            <>
              <div className="update-class">
                <img src={Rightcheck} alt="right check" />
                &nbsp;&nbsp;
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: "400",
                  }}
                  className="common-success-text"
                >
                  Admin deletion successful
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
          {adminSuccess ? (
            <>
              <div className="update-class">
                <img src={Rightcheck} alt="right check" />
                &nbsp;&nbsp;
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: "400",
                  }}
                  className="common-success-text"
                >
                  Admin Addition successful
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row className="mt-4 gx-5">
            <Col md={12} xs={12}>
              <table className="table table-borderless responsive">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Job Title</th>
                    <th>Last Login</th>
                  </tr>
                </thead>
                <tbody className="border-top-custom ">
                  {!leadersState.loading ? (
                    leadersState?.data?.map(
                      (leader: any, index: number) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>
                                <img
                                  height="65px"
                                  width="65px"
                                  src={defaultUser}
                                  alt="profileicon"
                                />
                              </td>
                              <td>
                                <span className="table-name">
                                  {leader.name
                                    ? leader.name + ` ` + leader.surname
                                    : "-"}
                                </span>
                              </td>
                              <td className="licence-text">
                                {leader.jobTitle ? leader.jobTitle : "-"}
                              </td>
                              <td className="last-login-date">
                                {leader.allocationStatusText}
                                {leader?.allocationStatus === "SENT_INVITE" && (
                                  <>
                                    <div>
                              
                                    </div>
                                  </>
                                )}
                              </td>

                              <td>
                                {leader.allocationStatus === "Unassigned" ? (
                                  <img
                                    onClick={() => {
                                      addLeaderOpen();
                                    }}
                                    src={addicon}
                                    alt="addicon"
                                    height={60}
                                    width={60}
                                  />
                                ) : (
                                  !leader?.isCurrentUser && (
                                    <>
                                      <div className="dropdown">
                                        <a
                                          href="#"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img
                                            src={Minusicon}
                                            alt="minusicon"
                                          />
                                        </a>
                                        <div
                                          className="dropdown-menu "
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          <div className="delete-leader delete-description">
                                            Are you sure you want to remove{" "}
                                            {leader.name} {leader.surname} as a
                                            Leader? They’ll receive a
                                            notification via email.
                                          </div>
                                          <div className="delete-leader-button text-center">
                                            <button
                                              className="delete-button"
                                              onClick={() =>
                                                deleteLeader(leader.licenseAllocationId)
                                              }
                                            >
                                              Remove this Leader
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <Grow />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                manageLeaderClose();
              }}
              className="cancel-btn"
            >
              Back to Manage Team
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            <button
              onClick={() => {
                addLeaderOpen();
              }}
              className="assign-btn"
            >
              Add a New Leader
            </button>
          </Col>
        </Row>
      </Modal>
      <Modal show={addLeader} onHide={addLeaderClose} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Manage Leaders</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <img src={Leader} alt="learner" />
          <span className="new-learner">ADD A NEW LEADER</span>
          <form id="leader-form">
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">First Name</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  placeholder="Enter First Name"
                  name="name"
                  onChange={(e) => {
                    updateLeaderData(e);
                  }}
                  value={leaderData.name}
                />
                {leaderValidator.current.message(
                  "name",
                  leaderData.name,
                  "min:2|required|max:100"
                )}
              </Col>
              <Col md={6} xs={12}>
                <label className="learner-label">Last Name</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  placeholder="Enter Last Name"
                  name="surname"
                  onChange={(e) => {
                    updateLeaderData(e);
                  }}
                  value={leaderData.surname}
                />
                {leaderValidator.current.message(
                  "surname",
                  leaderData.surname,
                  "min:2|required|max:100"
                )}
              </Col>
            </Row>
            <Row className="mt-4 gx-5">
              <Col md={6} xs={12}>
                <label className="learner-label">Email</label>
                <input
                  type="email"
                  className="form-control mt-1 no-radious"
                  placeholder="Enter Email"
                  name="email"
                  onChange={(e) => {
                    updateLeaderData(e);
                  }}
                  value={leaderData.email}
                />
                {leaderValidator.current.message(
                  "email",
                  leaderData.email,
                  "email|required|max:100"
                )}
              </Col>
              <Col md={6} xs={12}>
                <label className="learner-label">Job Title</label>
                <input
                  type="text"
                  className="form-control mt-1 no-radious"
                  placeholder="Enter Jobtitle"
                  name="jobTitle"
                  onChange={(e) => {
                    updateLeaderData(e);
                  }}
                  value={leaderData.jobTitle}
                />
                {leaderValidator.current.message(
                  "jobTitle",
                  leaderData.jobTitle,
                  "min:2|required|max:100"
                )}
              </Col>
            </Row>
            <br />
            <br />
          </form>
          <Row className="mt-4">
            <p className="add-learner-extra">
              This leader will be emailed of their account creation, so they can
              continue setting up their account. They will have the same
              administrative privileges as you, including the ability to:
              <br />
              <b>
                manage learners, your Alchemy Vision subscription, your practice
                information, and payment type
              </b>
            </p>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                addLeaderClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            <button
              onClick={(e) => {
                addNewLeader(e);
              }}
              disabled={addLeadersState?.loading}
              className="assign-btn"
            >
              {addLeadersState?.loading && (
                <Spinner className="loader" animation="border" />
              )}
              Add New Leader
            </button>
          </Col>
        </Row>
      </Modal>
      {!ref ? 
      <Col md={12} className="mt-5" id="manage-team">
        <div className="common-box">
          <div className="partition practice-color ">
            <Row id='sent-invite'>
              <Col md={6}>
                <DashboardNav />
                <span className="common-text">Manage Licenses</span>
              </Col>
              <Col md={6} className="text-right update-class">
                {licenceUpdateSuccess ? (
                  <>
                    <img src={Rightcheck} alt="right check" />
                    &nbsp;&nbsp;
                    <span>&nbsp;&nbsp;License updates successfully</span>
                  </>
                ) : (
                  <></>
                )}
                {licenceAllocationSuccess ? (
                  <>
                    <img src={Rightcheck} alt="right check" />
                    &nbsp;&nbsp;
                    <span style={{color:'#CAE2CD',fontSize:'21px'}}>&nbsp;&nbsp;Invite email sent to the {type==='learner' ? 'Learner' :'Leader'}</span>
                  </>
                ) : (
                  <></>
                )}
                {
                deleteSuccess &&
                  (
                    <>
                      <img src={Rightcheck} alt="right check" />
                      &nbsp;&nbsp;
                      <span style={{color:'#CAE2CD',fontSize:'21px'}}>&nbsp;&nbsp;{type==='learner' ? 'Learner' :'Leader'} successfully removed</span>
                    </>
                  )
                }
              </Col>
              <br />
            </Row>
          </div>
          <div className="partition">
            <div className="table-responsive-lg">
              <table className="table table-borderless responsive">
                <thead>
                  <tr className="manage-learner-heading">
                    <th  className="heading-table" onClick={()=>SortOrder('name')}>Name {nameSort===false ? <i className="fa fa-angle-up" aria-hidden="true"></i> : <i className="fa fa-angle-down" aria-hidden="true"></i>}</th>
                    <th className="heading-table" onClick={()=>SortOrder('licenseType')}>License Type {typeSort===false ? <i className="fa fa-angle-up" aria-hidden="true"></i> : <i className="fa fa-angle-down" aria-hidden="true"></i> }</th>
                    <th className="heading-table" onClick={()=>SortOrder('allocationStatusText')}>Last login {loginSort===false ? <i className="fa fa-angle-up" aria-hidden="true"></i>: <i className="fa fa-angle-down" aria-hidden="true"></i> }</th>
                    <th className="heading-table"></th>
                  </tr>
                </thead>
                <tbody className="border-top-table">
                  {!learnerState.loading ? (
                    currentTableData && currentTableData?.length > 0 ? (
                      currentTableData.map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                            {item.email ? (
                              <>
                                <td width={'30%'}>
                                  <span className="table-name">
                                    {item?.name + ` ` + item?.surname}
                                  </span>
                                  {/* <br />
                                  <span className="table-email">
                                    {item?.email}
                                  </span> */}
                                </td>
                                <td width={'25%'} className="licence-text">
                                    <button type='button' className={item?.licenseType.toLowerCase()+`-type`}>{item?.licenseType}</button>
                                 </td>
                                <td width={'30%'} className="last-login-date">
                                  {item?.allocationStatusText}
                                </td>
                                {item?.allocationStatus === 'ACTIVE' || item?.allocationStatus==='SENT_INVITE' || item?.allocationStatus==='CANCELED' ? (
                                  <>  
                                    <td width={'15%'}>
                                      {item?.isCurrentUser===false && 
                                      <button
                                        onClick={() => {
                                          handleLearnerInfo(
                                            item.email,
                                            item.name,
                                            item.surname,
                                            item?.plan?.name,
                                            item.licenseAllocationId,
                                            // item.subscription.currentPerio,dEnd,
                                            item.cancelAt ? item.cancelAt : "",
                                            item?.plan?.id,
                                            item.profileId,
                                            item?.plan?.legacyPlan,
                                            item?.allocationStatus,
                                            item?.jobTitle,
                                            item?.licenseType
                                          );
                                        }}
                                        className="manage-icon action-type"
                                      >
                                        Actions<i className="fa fa-chevron-right" style={{marginLeft:14}} aria-hidden="true"></i>
                                      </button>
                                      }
                                    </td>
                                  </>
                                ) : (
                                  <td>
                                    <button  onClick={() => {
                                        handleLearnerShow();
                                      }} className="invite-type">Invite </button>
                                  </td>
                                )}
                              </>
                            ) : (
                              <>
                                <td width={'30%'}>
                                  <span className="table-name">
                                    Unassigned
                                  </span>
                                  <br />
                                </td>
                                <td width={'25%'} className="licence-text">
                                  {item?.plan?.name}
                                </td>
                                <td width={'30%'} className="last-login-date">
                                   {item?.allocationStatusText}
                                </td>
                                <td width={'15%'}>
                                    <button  onClick={() => {
                                        handleLearnerLeaderOption(item);
                                      }} className="invite-type">Invite</button>
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="no-data-found" colSpan={5}>
                          <h4>No Data Found</h4>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr className="mt-5 mb-5">
                      <td className="no-data-found" colSpan={5}>
                        <Grow />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={learnerState?.data?.length}
                pageSize={PageSize}
                onPageChange={(page: any) => setCurrentPage(page)}
              />
            </div>
          </div>
          <div className="partition-last">
            <Row className="gx-32 update-btn-border">
              <Col
                md={12}
                className="text-center main-div-last save-my-details-div"
                style={{ background: "#F2F6FC" }}
              >
                <button
                  className="save-my-details"
                  style={{
                    border: "none",
                    background: "none",
                    marginTop: "10px",
                    fontWeight: 500,
                    color: "#1A2121",
                  }}
                  onClick={() => {
                    if (!profileData?.freeTrial) {
                      // showManageLicense();
                      navigate('/my-practice#manage-subscription')
                    }
                  }}
                  disabled={profileData?.freeTrial}
                >
                  Need more / fewer licences?  <span style={{fontWeight:700,textDecoration:'underline'}}>Manage Subscription</span>
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      :
      <></> 
    }
    </>
  );
})

export default Manageteam;
