/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import './Undermaintenance.scss'
import  maintenance from "../../assets/images/maintenance.svg";

function Undermaintenance() {
  return (
    <>
      <div className="junction-page-container" style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
        <div className="action-buttons" >
        <div className="">
          <div className="gx-5  row justify-content-center" style={{padding:20}}>
            <div className="col-md-8" style={{textAlign:'center'}}>
              <img src={maintenance} alt="maintainance"   height={100} width={130}/><br/><br/>
              <span style={{fontSize: '25px',fontWeight: '500',lineHeight: '35.16px',textAlign: 'center',marginTop:50,color:'#012A4A'}}>
                Our internal systems are currently under maintenance.We’ll be back up for you as soon as we can!
              </span>
            </div>
          </div>
          </div>
        </div>

        <div className="jn-footer">
          <div className="footer-text">
            <span>
              NEED HELP? <a className='no-color' href='tel:+12135108050'> CALL +1 213 510 8050</a>
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              <a className='no-color' href='mailto:hello@alchemyvision.com'>EMAIL SUPPORT</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;<a className='no-color' href='mailto:hello@alchemyvision.com'>FAQs</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; <a className='no-color' target='_blank' rel='noreferrer' href='https://www.alchemyvision.com/terms'>TERMS
              & CONDITIONS{" "}</a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Undermaintenance;