import apiService from "../../services/api.service";
import * as actionTypes from "./actions";

export const register = (registerData: any): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.REGISTER_USER,
    });
    apiService
      .register(registerData)
      .then(({ data, ...rest }) => {
        const response = data.response;
        dispatch({
          type: actionTypes.REGISTER_USER_SUCCESS,
          data: response,
        });
      })
      .catch(({ response }) => {
        let error;
        if (Array.isArray(response?.data?.errors)) {
          error = response?.data?.errors;
        } else {
          error = response;
        }

        dispatch({
          type: actionTypes.REGISTER_USER_FAILED,
          error,
        });
      });
  };
};

export const registerDataStore = (registerData: any): any => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.REGISTER_DATA,
      registerData:registerData ,
    });
  };
};
