/* eslint-disable jsx-a11y/anchor-is-valid */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footerlogo from "../../../assets/images/footer-Logo.svg";
import "./footer.scss";
import { AppConfig } from "../../../configs/app.config";
import { useLocation } from "react-router-dom";

function MainFooter() {
    const location = useLocation();
  
  return (
    localStorage?.getItem("accessToken") && location.pathname!=='/'   ? 
    <div className="footer-a">
      If you still have questions before committing to a subscription,<br/> you can contact us at sales@alchemyvision.com or +1 213 510 8050
    </div>
    :
    <div className="footer">
    <Container fluid="xxl">
      <Row>
        <Col md={5} className="text-left header-pending">
          <img src={Footerlogo} width='255px' alt="footer-logo" />
        </Col>
        <Col md={2} className="text-left header-pending">
          <b className="footer-title mb-5">Explore</b>
          <br />
          <br />
          <a className="footer-link" href={AppConfig.productUrl} target="_self" rel="noreferrer">
            Solutions
          </a>
          <br />
          <a className="footer-link" href={AppConfig.pricingUrl} target="_self" rel="noreferrer" >
            Pricing
          </a>
          <br />
          <a className="footer-link" href={AppConfig.demo} target="_self" rel="noreferrer" >
            Demo
          </a>
          <br />
        </Col>
        <Col md={2} className="text-left header-pending">
          <b className="footer-title mb-5">Company</b>
          <br />
          <br />
          <a className="footer-link" href={AppConfig.about} target="_self" rel="noreferrer">
            About
          </a>
          <br />
          <a className="footer-link" href={AppConfig.blog} target="_blank" rel="noreferrer">
            Blog
          </a><br/>
          <a className="footer-link" href={AppConfig.terms} target="_self" rel="noreferrer">
            Terms
          </a>
          <br />
        </Col>
        <Col md={2} className="text-left header-pending">
          <b className="footer-title mb-5">Connect</b>
          <br />
          <br />
          <a className="footer-link" href={AppConfig.linkedIn} target="_blank" rel="noreferrer">
          LinkedIn
          </a><br/>
          <a className="footer-link"  href={AppConfig.instagram} target="_blank" rel="noreferrer">
          Instagram
          </a>
          <br />
          <a className="footer-link"  href='mailto:hello@alchemyvision.com' target="_self" rel="noreferrer">
          Email
          </a>
          <br />
        </Col>
      </Row>
      <br />
    <div className="last-text" style={{ color: "white" }}>
      <Row>
        <Col md={6} className="text-left">
          <span className="footer-last-text">
            Copyright @{new Date().getFullYear()} Alchemy Vision, Inc. All rights reserved.
          </span>
        </Col>
      </Row>
    </div>
    </Container>
  </div>
  );
}

export default MainFooter;
