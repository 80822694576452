// login actions
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";

//reset register
export const RESET_REGISTER = "RESET_REGISTER";
export const REGISTER_DATA = "REGISTER_DATA";
export const REGISTER_DATA_RESET = "REGISTER_DATA_RESET";
export const SET_RESET_FALSE = "SET_RESET_FALSE";
export const SET_RESET_TRUE = "SET_RESET_TRUE";
