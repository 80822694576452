import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import React, { useState, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AppConfig } from "../../configs/app.config";
import ApiService from "../../services/api.service";
import { toast } from "react-toastify";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import SimpleReactValidator from "simple-react-validator";
import { GetApplyCouponState } from "../../types/Coupon.type";
import Spinner from "react-bootstrap/Spinner";
import Progress from "../common/Progress";
import { applyCouponCode } from "../../store/coupons/effect";
import './stripe.scss';
import { useDispatch, useSelector } from "react-redux";
import { RESET_APPLY_COUPON } from "../../store/coupons/actions";
import Choosedplan from "../widgets/Choosedplan";

const stripePromise = loadStripe(AppConfig.stripePrimaryKey);

function Stripe() {
  const { state } = useLocation() as any;
  const navigate = useNavigate();

  const appearance = {
    variables: {
        // cssSrc:"https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap",
        // family:"Montserrat, system-ui, sans-serif",
        // weight:700,
      colorPrimary: "#0570de",
      colorBackground: "#eee",
      colorText: "#787A6D",
      colorDanger: "#df1b41",
      fontSizeBase:'17px',
      // fontWeight:'bold',
      // See all possible variables below
    },
    rules: {
     
      '.Label': {
        fontWeight:'bold',
      },

    }
    // loader:'always',
    // currency:'usd'
  };

  React.useEffect(() => {
    if (state === null) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  var options;
  if (state !== null) {
    options = {
      // passing the client secret obtained from the server
      clientSecret: state.clientSecret,
      appearance: appearance,
    };
  }
  return (
    <>
      <section className="stripe-section">
        <Container className="stripe-form">
          <Progress step={2} />
        </Container>
       
        <Container className="mt-2">
          <Elements stripe={stripePromise} options={options}>
            <StripeIntegration />
          </Elements>
        </Container>
      </section>
    </>
  );
}

function StripeIntegration() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { state } = useLocation() as any;
  const [exist,setExist] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disablebtn, setDisablebtn] = useState(false);
  const [couponStatus, setCouponStatus]:any = useState();
  const [discountData, setDiscountData]:any = useState();
  const [token, setToken]:any = useState();
  const [billingInfo, setBillingInfo] = useState({
    address: "",
    suite: "",
    city: "",
    state: "",
    postalCode: "",
    code: "",
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  let accessToken;
  React.useEffect(()=>{
    const checkTypewriter = document.querySelector('.header-pending')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    accessToken = JSON.parse(localStorage?.getItem("accessToken") as string);
    setToken(accessToken?.token)
    if (!checkTypewriter) {
      setExist(false)
    }
      if(applyCouponState?.lastApplied){
        dispatch(applyCouponCode(applyCouponState?.lastApplied, state.planId));
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        not_regex: "Please Enter Valid Value",
      },
    })
  );
  const couponValidator = useRef(
    new SimpleReactValidator({
      messages: {
        not_regex: "Please Enter Valid Value",
      },
    })
  );

  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      setValidated(true);
      simpleValidator.current.showMessages();
    } else {
      if (!stripe || !elements) {
        return;
      }
      setDisablebtn(true);
      await  stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: "https://app.alchemyvision.com/thankyou",
          },
          redirect: "if_required",
        })
        .then((result) => {
          if (result && !Object.hasOwn(result, "error")) {
              const data = {
                  practiceInfo:{
                  name: state.registerDataState.practiceName,
                  practiceName: state.registerDataState.practiceName,
                  workEmail: state.registerDataState.workEmail,
                  website: state.registerDataState.practiceUrl,
                  contactNumber: state.registerDataState.contactNumber,
                  location: { 
                    address: state.registerDataState.address,
                    suite: state.registerDataState.suite,
                    city: state.registerDataState.city,
                    state: state.registerDataState.state,
                    postalCode: state.registerDataState.postalCode,
                    }
                  },
                  pointOfContact: {
                    firstName: state.registerDataState.firstname,
                    lastName: state.registerDataState.lastname,
                    professionalTitle: state.registerDataState.professionalTitle,
                    workEmail: state.registerDataState.workEmail,
                  },
                  billingInfo: {
                    billingAddress: billingInfo.address,
                    suite: billingInfo.state,
                    city: billingInfo.address,
                    state: billingInfo.address,
                    postalCode: billingInfo.address,
                  },
                  additionalInfo: state.registerDataState.additionalInfo ? state.registerDataState.additionalInfo : undefined,
                  discoverySource:state.registerDataState.referall ? state.registerDataState.referall : undefined,
                  licenseTemplateId:state.planId,
                  couponCode:applyCouponState?.lastApplied ? applyCouponState?.lastApplied : undefined
              };
              ApiService.createPayment(data)
                .then((resp) => {
                  if (resp) {
                    if(exist===true){
                    navigate("/thankyou",{ replace: true,state: {email:state.registerDataState.workEmail,lastPlanId:state.planId,discount:discountData} });
                    }else{
                    navigate("/thankyouwithoutheader",{ replace: true,state: {email:state.registerDataState.workEmail,lastPlanId:state.planId,discount:discountData} });
                    }
                  }
                })
                .catch((errorData) => {
                  const { response } = errorData;
                  const error = response;
                  toast.error(
                    error?.statusText, {
                    position: "top-right",
                  });
                  setDisablebtn(false);
                });
          } else {
            toast.error(result?.error?.message, {
              position: "top-right",
            });
            const section = document.querySelector("#stripe-integration");
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
            setDisablebtn(false);
          }
        });
    }
  };

  const setBillingInfoValue = async (event: any) => {
    var { name, value } = event.target;
    simpleValidator.current.showMessageFor(name);
    setBillingInfo({ ...billingInfo, [name]: value });
  };

  const [applyCouponData, setApplyCouponData] = useState({ couponCode: "" });
  const applyCouponState = useSelector(
    (state: GetApplyCouponState) => state?.couponState.applyCoupon
  );

  React.useEffect(() => {
    // dispatch({
    //   type: RESET_APPLY_COUPON,
    //   data: null,
    // });
    if (applyCouponState?.error) {
      if (applyCouponState?.error?.data?.message) {
        // toast.error(applyCouponState?.error?.data?.message, {
        //   position: "top-right",
        // });
        setCouponStatus(false)
        dispatch({
          type: RESET_APPLY_COUPON,
          data: null,
        });
      }
    }
    if (applyCouponState?.data?.discount) {
    setDiscountData(applyCouponState?.data)
      setCouponStatus(true)
      dispatch({
        type: RESET_APPLY_COUPON,
        coupon: applyCouponData.couponCode,
      });
    }
    if(applyCouponState?.lastApplied){
      let code ={couponCode:applyCouponState?.lastApplied};
      setApplyCouponData(code);
      setCouponStatus(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyCouponState]);


  

  const handleCoupanInputs = (event: any) => {
    var { name, value } = event.target;
    setApplyCouponData({ ...applyCouponData, [name]: value });
  };
  const applyCouponCodeForm = (event: any) => {
    event.preventDefault();
    const formValid = couponValidator.current.allValid();
    if (!formValid) {
      couponValidator.current.showMessages();
    } else {
      dispatch(applyCouponCode(applyCouponData.couponCode, state.planId));
    }
  };

  return (
    <div>
      <Container className="small-container">
      <Choosedplan planid={state.planId} discountData={discountData} />
          <form onSubmit={handleSubmit} className="mt-5">
            <div id="stripe-integration">
              <PaymentElement />
            </div>
            <br />
            <b className="lable-elm-info">Billing Info</b>
            <br />
            <br />
            <Row>
              <Col md={8}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">Address*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single-billing-info"
                    name="address"
                    maxLength={100}
                    onChange={(e) => {
                      setBillingInfoValue(e);
                    }}
                    value={billingInfo.address}
                  />
                  {simpleValidator.current.message(
                    "address",
                    billingInfo.address,
                    "required|max:100"
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">
                    Suite, Unit, Etc.
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single-billing-info"
                    name="suite"
                    maxLength={50}
                    value={billingInfo.suite}
                    onChange={(e) => {
                      setBillingInfoValue(e);
                    }}
                  />
                  {simpleValidator.current.message(
                    "suite",
                    billingInfo.suite,
                    "max:50"
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">City*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single-billing-info"
                    name="city"
                    maxLength={100}
                    value={billingInfo.city}
                    onChange={(e) => {
                      setBillingInfoValue(e);
                    }}
                  />
                  {simpleValidator.current.message(
                    "city",
                    billingInfo.city,
                    "required|max:100"
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">State*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single-billing-info"
                    name="state"
                    maxLength={100}
                    value={billingInfo.state}
                    onChange={(e) => {
                      setBillingInfoValue(e);
                    }}
                  />
                  {simpleValidator.current.message(
                    "state",
                    billingInfo.state,
                    "required|max:100"
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Col}
                  controlId="validationCustom01"
                  className="form-elem"
                >
                  <Form.Label className="lable-elm">Postal Code*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    className="form-elem-single-billing-info"
                    name="postalCode"
                    maxLength={15}
                    value={billingInfo.postalCode}
                    onChange={(e) => {
                      setBillingInfoValue(e);
                    }}
                  />
                  {simpleValidator.current.message(
                    "postalCode",
                    billingInfo.postalCode,
                    "required|max:50"
                  )}
                </Form.Group>
              </Col>
              <Row>
                {
                  !token &&
                <>
               <Col md={6} className="mt-4">
              <Form.Group as={Col} controlId="coupan-box" className="form-elem">
                    <Form.Control
                      required
                      type="text"
                      name="couponCode"
                      className="form-elem-single"
                      onChange={handleCoupanInputs}
                      value={applyCouponData.couponCode}
                      maxLength={40}
                      placeholder="Promo code"
                      style={{border:couponStatus===true ? '1px solid #40916C' : couponStatus===false ? '1px solid #FF5F00' : '1px solid #DBD9D9',backgroundColor:couponStatus===true ? '#DCF1E8' : couponStatus===false ? '#F8EAE1' : 'transperent',marginBottom: "15px" }}
                    />
                    {couponValidator.current.message(
                      "couponCode",
                      applyCouponData.couponCode,
                      "required|max:50"
                    )}
                   
                  </Form.Group>         
                </Col>
                <Col md={6} >
                  <button
                    type="button"  
                    className="coupan-btn mt-4"
                    onClick={applyCouponCodeForm}
                  >
                    Apply
                  </button>
                  {couponStatus === true && (
                    <button type="button" className="code-applied" style={{marginLeft:'10px'}}>✅ Promo code applied</button>
                  ) }
                    {couponStatus === false && (
                    <button type="button" className="code-not-applied" style={{marginLeft:'10px'}}>⚠️ Promo code is invalid</button>
                  )}
                </Col>
                </>
              }
              </Row>
              <Row className="team-started">
                <Container className="small-container ">
                  {/* <h1 className="team-price">
                    ${total}/mo
                    {applyCouponState?.lastDiscount > 0 &&
                      `(-$` + applyCouponState?.lastDiscount + `)`}
                  </h1> */}
                  <Row className="gx-5 mb-5" style={{ marginTop: "-2rem" }}>
                    <Col md={12} className="text-center team-start-div">
                      <Button
                        onClick={(e) => handleSubmit(e)}
                        className="team-start-btn"
                        style={{paddingLeft:80,paddingRight:80}}
                        disabled={disablebtn}
                      >
                        {disablebtn && (
                          <Spinner className="loader" animation="border" />
                        )}
                        Pay Now
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Row>
            </Row>
          </form>
        {/* <Col md={1}></Col>
        <Col md={4}>
          <Subscriptionsummery />
        </Col> */}
      </Container>
    </div>
  );
}

export default Stripe;
