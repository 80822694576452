import React,{  useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { getPlans,getAAOPlans } from "../../store/plans/effect";
import { useLocation,useNavigate } from "react-router-dom";
import apiService from "../../services/api.service";
import Grow from "../loader/Grow";

const Choosedplan = ({planid,discountData=null}:any) => {
    const { pathname } = useLocation() as any;
    const dispatch = useDispatch()
    const [selected, setSelected]:any = useState();
    const planState = useSelector((state: any) => state.planState.plans);
    const aaoplanState = useSelector((state: any) => state.planState.aaoplans);
    const [exist,setExist] = useState(true);
    const [fromAao,setFromAao] = useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        const checkTypewriter = document.querySelector('.header-pending')
        if (!checkTypewriter) {
            setExist(false)
        }
        const accessToken = JSON.parse(localStorage?.getItem("accessToken") as string);
        if(accessToken?.token){
             apiService.aaoVerify(accessToken.token).then(({ data, ...rest })=>{
                setFromAao(true)
                }).catch(({response})=>{
                const {data} = response
                if(data?.auth===false){
                    navigate('/')
                }
            })
            // window.alert(aaoplanState?.data?.length)
            if(aaoplanState?.data?.length === 0){
                dispatch(getAAOPlans(1,accessToken?.token));
            }
        }
       
        dispatch(getPlans(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(()=>{
        // window.alert(fromAao)
    },[fromAao])

    React.useEffect(()=>{
        let selectedPlan:any;
        // window.alert(fromAao)
        if(fromAao){
            // window.alert('innner')
            selectedPlan = aaoplanState?.data?.filter((plan:any)=>plan.id === planid)
        }else{
            selectedPlan = planState?.data?.filter((plan:any)=>plan.id === planid)
        }
        if(selectedPlan){   
            setSelected(selectedPlan[0])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[planState?.data,aaoplanState?.data,fromAao])

  return (
    <div className='choose-plan'>
        { 
        selected ?
        <>
        <span className='mt-2 choosen'>{pathname!=='/thankyou' ? 'You’ve chosen' : 'You’re subscribed'}</span><br/><br/>
        <span className='mt-5 choosen-price'>{selected?.licenseCount} licenses for ${ discountData?.total ? discountData?.total:selected?.displayPrice}/mo {discountData && pathname!=='/thankyou' && <> <span style={{color:'#549b7b'}}>{ discountData?.discountType==="percentOff" ? `(${discountData?.discountRate}% discount)` : `(${discountData?.discount}$ discount)`}</span></>}</span><br/><br/>
        {
            pathname!=='/thankyou'&&
            fromAao===false ?
            <Link to= {exist===true ?'/?changequantity=true' : '/pricing/?changequantity=true'} className='mt-5 change-qty'>Change quantity</Link>
            :
            pathname!=='/thankyou'&&
            <Link to= {'/academypricing?changequantity=true'} className='mt-5 change-qty'>Change quantity</Link>
        }
        </>
        :
        <Grow />
        }
    </div>
  )
}

export default Choosedplan