/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Subtitle from "../widgets/Subtitle";
import Title from "../widgets/Title";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { getBilling, editBilling } from "../../store/billingdetails/effect";
import { GetBillingState, EditBillingState } from "../../types/Billing.type";
import Grow from "../loader/Grow";
import { toast } from "react-toastify";
import { RESET_BILLINGEDIT } from "../../store/billingdetails/actions";
import PhoneInput from "react-phone-input-2";
import Rightcheck from "../../assets/images/rightcheck.svg";
import apiService from "../../services/api.service";
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { AppConfig } from "../../configs/app.config";
import { loadStripe } from "@stripe/stripe-js";
import DashboardNav from "../../pages/widgets/DashboardNav";

function Billingdetails(params: any) {
  const dispatch = useDispatch();
  const [show6, setShow6] = useState(false);
  const [openStripe, setOpenStripe] = useState(false);
  const handleStripeClose = () => {
    setOpenStripe(false);
    setOptions({
      ...options,
      ...{ clientSecret:''},
    });
  }
  const [options, setOptions]:any = useState({clientSecret:''});

  const handleStripeOpen = () => {
    handleClose6();
    apiService
        .editCreditCard()
        .then((resp) => {
          if (resp && resp.data) {
            setOptions({
              ...options,
              ...{ clientSecret: resp?.data?.data?.setupIntents?.clientSecret},
            });
          }
        })
        .catch((errorData) => {
          const { response } = errorData;
          const error = response;
          toast.error(error?.data?.message, {
            position: "top-right",
          });
        });
    setOpenStripe(true)
  }
  const [editData, setEditData]: any = useState({
    name: "",
    type: "",
    number: "",
    billingAddress: "",
    contactNumber: "",
    expiry: "",
    expiryMonth: "",
    expiryYear: "",
    cardNumber: "",
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  const [update, setUpdate] = useState(false);
  const [success, setSuccess] = useState(false);
  const billingState = useSelector(
    (state: GetBillingState) => state.billingState.billigdetails
  );
  const editBillingState = useSelector(
    (state: EditBillingState) => state.billingState.editBilligDetails
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(0);

  var creditCardType = require("credit-card-type");

  React.useEffect(() => {
    dispatch(getBilling());
  }, []);

  function useForceUpdate() {
    return () => setValue((value) => value + 1);
  }
  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    if (update === true) {
      const expiryArray = editData?.expiry?.split("/");
      setEditData({
        ...editData,
        ...{ expiryMonth: expiryArray[0], expiryYear: expiryArray[1] },
      });
      dispatch(editBilling(editData));
      setUpdate(false);
    }
  }, [update]);

  React.useEffect(() => {
    if (editBillingState?.data?.success === true) {
      setSuccess(true);
      dispatch({ type: RESET_BILLINGEDIT });
      dispatch(getBilling());
      handleClose6();
    }
    if (editBillingState?.error) {
      toast.error(editBillingState?.error?.data?.message, {
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editBillingState]);

  const handleClose6 = () => {
    setShow6(false);
    simpleValidator.current.hideMessages();
    simpleValidator.current.hideMessageFor("name");
    simpleValidator.current.hideMessageFor("type");
    simpleValidator.current.hideMessageFor("cardNumber");
    simpleValidator.current.hideMessageFor("contactNumber");
    simpleValidator.current.hideMessageFor("expiry");
    simpleValidator.current.hideMessageFor("billingAddress");
  };
  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        regex: "Please Enter Valid Value",
      },
      validators: {
        billingaddress: {
          message: "Please Enter Valid Value",
          rule: (val: any, params: any): any => {
            const regex = new RegExp("^[A-Za-z0-9,]+$");
            return regex.test(val);
          },
        },
        creditcard: {
          message: "Please Enter Valid Value",
          rule: (val: any, params: any): any => {
            const regex = new RegExp("^[0-9●]");
            return regex.test(val);
          },
        },
      },
    })
  );
  const handleShow6 = () => {
    setSuccess(false);
    setEditData({
      ...editData,
      ...{
        name: billingState?.data?.name,
        cardNumber: "●●●●●●●●●" + billingState?.data?.card?.last4,
        type: billingState?.data?.card?.brand,
        billingAddress: billingState?.data?.billingAddress?.line1,
        contactNumber: billingState?.data?.contactNumber,
        expiry:
          billingState?.data?.card?.expMonth +
          "/" +
          billingState?.data?.card?.expYear,
        expiryMonth: billingState?.data?.card?.expMonth,
        expiryYear: billingState?.data?.card?.expYear,
      },
    });
    setShow6(true);
  };
  const updateValue = (event: any) => {
    const { name, value } = event.target;
    setEditData({ ...editData, [name]: value });
    simpleValidator.current.showMessageFor(name);

    if (name === "cardNumber") {
      setEditData({ ...editData, [name]: value });
      var visaCards = creditCardType(value);
      setEditData({
        ...editData,
        ...{ type: visaCards[0]?.niceType, number: value, cardNumber: value },
      });
    }
  };

  const updateAccount = (event: any) => {
    setValidated(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate();
    } else {
      var oldCard = `●●●●●●●●●${billingState?.data?.card?.last4}`;
      if (oldCard === editData.number) {
        setEditData({ ...editData, ...{ number: "" } });
      }
      setUpdate(true);
    }
  };

  function formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  function formatString(event: any) {
    var code = event.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    event.target.value = event.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        "0" // 0/ > 0 and 00 > 0
      )
      .replace(
        // eslint-disable-next-line no-useless-escape
        /[^\d\/]|^[\/]*$/g,
        "" // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        "/" // Prevent entering more than 1 `/`
      );
  }
  const stripePromise = loadStripe(AppConfig.stripePrimaryKey);
 
  return (
    <>
      <Modal show={show6} onHide={handleClose6} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Update Billing Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row className="gx-5 justify-content-center pt-5 pb-5 justify-content-center">
            <Col md={6}>
              <label htmlFor="name" className="learner-label">
                Name on Card
              </label>
              <br />
              <input
                type="text"
                id="name"
                name="name"
                value={editData?.name}
                onChange={(e) => {
                  updateValue(e);
                }}
                className="form-control mt-1 no-radious"
              />
              {simpleValidator.current.message(
                "name",
                editData.name,
                "required|max:100"
              )}
              <br />
            </Col>
            <Col md={6}>
              <label htmlFor="Practice Website" className="learner-label">
                Card Type
              </label>
              <br />
              <input
                type="text"
                id="type"
                name="type"
                style={{ pointerEvents: "none" }}
                value={editData?.type}
                disabled={true}
                onChange={(e) => {
                  updateValue(e);
                }}
                className="form-control mt-1 no-radious"
              />
              <br />
            </Col>
          </Row>
          <Row className="gx-5 justify-content-center pb-5 justify-content-center">
            <Col md={6}>
              <label htmlFor="cardNumber" className="learner-label">
                Card Number 
                <button type="button" onClick={()=>{handleStripeOpen()}} style={{textDecoration:'underline'}} className="video-link no-border no-bg" >
                 (Edit Credit Card)
                </button>   
              </label>
              <br />
              <input
                type="text"
                id="cardNumber"
                name="cardNumber"
                value={editData?.cardNumber}
                disabled={true}
                onChange={(e) => {
                  updateValue(e);
                }}
                className="form-control mt-1 no-radious"
              />
              <br />
            </Col>
            <Col md={6}>
              <label htmlFor="Practice Phone" className="learner-label">
                Expiry Date
              </label>
              <br />
              <input
                type="text"
                className="form-control mt-1 no-radious"
                maxLength={7}
                placeholder="MM/YYYY"
                name="expiry"
                disabled={true}

                onKeyUp={(e) => {
                  formatString(e);
                }}
                value={editData?.expiry}
                onChange={(e) => {
                  updateValue(e);
                }}
              />
             
              <br />
            </Col>
          </Row>
          <Row className="gx-5 justify-content-center pb-5 justify-content-center">
            <Col md={6}>
              <label htmlFor="billingAddress" className="learner-label">
                Billing Address
              </label>
              <br />
              <input
                type="text"
                id="billingAddress"
                name="billingAddress"
                value={editData?.billingAddress}
                onChange={(e) => {
                  updateValue(e);
                }}
                className="form-control mt-1 no-radious"
              />
              {simpleValidator.current.message(
                "billingAddress",
                editData.billingAddress,
                "required|max:100"
              )}
              <br />
            </Col>
            <Col md={6}>
              <label htmlFor="Practice Phone" className="learner-label">
                Contact Number
              </label>
              <br />

              <PhoneInput
                country={"us"}
                disableDropdown={true}
                inputProps={{
                  id: "contactNumber",
                  name: "contactNumber",
                  className: "form-control mt-1 no-radious",
                }}
                value={editData?.contactNumber}
                onChange={(e) =>
                  updateValue({
                    target: {
                      name: "contactNumber",
                      value: e,
                    },
                  })
                }
                countryCodeEditable={false}
              />
              {simpleValidator.current.message(
                "contactNumber",
                editData.contactNumber,
                "required|min:11"
              )}

              <br />
            </Col>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={6} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleClose6();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
          <Col md={6} xs={12} className="footer-section-2 text-center">
            <button
              className="assign-btn"
              onClick={(e) => {
                updateAccount(e);
              }}
            >
              {editBillingState.loading && (
                <Spinner className="loader" animation="border" />
              )}
              Save New Billing Details
            </button>
          </Col>
        </Row>
      </Modal>
      <Modal show={openStripe} onHide={handleStripeClose} size="xl">
        <Modal.Header className="model-heading-custom" closeButton>
          <Modal.Title>Update Credit Card</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model-body-custom">
          <Row className="gx-5 justify-content-center pt-5 pb-5 justify-content-center">
          <Container>
            { options?.clientSecret &&
            <Elements stripe={stripePromise} options={options}>
              <StripeIntegration handleStripeClose={handleStripeClose} setOptions={setOptions} options={options} />
            </Elements>
            }
          </Container>
          </Row>
        </Modal.Body>
        <Row className="footer-modal">
          <Col md={12} xs={12} className="footer-section-1 text-center">
            <button
              onClick={() => {
                handleStripeClose();
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Modal>

      <Col md={12} className="mt-5" id="billing-details">
        <div className="common-box">
          <div className="partition  practice-color">
            <Row>
              <Col md={6}>
                <DashboardNav/>
                <span className="common-text">Billing Details</span>
                <br />
              </Col>
              <Col md={6} className="text-right update-class">
                {success ? (
                  <>
                    <img src={Rightcheck} alt="right check" />
                    <span className="common-success-text">
                      &nbsp;&nbsp;Billing details were updated
                    </span>
                  </>
                ) : (
                  <></>
                )}
                <br />
              </Col>
            </Row>
          </div>
          {!billingState?.loading ? (
            <>
              <div className="partition">
                <Row className="gx-5 justify-content-center">
                  <Col md={5}>
                    <Title title="Name on Card" />
                    <br />
                    <Subtitle subtitle={billingState?.data?.name} />
                    <br />
                  </Col>
                  <Col md={4}>
                    <span className="common-title"></span>
                    <Title title="Card Type" />
                    <br />
                    <Subtitle capital={true} subtitle={billingState?.data?.card?.brand} />
                    <br />
                  </Col>
                </Row>
              </div>
              <div className="partition">
                <Row className="gx-5 justify-content-center">
                  <Col md={5}>
                    <Title title="Billing Address" />
                    <br />
                    <div style={{ width: "60%" }}>
                      <Subtitle
                        subtitle={`${
                          billingState?.data?.billingAddress?.line1
                            ? billingState?.data?.billingAddress?.line1
                            : "-"
                        }`}
                      />
                      <br />
                    </div>
                  </Col>
                  <Col md={4}>
                    <Title title="Card Number" />
                    <br />
                    <Subtitle
                     style={{textTransform: 'capitalize'}}
                      subtitle={`Ending with ` + billingState?.data?.card?.last4}
                    />
                    <br />
                  </Col>
                </Row>
              </div>
              <div className="partition">
                <Row className="gx-5 justify-content-center">
                  <Col md={5}>
                    <Title title="Contact Number" />
                    <br />
                    <Subtitle
                      subtitle={formatPhoneNumber(
                        billingState?.data?.contactNumber
                      )}
                    />
                    <br />
                  </Col>
                  <Col md={4}>
                    <Title title="Expiry Date" />
                    <br />
                    <Subtitle
                      subtitle={`${billingState?.data?.card?.expMonth} / ${billingState?.data?.card?.expYear}`}
                    />
                    <br />
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="mt-5 mb-5">
              <Grow />
            </div>
          )}
          <div className="partition-last mt-5 text-center update-btn-border">
            <button
              onClick={() => {
                handleShow6();
              }}
              className="update-practice-detail"
            >
              Update Billing Details
            </button>
          </div>
        </div>
      </Col>
    </>
  );
}

function StripeIntegration(prop:any) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disablebtn, setDisablebtn] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [billingInfo, setBillingInfo] = useState({
    address: "",
    suite: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    code: "",
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        not_regex: "Please Enter Valid Value",
      },
    })
  );

 
  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      setValidated(true);
      simpleValidator.current.showMessages();
    } else {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setDisablebtn(true);
      await stripe
        .confirmSetup({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: "https://app.alchemyvision.com/my-practice#billing-details",
          },
          redirect: "if_required",
        })
        .then((result) => {
          if (result && !Object.hasOwn(result, "error")) {
             dispatch(getBilling());
             setDisablebtn(false);
             prop.handleStripeClose()
        } else {
          toast.error(result?.error?.message, {
            position: "top-right",
          });
          setDisablebtn(false);
        }
      
        });
    }
  };
  
  return (
    <div className='pay'>
      <h2 className="text-center ">Let’s get your card info</h2>
      <div className="subscription-sub-text-box mb-5">
        <span className="subscription-sub-text">
          Help us ensure that we have the right information on your practice and
          our best point of contact
        </span>
      </div>
     <form onSubmit={handleSubmit}>
      <Row>
        <Col md={12}>
            <div id="stripe-integration">
                <PaymentElement />
              </div>
                <Row>
                  <Container className="small-container ">
                    <Row className="gx-5">
                      <Col md={12} className="mb-5" style={{textAlign:'right'}}>
                        <Button
                          onClick={(e) => handleSubmit(e)}
                          className="team-start-btn"
                          disabled={disablebtn}
                        >
                          {disablebtn && (
                            <Spinner className="loader" animation="border" />
                          )}
                          Onto the confirmation 👌🏽
                        </Button>
                      </Col>
                    </Row>
                  </Container>
              </Row>
        </Col>
      </Row>
      </form>
    </div>
  );
}


export default Billingdetails;
