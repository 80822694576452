import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Preview from "./Preview";
import { useNavigate } from "react-router-dom";
import Progress from "../common/Progress";
import { useLocation } from "react-router-dom";
import {
  GetRegisterDataState,
} from "../../types/Register.type";
import Choosedplan from "../widgets/Choosedplan";
import { useSelector } from "react-redux";

function Signup() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryParameters = new URLSearchParams(window.location.search);
  const signup = queryParameters.get("signup");
  const { state } = useLocation() as any;
  const navigate = useNavigate();
  const registerDataState: any = useSelector(
    (state: GetRegisterDataState) => state?.registerState.registerData
  );

  
  React.useEffect(() => {
    if (!state?.planid) {
      navigate("/");
    }
    if(state?.planid && registerDataState?.reset===true){
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);



  return (
    <div>
      <section className="signup-form-sec">
        <Container>
          <Progress step={1} />
        </Container>
        <Container className="small-container" id='from-part'>
           <Choosedplan planid={state?.planid} />
          <Preview signup={signup} planId={state?.planid} />
        </Container>
      </section>
    </div>
  );
}

export default Signup;
