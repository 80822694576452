import React from 'react'

function Alcamyforacademy(props:any) {
  return (
    // <section className="header-pending2" style={{marginTop:50}}>
    //     <div className="aao-heading-section" style={{justifyContent:'center',display:'flex'}}>
    //         <span className="aao-sub-text">{props.desc}</span><br/><br/>
    //     </div>
    //     <span className='start-price'>Special Academy member pricing starting at $79/mo.</span>
    // </section>
    <section className="header-pending2" style={{padding:15,marginTop:50}}>
        <div style={{justifyContent:'center',display:'flex'}}>
          <div className="i-pad-text2">
            {props?.title && 
              <>
              <span className="heading-aao">{props.title}</span><br/><br/>
              </>
            }
            <span className="aao-sub-text mt-2">{props.desc}</span><br/><br/>
            {props.title === '' && 
             <span className='start-price'>Special Academy member pricing starting at $79/mo.</span>
            }
             {/* <span className='heading-aao-sub-desc'>Our training curriculums  streamline new hire onboarding, develops COA-proficient technicians and scribes, and equips your team with the soft skills to provide excellence in patient experience.</span> */}
          </div>
        </div>
  </section>
  )
}

export default Alcamyforacademy