/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState} from "react";
import "./aoi.scss";
import { useDispatch, useSelector } from "react-redux";
import apiService from "../../services/api.service";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Card from "react-bootstrap/esm/Card";
import AaoLogo from "../../assets/images/pricing-header.svg"
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";
import Grow from "../loader/Grow";
import Alcamyforacademy from "../common/Alcamyforacademy";
import Faqs from "../common/Faqs";
import { GetPlanState } from "../../types/Plan.type";
import { getAAOPlans } from "../../store/plans/effect";
import { SinglePlan } from "../../types/Plan.type";
import Plan from "./Plan";
import Nolongterm from "../../assets/images/no-long-term.svg"
import Democall from "../common/Democall";

function Aoi() {

  const hash = window.location.hash;
  let token:any = new URLSearchParams(hash.substring(1)).get('access_token');
  const planState = useSelector((state: GetPlanState) => state.planState.aaoplans);

   React.useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tokenLoader,setTokenLoader] = useState(false)
  const [active1,set1Active] = useState(true)
  const [active2,set2Active] = useState(false)
  const [active3,set3Active] = useState(false)
  const [active4,set4Active] = useState(false)
  const [active5,set5Active] = useState(false)
  
  React.useEffect(() => {
    if(!token){
      let accessToken = JSON.parse(localStorage?.getItem("accessToken") as string);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      token = accessToken?.token;
    }else{
      localStorage.setItem('accessToken',JSON.stringify({token:token}));
    }
    apiService.aaoVerify(token).then(({ data, ...rest })=>{
      setTokenLoader(false)
      }).catch(({response})=>{
        const {data} = response
        if(data?.auth===false){
          navigate('/')
        }
    })
    dispatch(getAAOPlans(1,token));

  }, [dispatch]);



  function active(number:any) {
      if(number===1){    
        set1Active(true)
        set2Active(false)
        set3Active(false)
        set4Active(false)
        set5Active(false)
      }
      if(number===2){
        set2Active(true)
        set1Active(false)
        set3Active(false)
        set4Active(false)
        set5Active(false)
      }
      if(number===3){    
        set2Active(false)
        set1Active(false)
        set3Active(true)
        set4Active(false)
        set5Active(false)
      }
      if(number===4){
        set2Active(false)
        set1Active(false)
        set3Active(false)
        set4Active(true)
        set5Active(false)
      }
      if(number===5){
        set2Active(false)
        set1Active(false)
        set3Active(false)
        set4Active(false)
        set5Active(true)
      }
  }  React.useEffect(() => {
      const script = document.createElement('script');
  
      script.src = "https://webforms.pipedrive.com/f/loader";
  
      script.async = true;
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      }
    }, []);

  return (
    tokenLoader===false ?
    <div className="home-dashboard-aao">
      <Container fluid="xxl" >
          <section className="header-pending2 first-section" style={{padding:15}}>
            <Row>
              <Col md={12} className="av-logo" style={{borderRight:0}}>
                <img src={AaoLogo} alt='Aao-logo' width='100%' />
              </Col>
            </Row>
          </section>
          <Alcamyforacademy title='Exclusive Academy Pricing' desc='Academy, AAOE®, and AAOP™ members receive exclusive access to Alchemy Vision’s premier staff training at special member-only rates. Choose the number of licenses that fits your team’s needs.' />
      </Container>
      
      <Container className="small-container">
          <div className="plans-aao row gx-4">
              {
                !planState?.loading ?
                planState?.data?.map((plan:SinglePlan,index:number)=>(
                    <Plan key={index} plan={plan} />
                  )
                )
                :
                <Grow />
              }
          </div>
          <div style={{textAlign:'center'}} className="mt-5">
            <span className="no-long-term "><img src={Nolongterm} alt='no-long-term' /> No long-term commitment required</span>
          </div>
          <Row>
              <Card className="enter-price-card" style={{background:'#ECECEC',padding:0}}>
                <Card.Body style={{padding:35}}>
                  <Row style={{background:'#F2F6FC',padding:20}}>
                    <Col md={8}>
                      <Card.Title className="text-left plan-name-enterprise mt-2 ">
                        ENTERPRISE
                      </Card.Title>
                      <Card.Text className="text-left detail-text-2" style={{color:'#3B6494',fontSize:18}}>
                      Book a demo to learn more about enterprise level<br/> training, support, and other customizable options.
                      </Card.Text>
                    </Col>
                    <Col md={4} className="contact-button">
                      <Button
                        variant="primary"
                        className="contact-sale-btn-pricing"
                        onClick={()=>{}}
                      >
                       Book a Demo
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              </Row>
        <Faqs from='aoi' />
        <div className="faq">
              <div className="mt-5 mb-5 subscription-sub-text-box">
                  <span className="subscription-sub-text-title-includes" style={{fontFamily:'Space Mono !important'}}>
                  Commonly asked questions:
                  </span>
              </div>
              <div className="row gx-0">
              <div className="col-md-6">
                  <div className="col-md-12 que" style={{background:active1===true ? `#EAE3EF` : ``,fontWeight:active1===true ? `bold` : ``}} onClick={()=>{active(1)}}>Can this integrate with my existing training program?</div>
                  <div className="col-md-12 que" style={{background:active2===true ? `#EAE3EF` : ``,fontWeight:active2===true ? `bold` : ``}}  onClick={()=>{active(2)}}>How long does it take to complete the training?</div>
                  <div className="col-md-12 que" style={{background:active3===true ? `#EAE3EF` : ``,fontWeight:active3===true ? `bold` : ``}}  onClick={()=>{active(3)}}>How do I manage my subscription and team?</div>
                  <div className="col-md-12 que" style={{background:active4===true ? `#EAE3EF` : ``,fontWeight:active4===true ? `bold` : ``}}  onClick={()=>{active(4)}}>How do you measure staff engagement and success?</div>
                  <div className="col-md-12 que" style={{background:active5===true ? `#EAE3EF` : ``,fontWeight:active5===true ? `bold` : ``}}  onClick={()=>{active(5)}}>Do you offer implementation support?</div>
              </div>
              <div className="col-md-6" style={{background:'#EAE3EF',padding:20}}>
                  <div className="col-md-12 ans">{active1===true && `This turnkey staff training solution includes built-in tools designed to enhance your existing program.`}</div>
                  <div className="col-md-12 ans">{active2===true && `Completion times vary based on experience and time commitment. You'll start seeing value within the first week, with ongoing development over time.`}</div>
                  <div className="col-md-12 ans">{active3===true && `You can manage everything from your Leader Dashboard including: license assignment, update billing, review reports and analytics, etc.`}</div>
                  <div className="col-md-12 ans">{active4===true && `Reporting and analytic tools are provided  to oversee individual progress.  Leaderboards help motivate staff, while knowledge checks and exams reinforce learning. Automated reminders help keep employees on schedule.`}</div>
                  <div className="col-md-12 ans">{active5===true && `Alchemy Vision collaborates with the Academy to review, approve, and develop content that is current and relevant to today's patient needs.`}</div>
              </div>
              </div>
        </div>
        <br/><br/>
      </Container>
      <Container fluid="xxl" className="mt-5">
       <Democall />
      </Container>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
    :
    <div style={{height:'500px  ',lineHeight:'500px',verticalAlign:'middle',display:'flex'}}>
     <Grow />
    </div>
  );
}

export default Aoi;
