import React,{ useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Plan from "./Plan";
// import Beliving from "./Beliving";
import { useDispatch, useSelector } from "react-redux";
import { getPlans } from "../../store/plans/effect";
import { getFaq } from "../../store/faqs/effect";
import { SinglePlan, GetPlanState } from "../../types/Plan.type";
import {  GetFaqState } from "../../types/Faq.type";
import Grow from "../loader/Grow";
import Toast from "../toasts/Toast";
import { toast } from "react-toastify";
import { REGISTER_DATA_RESET } from "../../store/register/actions";
import { useNavigate } from "react-router-dom";
import { Includedata } from "./Includedata";
import { useLocation } from 'react-router-dom';
import Testimonials from "../common/Testimonials";
import Faqs from '../common/Faqs';

function Welcome() {
  const dispatch = useDispatch();
  const planState = useSelector((state: GetPlanState) => state.planState.plans);
  const faqState = useSelector((state: GetFaqState) => state.faqState.faqs);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [include,setInclude] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [exist,setExist] = useState(true);
  let location = useLocation();
  
  const contact = () => {
    navigate('/contact')
  }

  React.useEffect(() => {
    localStorage?.removeItem('accessToken')
    const checkTypewriter = document.querySelector('.header-pending')
    if (!checkTypewriter) {
      setExist(false)
    }
    dispatch(getPlans(1));
    dispatch(getFaq());
    if(location.search!=="?changequantity=true"){
      dispatch({
        type: REGISTER_DATA_RESET
      });
    }
    // dispatch(getPlanFeature());
    setInclude(Includedata)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    if (planState?.error) {
      if(planState?.error?.data?.message){
      toast.error(planState?.error?.data?.message, {
        position: "top-right",
      });
      }
    }
  }, [planState.error]);
  React.useEffect(() => {
    if (faqState?.error) {
      if(faqState?.error?.data?.message){
      toast.error(faqState?.error?.data?.message, {
        position: "top-right",
      });
      }
    }
  }, [faqState.error]);

  // const planRedireact = () => {
  //   if(exist===true){
  //   navigate('/plans')
  //   }else{
  //   navigate('/planswithoutheader')
  //   }
  // }

  return (
    <div className="welcome">
      <div>
        <Toast />
        <section className="subscription">
          <Container className="small-container">
            <div className="subscription-sub-text-box">
              <span className="subscription-sub-text-title">
                Fast, Effective Training for Your Entire Team—Elevate Patient Care, and Drive Results
              </span>
            </div>
            <div className="plans-home row gx-4">
              {
                !planState?.loading ?
                planState?.data?.map((plan:SinglePlan,index:number)=>(
                    <Plan key={index} plan={plan} />
                  )
                )
                :
                <Grow />
              }
            </div>
          </Container>
        </section>
        <section className="whats-include">
           <Container className="small-container">
            <Row>
              <Card className="enter-price-card">
                <Card.Body>
                  <Row>
                    <Col md={3}>
                      <div className="plan-icon-div">
                        {/* <img src={Enterprise} alt="enterprise" /> */}
                      </div>
                      <Card.Title className="text-center plan-name-enterprise mt-2 ">
                        ENTERPRISE
                      </Card.Title>
                    </Col>
                    <Col md={5} className="contact-text">
                      <Card.Text className="text-left detail-text-2">
                        <b style={{color:'#1B4332'}}>We support practices of all sizes.</b>
                        <br />
                        If you have over 26 learners, contact us 
                        for enterprise pricing, support, and
                        other customizable options.
                      </Card.Text>
                    </Col>
                    <Col md={4} className="contact-button">
                      <Button
                        variant="primary"
                        className="contact-sale-btn"
                        onClick={()=>contact()}
                      >
                        Contact Sales
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              </Row>
              <div>
               <Faqs/>
              </div>
             <div className="text-center mt-4">
                <button className="faq-cta">FAQs</button>
             </div>
              <Testimonials />
          </Container>
        </section>
      </div>
    </div>
  );
}

export default Welcome;
