import React,{ useState } from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import 'reactjs-popup/dist/index.css';
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function Plan({ plan }: { plan: any }) {
  const [exist, setExist] = useState(true);

   React.useEffect(() => {
      const checkTypewriter = document.querySelector(".header-pending");
      if (!checkTypewriter) {
        setExist(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const navigate = useNavigate();
  
  const planRedirect = (id:any) =>{
      if(exist===true){
        navigate('/signup', { state: {planid:id} })
      }else{
        navigate('/signupwithoutheader', { state: {planid:id} })
      }
  }

  return (
      <Col md={4} sm={12} className='mobile-margin-bottom'>
        <Card className="plan-card">
          <div style={{background:'#F2F6FC'}} className='c-body pt-3 pb-4'>
            <div style={{padding:'0px 20px 0px 20px',minHeight:'243px'}}>
              <img src={plan?.icon} alt="plan" height='50px' width='50px'  />
                <h2 className='charge-sub custome-margin' >{plan?.title}</h2>
                <h4 className='charge-secondorytext mt-4'>{plan?.text}</h4>
              </div>
            <div className="text-left custome-margin  charge-padding">
                  <span className='plan-price' >${plan?.displayPrice}</span><span className='price-simple-text' > per month</span>
            </div>
            <div  className="mt-4  charge-padding">
              <Button  onClick={()=>{ planRedirect(plan.id)} } className="get-started-button">Get started</Button>
            </div>
          </div>
        </Card>
    </Col>
  );

  

}

export default Plan;
