/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./Mypractice.scss";
import Billingdetails from "./Billingdetails";
import Managesubscription from "./Managesubscription";
import Subscriptioninvoices from "./Subscriptioninvoices";
import {
  GetPracticetState,
} from "../../types/Practice.type";
import { getSubscriptionType } from "@store/subscription/effect";
import { useDispatch,useSelector  } from "react-redux";

function Mypractice() {
  const dispatch = useDispatch();
  const practiceState = useSelector(
    (state: GetPracticetState) => state.practiceState.practice
  );

  React.useEffect(()=>{
      const str = window.location.href;
      const after_ = str.slice(str.indexOf("#") + 1);
      var elem = document.getElementById(after_);
      elem && elem.scrollIntoView();
  },[practiceState?.data])

  const subscriptionTypeState = useSelector(
    (state: any) => state.subscriptionState?.subscriptionType
  );

  React.useEffect(() => {
    dispatch(getSubscriptionType());
  }, [dispatch]);

  return (
    <div className="my-practice">
      <section className="section-practice">
        <Container>
          <Row className="mt-5">
            <Managesubscription />
            { !subscriptionTypeState?.isTrial && <Billingdetails /> }
            <Subscriptioninvoices />
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Mypractice;
