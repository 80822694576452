import React from 'react'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

function Democall() {
  return (
    <>
    <Row className='justify-content-center'>
    <Col md={8} style={{textAlign:'center'}} className="mb-5 demo-call-description">
      <h3 className="heading-aao">Sometimes seeing is believing</h3>
      <span className="plan-description-desc">Schedule a demo call with us so we can walk you through the benefits and results you can expect with  the Academy Technician Training Platform.</span>
    </Col>
    </Row>
    <Row>
    <Col md={12} style={{textAlign:'center'}}>
    <div className="form-aao">
        <div className="pipedriveWebForms" style={{width:'100%'}} data-pd-webforms="https://webforms.pipedrive.com/f/6qj27Jigg2RTyggeiHqm1Ycww9v20T1Jb7MF8g8uzZpZiPyfqNJQXexaMeMY4rdJZN"></div>
      </div>
    </Col>
  </Row>
  </>
  )
}

export default Democall