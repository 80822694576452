import React from "react";
import MainHeader from "../aaocommon/header"
import { Outlet } from "react-router-dom";
import "./style.scss";
import { Navigate } from "react-router-dom";
import MainFooter from "../aaocommon/footer";

function Aaolayout() {
  return !localStorage?.getItem("profile") ? 
   <div>
    <MainHeader></MainHeader>
    <Outlet />
    <MainFooter></MainFooter>
  </div>
  :
  <Navigate to='/dashboard' />
}

export default Aaolayout;
