import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Checktrue from "../../assets/images/check-true.svg";
import Checktrue2 from "../../assets/images/checktrue.svg";

function Faqs({from}:any) {
  return (
    <div>
    <div className="mt-5 mb-5 subscription-sub-text-box">
        <span className="subscription-sub-text-title-includes" style={{fontFamily:'Space Mono !important'}}>
            Your Alchemy Vision subscription includes:
        </span>
    </div>
    <Row>
        <Col md={6} className="stripe-row" style={{display:'flex'}}><div style={{marginRight:15}}><img src={from==='' ? Checktrue : Checktrue2 } alt='Checktrue' /></div>270+ visually engaging  courses</Col>
        <Col md={6} className="stripe-row" style={{display:'flex'}}><div style={{marginRight:15}}><img src={from==='' ? Checktrue : Checktrue2 } alt='Checktrue' /></div> Dedicated dashboard for effortless team and subscription management</Col>
        <Col md={6} className="no-stripe-row" style={{display:'flex'}}><div style={{marginRight:15}}><img src={from==='' ? Checktrue : Checktrue2 } alt='Checktrue' /></div>Observation checklists to verify skill competency</Col>
        <Col md={6} className="no-stripe-row" style={{display:'flex'}}><div style={{marginRight:15}}><img src={from==='' ? Checktrue : Checktrue2 } alt='Checktrue' /></div>Printable and downloadable study resources</Col>
        <Col md={6} className="stripe-row" style={{display:'flex'}}><div style={{marginRight:15}}><img src={from==='' ? Checktrue : Checktrue2 } alt='Checktrue' /></div>Create and upload your own videos and clinic-specific workflows</Col>
        <Col md={6} className="stripe-row" style={{display:'flex'}}><div style={{marginRight:15}}><img src={from==='' ? Checktrue : Checktrue2 } alt='Checktrue' /></div>Leaderboards to engage and reward your staff</Col>
        <Col md={6} className="no-stripe-row" style={{display:'flex'}}><div style={{marginRight:15}}><img src={from==='' ? Checktrue : Checktrue2 } alt='Checktrue' /></div>Multiple knowledge checkpoints & final exams</Col>
        <Col md={6} className="no-stripe-row" style={{display:'flex'}}><div style={{marginRight:15}}><img src={from==='' ? Checktrue : Checktrue2 } alt='Checktrue' /></div>Flexible Licenses—reassign for turnover and training completion</Col>
        <Col md={6} className="stripe-row" style={{display:'flex'}}><div style={{marginRight:15}}><img src={from==='' ? Checktrue : Checktrue2 } alt='Checktrue' /></div>Course assignments for learning reinforcement</Col>
        <Col md={6} className="stripe-row" style={{display:'flex'}}><div style={{marginRight:15}}><img src={from==='' ? Checktrue : Checktrue2 } alt='Checktrue' /></div>Real-time reports and analytics for data-driven decisions</Col>
    </Row>
    </div>
  )
}

export default Faqs