import MainHeader from "../common/headernew";
// import MainFooter from "../common/footer";
import { Outlet } from "react-router-dom";
import "./style.scss";
import { Navigate } from "react-router-dom";

function MainLayout() {
  // return (
  //   <div>
  //     <MainHeader></MainHeader>
  //     <Outlet />
  //     <MainFooter></MainFooter>
  //   </div>
  // );

  return !localStorage?.getItem("profile") ? 
   <div>
    <MainHeader></MainHeader>
    <Outlet />
    {/* <MainFooter></MainFooter> */}
  </div>
  :
  <Navigate to='/dashboard' />
}

export default MainLayout;
