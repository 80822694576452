import React from 'react'
import StepProgressBar from 'react-step-progress';
// import the stylesheet
import 'react-step-progress/dist/index.css';

const Progress = ({step}:any) => {
  return (
    <StepProgressBar
        startingStep={step}
        progressClass='change-color'
        onSubmit={()=>{}}
        steps={[
        {
            label: 'License selection',
            subtitle: '',
            name: 'step 1',
            content: ''
        },
        {
            label: 'Practice information',
            subtitle: '',
            name: 'step 2',
            content: '',
        },
        {
            label: 'Billing information',
            subtitle: '',
            name: 'step 3',
            content: '',
        },
        {
            label: 'Subscription confirmation',
            subtitle: '',
            name: 'step 4',
            content: '',
        },
        ]}
        />
  )
}

export default Progress