import React from "react";
import Container from "react-bootstrap/Container";
import "./Myaccount.scss";
import Myaccountbox from "./Myaccountbox";

function Mypractice() {
  return (
    <div className="my-account">
      <section className="section-practice">
        <Container>
          <Myaccountbox />
        </Container>
      </section>
    </div>
  );
}

export default Mypractice;
