/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState} from "react";
import "./aoi.scss";
import { useDispatch } from "react-redux";
import apiService from "../../services/api.service";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Card from "react-bootstrap/esm/Card";
import iPad from "../../assets/images/iPad-Zhu-Image.png";
import Essential1 from '../../assets/images/Essential-In-Use-1.png';
import Maskgroup from '../../assets/images/Maskgroup4.svg';
import Maskgroup2 from '../../assets/images/Maskgroup2.png';
import Ipad from '../../assets/images/ipad.svg';
import Substract from "../../assets/images/Subtract.png";
import Testimonials from "../common/Testimonials";
import Democall from "../common/Democall";
import Alcamyforacademy from "../common/Alcamyforacademy";
import slider from "../../assets/images/slider.svg";
import Attplockup from "../../assets/images/ATTPLockup.svg";

function Aoi() {

  const hash = window.location.hash;
  const token = new URLSearchParams(hash.substring(1)).get('access_token');
  const dispatch = useDispatch();
  const [tokenLoader,setTokenLoader] = useState(true)
  React.useEffect(() => {
    apiService.aaoVerifyPricing(token).then(({ data, ...rest })=>{
      localStorage.setItem('accessToken',JSON.stringify({token:token}));
      setTokenLoader(false)
      }).catch(({response})=>{
      setTokenLoader(true)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function seePricing() {
    if(tokenLoader===true){
      window.open("https://www.aao.org/", "_blank")
    }else{
      window.open(`/academypricing#access_token=`+token, "_blank")
    }
  }
  
  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://webforms.pipedrive.com/f/loader";

    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);


  return (
    <div className="home-dashboard-aao">
        <Container fluid="xxl" >
        <br/>
            <section className="heade5r-pending2 first-section" style={{backgroundRepeat:'no-repeat'}}>
                <img src={slider} alt="slider" width={`100%`} />
            </section>
            <section className="header-pending2 mt-5" style={{textAlign:'center'}}>
                <img src={Attplockup} alt="slider" className="slider-img" />
            </section>
            <Alcamyforacademy title='' desc='The American Academy of Ophthalmology and Alchemy Vision introduce a powerful training solution to accelerate clinical team performance and improve patient care. Developed in collaboration with the American Academy of Ophthalmic Professionals™ (AAOP™), this robust, video-based platform accelerates new hire training and helps practices boost productivity.' />
            <div style={{textAlign:'center'}}>
            <button onClick={()=>seePricing()} className="btn mt-4 see-pricing2" >See Pricing</button><br/><br/>
            <small className="small-redirect">You’ll be redirected to the Academy<br/> website for member authentication</small>
            </div>
            <section className="mt-5" style={{justifyContent:'center',display:'flex'}}>
                <img src={iPad} alt="ipad" className="ipad-image" />
            </section>
        </Container>

        <div className="bg-under-image">

        </div>
        <Container fluid="xxl" >
        <section className="header-pending2 mb-5" id='solutions' style={{padding:15}}>
              <div style={{justifyContent:'center',textAlign:'center',display:'flex'}}>
                <div className="i-pad-text">
                  <h4 className="heading-aao-sub">Improve patient care and revenue by fast-tracking new hire productivity</h4>
                  <span className='heading-aao-sub-desc'>Our platform trains your eye care staff so they can build skills with minimal oversight, so they can bring solutions to multiple pain points that so many practices experience today. Solutions such as...</span>
                </div>
              </div>
        </section>
        <section className="header-pending2 learning-paths" style={{padding:15}}>
            <Row>
                <Col sm={12} lg={4} className='mobile-margin-bottom mb-2'>
                    <Card className="plan-card desc-bg" style={{padding:'20px 50px 20px 40px'}}>
                      <h4 className="plan-text-color plan-heading">Improving<br/>Patient Flow</h4>
                      <p className="plan-text-color plan-sub-text">Quick and relevant training ensures patients are seen promptly, reducing bottlenecks and improving clinic flow and efficiency.</p>
                    </Card>
                </Col>
                <Col sm={12} lg={4} className="mobile-margin-bottom mb-2">
                    <Card className="plan-card desc-bg " style={{padding:'20px 50px 20px 40px'}}>
                      <h4 className="plan-text-color plan-heading">Decreasing<br/>Staff Turnover</h4>
                      <p className="plan-text-color plan-sub-text">Give your staff training that aligns with their professional development goals, demonstrating your commitment to their growth.</p>
                    </Card>
                </Col>
                <Col sm={12} lg={4}>
                    <Card className="plan-card desc-bg" style={{padding:'20px 50px 20px 40px'}}>
                      <h4 className="plan-text-color plan-heading">Enhancing<br/>Patient Care</h4>
                      <p className="plan-text-color plan-sub-text">Foster a consistent and efficient environment where a motivated, well-trained team is eager to provide timely attention and care for patients.</p>
                    </Card>
                </Col>
            </Row>
        </section>
        <section className="header-pending2" id='how-it-works' style={{padding:15,marginTop:50}}>
              <div style={{justifyContent:'center',display:'flex'}}>
                <div className="i-pad-text2">
                  <span className='heading-aao-sub-desc'>Our training curriculums  streamline new hire onboarding, develops COA-proficient technicians and scribes, and equips your team with the soft skills to provide excellence in patient experience.</span>
                </div>
              </div>
        </section>
        <section className="plan-description mt-5">
            <Row style={{padding:20,position:'relative'}}>
              <Col sm={12} lg={6} className="mobile-margin-bottom">
                  <div className="description-text-aao">
                    <h4 className="plan-description-heading mb-4">New Hire Onboarding</h4>
                    <p className="plan-description-desc">A turnkey solution to accelerate new hire training. These courses provide structured, timed education and resources to boost confidence and efficiency from day one, ensuring new employees feel prepared and engaged in their roles.</p>
                    <span className="plan-description-point">- Introductory ophthalmology education </span><br/>
                    <span className="plan-description-point">- Foundational clinical skills training</span><br/>
                    <span className="plan-description-point">- Essential diagnostic equipment training</span><br/>
                  </div>
                  <button className="btn  see-pricing" onClick={()=>{seePricing()}}>See Pricing</button>
              </Col>
              <Col sm={12} lg={6} className="text-end">
                  <img src={Essential1} alt="Essential" width='80%' />
              </Col>
            </Row>  
            <Row style={{padding:20,position:'relative'}}>
              <Col sm={12} lg={6} className="mobile-margin-bottom">
                  <div className="description-text-aao">
                    <h4 className="plan-description-heading mb-4">Technicians & Scribes</h4>
                    <p className="plan-description-desc">Empower your eye care team with COA-level training to close skill gaps and elevate technicians and scribes to their full potential.</p>
                    <span className="plan-description-point">- Advanced ophthalmology education </span><br/>
                    <span className="plan-description-point">- Comprehensive COA-level curriculum</span><br/>
                    <span className="plan-description-point">- CE accredited courses</span><br/>
                    <span className="plan-description-point">- Certification prep courses</span><br/>
                  </div>
                  <button className="btn  see-pricing" onClick={()=>{seePricing()}}>See Pricing</button>
              </Col>
              <Col sm={12} lg={6} className="text-end">
                  <img src={Maskgroup2} alt="Essential" width='80%' />
              </Col>
            </Row>
            <Row style={{padding:20,position:'relative'}}>
              <Col sm={12} lg={6} className="mobile-margin-bottom">
                  <div className="description-text-aao">
                    <h4 className="plan-description-heading mb-4">Patient Experience</h4>
                    <p className="plan-description-desc">Elevate patient care by equipping your team with the soft skills needed to deliver compassionate, personalized support throughout the patient journey.</p>
                    <span className="plan-description-point">- Empathy and compassion training</span><br/>
                    <span className="plan-description-point">- Active listening and rapport building to foster trust and understanding</span><br/>
                    <span className="plan-description-point">- Communication techniques</span><br/>
                  </div>
                  <button className="btn  see-pricing" onClick={()=>{seePricing()}} >See Pricing</button>
              </Col>
              <Col sm={12} lg={6} className="text-end">
                  <img src={Maskgroup} alt="Essential" width='80%' />
              </Col>
            </Row>
            <Row style={{padding:20}}>
              <Col sm={12} lg={6} className="ch-order">
                  <img src={Ipad} alt="ipad" width='80%' />
              </Col>
              <Col sm={12} lg={6} className="ch-order2">
                  <div className="state-team-mng">
                    <h4 className="plan-description-heading mb-4">Stats + Team Management</h4>
                    <p className="plan-description-desc"><img src={Substract} width={23} alt="substract" />  Seamlessly manage your team and account directly from your Leader Dashboard</p>
                    <p className="plan-description-desc"><img src={Substract} width={23} alt="substract" />  Gain insights through comprehensive reports and analytics, empowering you to make data-driven decisions that enhance team performance and drive ROI</p>
                    <p className="plan-description-desc"><img src={Substract} width={23} alt="substract" />  Track your employee engagement with leaderboards, motivating your team through interactive challenges and rewards</p>
                  </div>
              </Col>
            </Row>
        </section>
        
        </Container>

        <Container fluid="xxl small-container" className="carasoul-container" >
          <Testimonials />
        </Container>

        <Container fluid="xxl mt-8" >
          <Democall />
        </Container>
        <br/>
        <br/>
        <br/>
        <br/>
    </div>
  );
}

export default Aoi;
