/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./header.scss";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
// import apiService from "@services/api.service";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect,useState } from "react";
import Avxaao from "../../../assets/images/avxaao.svg";

function MainHeader() {
  const location = useLocation();
  const [tokenStore,setTokenStore] = useState();
  let accessToken:any;
  useEffect(()=>{
      // eslint-disable-next-line react-hooks/exhaustive-deps
      accessToken = JSON.parse(localStorage?.getItem("accessToken") as string);
      setTokenStore(accessToken?.token)
  },[])
  // const navigateToLogin = () => {
  //   apiService.login().then((resp) => {
  //     if (resp?.data?.url) {
  //       window.open(resp?.data?.url, "_blank");
  //     }
  //   }).catch((error) => {
  //     console.error('error: ', error);
  //   })
  // }

  const scroll = (url:any) => {
      var elem = document?.getElementById(url);
      elem && elem.scrollIntoView();
  }
  function redirectPricing(redirection:any) {
    window.open(`${redirection}#access_token=`+tokenStore, "_blank")
  }
  function redirectAAO() {
    window.open("https://www.aao.org/", "_blank")
  }

  return (
    <Navbar collapseOnSelect expand="sm" className="aao-colored-navbar">
      <Container fluid="xxl" className="header-pending mt-3">
        {
          tokenStore ? 
          <Link to= {'/academy#access_token='+tokenStore} >
            <img src={Avxaao} alt="avlogo" style={{height:'50px',width: 'auto' }} />
          </Link>
          :
          <Link to= {'/academy'} >
            <img src={Avxaao} alt="avlogo" style={{height:'50px',width: 'auto' }} />
          </Link>
        }
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="menu-main">
          <Nav>
            <a onClick={()=>{scroll('solutions')}} className={`${location.pathname === '/Academy' || location.pathname === '/academy'  ? '' : ''} nav-link aao-nav-text`} style={{cursor:'pointer',textDecoration:'none',paddingRight:20,paddingLeft:30}} >
              <div className={`${location.pathname === '/' ? '' : ''} aao-nav-text`}>Solutions</div>
            </a >
            <span onClick={()=>{scroll('how-it-works')}} className={`${location.pathname === '/'  ? '' : ''} nav-link aao-nav-text`}  style={{cursor:'pointer',textDecoration:'none',paddingRight:20,paddingLeft:30}} >
              <div className={`${location.pathname === '/' ? '' : ''} aao-nav-text`}>How It Works</div>
            </span >
            {
              tokenStore ? 
            
            <span onClick={()=>{redirectPricing('/academypricing')}} className={`${location.pathname === '/academypricing' || location.pathname === '/academy'  ? '' : ''} nav-link aao-nav-text`} style={{cursor:'pointer',textDecoration:'none',paddingRight:20,paddingLeft:30}} >
              <div className={`${location.pathname === '/' ? '' : ''} aao-nav-text`}>Pricing</div>
            </span >
            :
            <span onClick={()=>{redirectAAO()}} className={`${location.pathname === '/academypricing' || location.pathname === '/academy'  ? '' : ''} nav-link aao-nav-text`} style={{cursor:'pointer',textDecoration:'none',paddingRight:20,paddingLeft:30}} >
            <div className={`${location.pathname === '/' ? '' : ''} aao-nav-text`}>Pricing</div>
          </span >
          
          }
            <span onClick={()=>{redirectAAO()}} className={`${location.pathname === '/'  ? '' : ''} nav-link aao-nav-text`}  style={{cursor:'pointer',textDecoration:'none',paddingRight:20,paddingLeft:30}} >
              <div className={`${location.pathname === '/' ? '' : ''} aao-nav-text`}>Back to aao.org</div>
            </span >
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainHeader;
