import http from "./http-common";
import { AppConfig } from "../configs/app.config";
import { RegisterForm } from "../types/Register.type";
import { SingleLicenceAllocation } from "../types/licenceallocation.type";
import { Adminlogin } from "../types/User.type";

class ApiService {
  options = {
    headers: {
      Authorization: ''
    }
  }

  profile: any = null;
  thankyouemail:any = null;

  constructor() {
    if (localStorage.getItem('auth')) {
      const auth = JSON.parse(localStorage.getItem('auth') || '');
      this.options.headers.Authorization = `Bearer ${auth?.idToken}`;
    }

    if (localStorage.getItem('profile')) {
      this.profile = JSON.parse(localStorage.getItem("profile") || "")
    }
  }

  login() {
    return http.get(`${AppConfig.apiBaseURL}/auth/login`);
  }

  logout() {
    return http.get(`${AppConfig.apiBaseURL}/auth/logout`);
  }

  authlogout(){
    return http.get(`${AppConfig.apiBaseURL}/auth/logout/url`);
  }

  acquireToken(token: string) {
    return http.get(
      `${AppConfig.apiBaseURL}/auth/profile/details`,{headers: {
        Authorization: `Bearer `+token
      }}  
    );
  }

  getPlans(pageId: number) {
    return http.get(`${AppConfig.apiBaseURL}/plans/0`);
  }
  
  getAAOPlans(pageId: number,token:string) {
    return http.get(`${AppConfig.apiBaseURL}/plans/private/1`,{headers: {
      Authorization:token
    }});
  }

  collectSaml(token:any) {
    return http.get(`${AppConfig.apiBaseURL}/auth/saml/response`,{headers: {
      Authorization: `Bearer `+token
    }});
  }

  getPlanSecret(
    selectedPlanObj: any,
    couponCode: String,
    medicalPracticeId: number
  ) {
    const request = {
      plans: selectedPlanObj,
      couponCode: couponCode,
      medicalPracticeId: medicalPracticeId,
    };
    return http.post(`${AppConfig.apiBaseURL}/payment/intent/create`, request);
  }

  getFaq() {
    return http.get(`${AppConfig.apiBaseURL}/faqs/all`);
  }

  register(registerData: RegisterForm) {
    return http.post(
      `${AppConfig.apiBaseURL}/subscription/verify-and-setup`,
      registerData  
    );
  }
  //done
  adminLogin(loginData: Adminlogin) {
    return http.post(`${AppConfig.apiBaseURL}/profile/login`, loginData);
  }

  getCoupons() {
    return http.get(`${AppConfig.apiBaseURL}/coupon/all`);
  }

  applyCoupons(code: String, planId: any) {
    const request = {
      planTemplateId: planId,
      code:code
    };
    return http.post(`${AppConfig.apiBaseURL}/coupon/apply`, request);
  }

  createPayment(payload: any) {
    return http.post(`${AppConfig.apiBaseURL}/subscription/create`, payload);
  }

  createPayment2(payload: any) {
    return http.post(`${AppConfig.apiBaseURL}/migration`, payload);
  }

  applyLicenceAllocation(applyLicense: SingleLicenceAllocation,leader:any) {
    applyLicense.roleId = leader;
    return http.post(`${AppConfig.apiBaseURL}/profile/invite`, applyLicense, this.options);
  }

  getLicence() {
    return http.get(
      `${AppConfig.apiBaseURL}/medical-practice/licenses`, this.options
    );
  }

  getAccount() {
    if (localStorage.getItem('auth')) {
      const auth = JSON.parse(localStorage.getItem('auth') || '');;
      this.options.headers.Authorization = `Bearer ${auth?.idToken}`;
    };

    if (localStorage.getItem('profile')) {
      this.profile = JSON.parse(localStorage.getItem("profile") || "")
    };
    
    return http.get(`${AppConfig.apiBaseURL}/profile`, this.options);
  }

  updateAccount(payload: any) {
    return http.put(
      `${AppConfig.apiBaseURL}/profile`, payload, this.options
    );
  }

  getMedicalPractice() {
    return http.get(
      `${AppConfig.apiBaseURL}/medical-practice/`, this.options
    );
  }

  editMedicalPractice(practiceData: any) {
    return http.put(
      `${AppConfig.apiBaseURL}/medical-practice`,
      practiceData,this.options
    );
  }

  getsubscription() {
    return http.get(
      `${AppConfig.apiBaseURL}/subscription`, this.options
    );
  }

  deletesubscription() {
    return http.delete(
      `${AppConfig.apiBaseURL}/subscription/cancel`, this.options
    );
  }

  subscriptionList() {
    return http.get(
      `${AppConfig.apiBaseURL}/subscription/invoices`, this.options
    );
  }

  learnerList() {
    return http.get(
      `${AppConfig.apiBaseURL}/medical-practice/${this.profile.medicalPracticeId}/learners`, this.options
    );
  }


  experienceLevel(planId:number) {
    return http.get(
      `${AppConfig.apiBaseURL}/experience-level/${planId}`
    );
  }
  
  deleteLicence(licenseId:number) {
    return http.delete(
      `${AppConfig.apiBaseURL}/license/remove/${licenseId}/${this.profile.id}`, this.options
    );
  }


  getBilling() {
    return http.get(`${AppConfig.apiBaseURL}/billing`, this.options);
  }

  editBilling(payload:any) {
    return http.post(`${AppConfig.apiBaseURL}/billing`, payload, this.options);
  }

  getPracticePlan(payload:any) {
    return http.get(`${AppConfig.apiBaseURL}/medical-practice/${this.profile.medicalPracticeId}/plans`, this.options);
  }

  editLiceseCount(payload:any) {
    return http.post(`${AppConfig.apiBaseURL}/licenses/manage`, payload, this.options);
  }

  removeLeader(subscriptionAllocationId:number) {
    return http.delete(
      `${AppConfig.apiBaseURL}/profile/remove/${subscriptionAllocationId}`, this.options);
  }

  changeLicenceTire(payload: any) {
    return http.post(`${AppConfig.apiBaseURL}/license/change/tier/v2/`+this.profile.id, payload, this.options);
  }

  getExamState(){
    let payload = {
      medicalPracticeId: this.profile?.medicalPracticeId
    }
    return http.post(`${AppConfig.apiBaseURL}/learnupon/users/avg/exam/score`, payload, this.options);
  }
  insertContact(payload:any){
    return http.post(
      `${AppConfig.apiBaseURL}/enterprise/contact`,payload); 
  }

  registerProfile(payload: any){
    return http.post(`${AppConfig.apiBaseURL}/auth/register`, payload); 
  }

  getLoginState(){  
    let payload = {
      medicalPracticeId: this.profile?.medicalPracticeId
    };  

    return http.post(`${AppConfig.apiBaseURL}/learnupon/users/activity`, payload, this.options);
  }

  editCreditCard(){  
     return http.get(
      `${AppConfig.apiBaseURL}/billing/setup/intent`,this.options);
  }


  getStripeIntent(medicalPracticeId:number){
    let payload = {
      medicalPracticeId: medicalPracticeId
    }
    return http.get(
      `${AppConfig.apiBaseURL}/migration/intent/`+payload.medicalPracticeId,this.options);
  }
  sendEmail(payload:any){
    return http.get(
       `${AppConfig.apiBaseURL}/profile/invite/resend/${payload.licenseAllocationId}`,this.options);
  }

  getLearners(){  
    return http.get(`${AppConfig.apiBaseURL}/medical-practice/3/users`);
  }

  getActiveMedicalPractices(){  
    return http.get(`${AppConfig.apiBaseURL}/medical-practice/active/practices`);
  }

  changePassword(payload:any){
    return http.post(`${AppConfig.apiBaseURL}/auth/reset-password`,payload,this.options);
  }
  sendReport(){  
    let profile = JSON.parse(localStorage.getItem("profile") || "");
    return http.get(`${AppConfig.apiBaseURL}/learnupon/users/generate/report/`+profile?.medicalPracticeId, this.options);
  }

  getReport(){  
    let profile = JSON.parse(localStorage.getItem("profile") || "");
    return http.get(`${AppConfig.apiBaseURL}/learnupon/users/reporting/`+profile?.medicalPracticeId, this.options);
  }

  getFeatures(){  
    return http.get(`${AppConfig.apiBaseURL}/features`);
  }

  createFreeTrial(payload: any) {
    return http.post(`${AppConfig.apiBaseURL}/payment/create/free/trail`, payload);
  }

  getSubscriptionType() {
    return http.get(`${AppConfig.apiBaseURL}/subscription/type`, this.options);
  }

  learnerLogin() {
    if (localStorage.getItem('auth')) {
      const auth = JSON.parse(localStorage.getItem('auth') || '');
      this.options.headers.Authorization = `Bearer ${auth?.idToken}`;
    };

    return http.get(`${AppConfig.apiBaseURL}/auth/learner/login`, this.options);
  }

  generatePdfData(luid:number){
    return http.get(`${AppConfig.apiBaseURL}/learnupon/users/course/enrollment/details/`+luid, this.options);
  }

  getLicensesDetails(){
    return http.get(`${AppConfig.apiBaseURL}/licenses/details`, this.options);
  }

  gerLerners(){
    return http.post(`${AppConfig.apiBaseURL}/profile/search`,{
      "includeCancelLicenses": true
    }, this.options);
  }
  gerFreshLerners(){
    return http.post(`${AppConfig.apiBaseURL}/profile/search`,{
    }, this.options);
  }

  leaderlist() {
    return http.post(`${AppConfig.apiBaseURL}/profile/search`,{
      "userProfileType": "leaders",
      "includeCancelLicenses": true
    }, this.options);
  }

  addLeader(payload:any) {
    return http.post(
      `${AppConfig.apiBaseURL}/profile/invite`, {
        ...payload,
        roleId: 2
      },
      this.options
    );
  }
  
  uppdateLicence(payload:any,role:any) {
    let roleId;
    if(role==='Learner'){
      roleId=1;
    }else{
      roleId=2;
    }
    return http.post(
      `${AppConfig.apiBaseURL}/profile/invite`,{
        ...payload,
        roleId: roleId
      }, this.options
    );
  }

  aaoVerify(token:any){
    let tempToken = {
        headers: {
          Authorization:token
        }
    }
      return http.get(`${AppConfig.apiBaseURL}/academy/tokens/verify`, tempToken);
  }

  aaoVerifyPricing(token:any){
    let tempToken = {
        headers: {
          Authorization:token
        }
    }
    if(token){
      return http.get(`${AppConfig.apiBaseURL}/academy/tokens/verify`, tempToken);
    }else{
      return http.get(`${AppConfig.apiBaseURL}/academy/tokens/verify`);
    }
  }

  aaoVerifyCreate(){
    let options = {
      partnerId:'2686135',
      secretKey:'ApEZOgut3lJWD4XY'
    }
    return http.post(`${AppConfig.apiBaseURL}/academy/generate-url`,options);
  }
  
  
}

export default new ApiService();
