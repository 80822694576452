import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import next from "../../assets/images/Prev-d.svg";
import prev from "../../assets/images/Back-d.svg";
// import './pagination.scss';
const Pagination = (props: any) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (paginationRange) {
    if (currentPage === 0 || paginationRange.length < 2) {
      return null;
    }
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  let lastPage;
  if (paginationRange) {
    lastPage = paginationRange[paginationRange.length - 1];
  }
  return (
    <div className="text-center">
      <div
        className={classnames("pagination-container mt-3 mb-2", {
          [className]: className,
        })}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <span
          className={classnames("pagination-item", {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          {currentPage !== 1 && (
            <>
              {" "}
              <img src={prev} alt="prev" />
              &nbsp;&nbsp;&nbsp;{" "}
            </>
          )}
        </span>
        {paginationRange?.map((pageNumber: any) => {
          if (pageNumber === DOTS) {
            return (
              <span key={pageNumber} className="pagination-item dots">
                &#8230;
              </span>
            );
          }

          return (
            <div
              className={` `+classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              key={pageNumber}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber === currentPage ? (
                <>
                 <span className="page-number" style={{background:'#c9ccd1'}}>{pageNumber}</span> 
                  &nbsp;&nbsp;&nbsp;
                </>
              ) : (
                <>
                  <span className="page-number">{pageNumber}</span>
                  &nbsp;&nbsp;&nbsp;
                </>
              )}
            </div>
          );
        })}
        <span
          className={classnames("pagination-item", {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}
        >
          {currentPage !== lastPage && <img src={next} alt="next" />}
        </span>
      </div>
    </div>
  );
};

export default Pagination;
